import { message } from 'antd'
import { inject, observer } from 'mobx-react'
import React, { useEffect } from 'react'
import { memo } from 'react'
import { Helmet } from 'react-helmet'
import { useHistory, withRouter } from 'react-router-dom'
import { MODULE_CODE } from '../../../constants'
import UserGroupPage from '../../UserGroupPage'
import DocumentManagementLayout from '../DocumentManagementLayout'
import { DocumentManagementTabContent } from '../DocumentManagementStyled'

const UserGroup = props => {
  const history = useHistory()

  useEffect(() => {
    if(props.moduleStore.moduleList.length === 0) return 

    if (!props.moduleStore.checkAccessModule(MODULE_CODE.nhom_nguoi_dung)) {
      message.error('Không được quyền truy cập vào trang này')
      history.push('/dashboard')
    }
  }, [props.moduleStore.moduleList])

  return (
    <DocumentManagementLayout selectedKey="user-group">
      <DocumentManagementTabContent>
        <Helmet>
          <title>Nhóm người dùng | Quản trị | Can Tho Port Quản lý VB Can Tho Port</title>
        </Helmet>

        <UserGroupPage isTab />
      </DocumentManagementTabContent>
    </DocumentManagementLayout>
  )
}

export default memo(withRouter(inject('moduleStore')(observer(UserGroup))))
