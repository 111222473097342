import { action, observable } from 'mobx'
import RiskTemplateRequest from '../requests/RiskTemplateRequest'

class riskTemplateStore {
  @observable riskTemplateSortableLevels = []

  @observable riskTemplateList = []
  @observable riskTemplateTypes = null

  @observable riskTemplateDetail = {
    code: null,
    name: null,
    description: null,
    risk_levels: [],
  }

  @action getRiskTemplateList = filter => {
    return new Promise((resolve, reject) => {
      RiskTemplateRequest.getRiskTemplateList(filter)
        .then(response => {
          this.riskTemplateList = response.data
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }
  @action getRiskTemplateTypes = () => {
    return new Promise((resolve, reject) => {
      RiskTemplateRequest.getRiskTemplateTypes()
        .then(response => {
          this.riskTemplateTypes = response.data
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }
  @action getRiskTemplateDetail = code => {
    return new Promise((resolve, reject) => {
      RiskTemplateRequest.getRiskTemplateDetail(code)
        .then(response => {
          this.riskTemplateDetail = response.data
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  @action createRiskTemplate = payload => {
    return new Promise((resolve, reject) => {
      RiskTemplateRequest.createRiskTemplate(payload)
        .then(response => resolve(response.data))
        .catch(error => reject(error))
    })
  }

  @action updateRiskTemplate = (code, payload) => {
    return new Promise((resolve, reject) => {
      RiskTemplateRequest.updateRiskTemplate(code, payload)
        .then(response => resolve(response.data))
        .catch(error => reject(error))
    })
  }

  @action delete = code => {
    return RiskTemplateRequest.delete(code)
  }

  @action clearRiskTemplateDetail = () => {
    this.riskTemplateDetail = {
      code: null,
      name: null,
      description: null,
      risk_levels: [],
    }
  }

  @action setRiskTemplateSortableLevels = payload => {
    this.riskTemplateSortableLevels = payload
  }

  @action clearRiskTemplateSortableLevels = () => {
    this.riskTemplateSortableLevels = []
  }
}

export default new riskTemplateStore()
