import {
  FileTextOutlined,
  LeftOutlined,
  RightOutlined,
} from '@ant-design/icons'
import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  List,
  message,
  Row,
  Tabs,
  Tooltip,
  Typography,
} from 'antd'
import axios from 'axios'
import { runInAction, toJS } from 'mobx'
import { inject, observer } from 'mobx-react'
import moment from 'moment'
import React, { memo, useEffect, useState } from 'react'
import { Helmet } from 'react-helmet/es/Helmet'
import { Transition } from 'react-transition-group'
import { EmptyText, UrlifiedText } from '../../components/Common/CellText'
import CounterCard from '../../components/CounterCard/CounterCard'
import EmptyContent from '../../components/EmptyContent'
import IncompleteListModal from '../../components/IncompleteListModal/IncompleteListModal'
import SpinnerInlineComponent from '../../components/SpinnerInlineComponent'
import UnreadDocModal from '../../components/UnreadDocumentModal/UnreadDocModal'
import { useSSO } from '../../config'
import {
  ACL_ACTION_TYPE,
  ASSIGNEE_TYPE,
  DD_MM,
  HHhMM,
  ISO_DATE_FORMAT,
  MODULE_CODE,
} from '../../constants'
import date_utils from '../../date_utils'
import DashboardLayout from '../../layouts/DashboardLayout'
import tintucStore from '../../stores/tintucStore'
import utils from '../../utils'
import validator from '../../validator'
import { LoginWrapper } from '../PortalPageV2/PortalPageV2Styled'
import {
  BoxChartLoading,
  CardWrapper,
  DayTab,
  DescriptionLink,
  GeneralNotif,
  ItemCalendarContent,
  RowWrapper,
  StatisticList,
  WorkScheduledCard,
  WorkScheduledItem,
} from './DashboardPageV2Styled'

const { TabPane } = Tabs
const btnPrevAndNextStyled = {
  cursor: 'pointer',
}

const DashboardPageV2 = props => {
  const defaultStyle = {
    transition: `all 500ms cubic-bezier(.4,0,.2,1)`,
    opacity: 0,
    margin: 0,
    maxHeight: 0,
  }

  const transitionStyles = {
    entering: { padding: 0, maxHeight: 0, opacity: 0 },
    entered: { maxHeight: 5000, opacity: 1 },
    exiting: { padding: 0, maxHeight: 0, opacity: 0 },
    exited: { padding: 0, maxHeight: 0, opacity: 0 },
  }

  const {
    history,
    location,
    workScheduleStore,
    statisticStore,
    fileStore,
    authenticationStore,
    accountStore,
    commandStore,
    notificationStore,
    userStore,
    moduleStore,
    aclStore,
    taskStore,
    workStore,
    tintucStore,
  } = props

  const { currentUser, isAccountAdmin, isSuperAdmin } = authenticationStore
  const { aclActionsByUser } = aclStore
  const { workScheduleList } = workScheduleStore
  // const { statistic } = statisticStore
  const { sideMenuCounter, dashboardSideMenuCounter } = commandStore
  const { newsList } = tintucStore

  const [days, setDays] = useState(date_utils.weekRange(date_utils.current()))
  // const [dataCongViec, setDataCongViec] = useState([{ type: '', value: 0 }])
  //
  const [isLoadingCalendar, setIsLoadingCalendar] = useState(false)
  const [isLoadingSidemenuCounter, setIsLoadingSidemenuCounter] = useState(
    false
  )
  const [isLoadingTinTuc, setIsLoadingTinTuc] = useState(false)
  //
  const [confirmLoading, setConfirmLoading] = useState(false)
  const [, setShowDialog] = useState(false)

  const workScheduleOfDay = strDay =>
    workScheduleList.filter(
      workSchedule => moment(workSchedule.start_at).format(DD_MM) === strDay
    )

  useEffect(() => {
    ;(async () => {
      try {
        setIsLoadingSidemenuCounter(true)
        await Promise.all([
          commandStore.getSideMenuCounterDashboard(['WORK']),
          commandStore.getSideMenuCounterDashboard(['PROPOSAL']),
          commandStore.getSideMenuCounterDashboard(['INCOMING']),
          commandStore.getSideMenuCounterDashboard(['OUTGOING']),
          commandStore.getSideMenuCounterDashboard(['TASK']),
        ])
      } catch (err) {
        console.log(err)
        message.error(err.vi || 'Đã có lỗi xảy ra!')
        setIsLoadingSidemenuCounter(false)
      } finally {
        setIsLoadingSidemenuCounter(false)
      }
    })()
  }, [])
  // console.log('dashboardSideMenuCounter',moduleList);
  useEffect(() => {
    ;(async () => {
      try {
        setIsLoadingTinTuc(true)
        await tintucStore.getAllNews()
      } catch (err) {
        console.log(err)
        message.error(err.vi || 'Đã có lỗi xảy ra!')
        setIsLoadingTinTuc(false)
      } finally {
        setIsLoadingTinTuc(false)
      }
    })()
  }, [])

  const isAccessControl = (moduleCode, accessControlType) => {
    if (isSuperAdmin) return true
    if (!moduleStore.checkAccessModule(moduleCode)) return false
    if (accessControlType)
      return (
        isAccountAdmin ||
        !!aclActionsByUser.find(item => item.code === accessControlType)?.status
      )
    return true
  }

  const handleChangeWeek = async (date, type) => {
    let fromDate = null
    let toDate = null
    if (type === 'prev') {
      fromDate = moment(date[0]).subtract(7, 'days').format(ISO_DATE_FORMAT)
      toDate = moment(date[6]).subtract(7, 'days').format(ISO_DATE_FORMAT)
      setDays(date_utils.weekRange(moment(date[0]).subtract(7, 'days')))
    }
    if (type === 'next') {
      fromDate = moment(date[0]).add(7, 'days').format(ISO_DATE_FORMAT)
      toDate = moment(date[6]).add(7, 'days').format(ISO_DATE_FORMAT)
      setDays(date_utils.weekRange(moment(date[0]).add(7, 'days')))
    }
    if (type === 'week') {
      fromDate = date_utils.weekRange(date)[0]
      toDate = date_utils.weekRange(date)[6]
      setDays(date_utils.weekRange(date))
    }
    try {
      setIsLoadingCalendar(true)
      await workScheduleStore.getWorkSchedule(
        moment(fromDate).format(ISO_DATE_FORMAT),
        moment(toDate).format(ISO_DATE_FORMAT)
      )
    } catch (error) {
      console.log(error)
      message.error(error?.vi || 'Đã có lỗi xảy ra!')
    } finally {
      setIsLoadingCalendar(false)
    }
  }

  useEffect(() => {
    ;(async () => {
      setIsLoadingCalendar(true)
      try {
        await workScheduleStore.getWorkSchedule(
          moment(days[0]).format(ISO_DATE_FORMAT),
          moment(days[6]).format(ISO_DATE_FORMAT)
        )
      } catch (error) {
        console.log(error)
        message.error(error?.vi || 'Đã có lỗi xảy ra!')
      } finally {
        setIsLoadingCalendar(false)
      }
    })()
  }, [])

  // clear workScheduleList va statistic khi ra khỏi trang dashboard
  useEffect(() => {
    return () => {
      workScheduleStore.clearWorkSchedule()
      statisticStore.clearStatistic()
      tintucStore.clear()
      commandStore.clearStore()
    }
  }, [])

  const onClickIncoming = () => {
    history.push({
      pathname: `/internal-document/incoming-document`,
      state: {
        from: location.pathname,
      },
    })
  }
  const onClickOutgoing = () => {
    history.push({
      pathname: `/internal-document/outgoing-document`,
      state: {
        from: location.pathname,
      },
    })
  }
  const onClickWorks = () => {
    history.push({
      pathname: `/works`,
      state: {
        from: location.pathname,
      },
    })
  }
  const onClickMission = () => {
    history.push({
      pathname: `/mission`,
      state: {
        from: location.pathname,
      },
    })
  }
  const onClickProposal = () => {
    history.push({
      pathname: `/proposal`,
      state: {
        from: location.pathname,
      },
    })
  }

  const renderFile = file_ids => {
    if (file_ids) {
      if (file_ids.length === 0) {
        return <EmptyText>Không có tài liệu.</EmptyText>
      }
      if (file_ids.length === 1) {
        return (
          <DescriptionLink>
            <FileTextOutlined style={{ color: '#40a9ff' }} />
            <a
              onClick={e =>
                fileStore.handleDownloadFile(
                  file_ids[0].file_id,
                  file_ids[0].file_title
                )
              }>
              {file_ids[0].file_title}
            </a>
          </DescriptionLink>
        )
      }

      if (file_ids.length > 1) {
        return (
          <DescriptionLink>
            <FileTextOutlined style={{ color: '#40a9ff' }} />
            <a
              onClick={e =>
                fileStore.handleDownloadFile(
                  file_ids[0].file_id,
                  file_ids[0].file_title
                )
              }>
              {file_ids[0].file_title}
            </a>
          </DescriptionLink>
        )
      }
    }
  }

  const getShipScheduleContent = workScheduled => {
    const shipScheduleContent = []

    shipScheduleContent.push(
      <div className="work-scheduled-item__info">
        <b>Thời gian: </b>
        {workScheduled.end_at
          ? `Từ ${moment(workScheduled.start_at).format(HHhMM)} Đến ${moment(
              workScheduled.end_at
            ).format(HHhMM)}`
          : `Từ ${moment(workScheduled.start_at).format(HHhMM)}`}
      </div>
    )
    shipScheduleContent.push(
      <div className="work-scheduled-item__info">
        <b>Chủ trì: </b>
        {workScheduled.host ? (
          workScheduled.host
        ) : (
          <EmptyText>Không có chủ trì.</EmptyText>
        )}
      </div>
    )
    shipScheduleContent.push(
      <div className="work-scheduled-item__info">
        <b>Địa điểm: </b>
        <span>
          {workScheduled.location ? (
            UrlifiedText(workScheduled?.location)
          ) : (
            <EmptyText>Không có địa điểm.</EmptyText>
          )}
        </span>
      </div>
    )
    shipScheduleContent.push(
      <div className="work-scheduled-item__info">
        <b>Tài liệu: </b>
        <span>{renderFile(workScheduled?.file_ids)}</span>
      </div>
    )

    shipScheduleContent.push(
      <div className="work-scheduled-item__info">
        <b>Chuẩn bị: </b>
        <span>
          {workScheduled.preparation ? (
            workScheduled.preparation
          ) : (
            <EmptyText>Không có chuẩn bị.</EmptyText>
          )}
        </span>
      </div>
    )
    shipScheduleContent.push(
      <div className="work-scheduled-item__info">
        <b>Nội dung: </b>
        <div>
          {workScheduled.event_notice ? (
            UrlifiedText(
              workScheduled.event_notice
                .replace(/<\/?[^>]+(>|$)/g, '')
                .replace(/&nbsp;/g, '')
            )
          ) : (
            <EmptyText>Không có nội dung.</EmptyText>
          )}
        </div>
      </div>
    )

    shipScheduleContent.push(
      <div className="work-scheduled-item__info">
        <b>Thành viên tham gia: </b>
        <div>
          {workScheduled.attenders ? (
            workScheduled.attenders
          ) : (
            <EmptyText>Không có thành viên tham gia.</EmptyText>
          )}
        </div>
      </div>
    )

    return shipScheduleContent
  }

  const handleOk = async values => {
    setConfirmLoading(true)
    try {
      const response = await authenticationStore.userLogin(
        values.identifier,
        values.password
      )
      if (response.status === 200) {
        const res = await authenticationStore.checkCurrentUser()
        await authenticationStore.getCurrentUserAvatar()
        await commandStore.getCommandList()
        await notificationStore.getUnreadNotificationCount()
        await notificationStore.getUnreadNewsCount()
        await userStore.getMentionUserList()
        await accountStore.getCurrentUserAccount()
        await moduleStore.getModuleList()
        await aclStore.getACLDetailByUser(res.data.username)
        setShowDialog(false)
        message.success(
          `Xin chào, ${utils.getNameInCapitalize(res.data.name_uppercase)}!`
        )
      }
    } catch (err) {
      console.log(err)
      message.error(err.vi || 'Login failed response status!')
    } finally {
      setConfirmLoading(false)
    }
  }

  const loginPage = (
    <>
      <LoginWrapper
        bgImage={`${process.env.PUBLIC_URL}/assets/photos/auth-bg-2.jpg`}>
        {useSSO ? (
          <></>
        ) : (
          <Form layout={'vertical'} name={'loginForm'} onFinish={handleOk}>
            <Form.Item style={{ textAlign: 'center' }}>
              <img
                alt="login_logo"
                height={32}
                src={`${process.env.PUBLIC_URL}/assets/photos/portal_logo_white.png`}
              />
            </Form.Item>
            <Form.Item
              label="Tên đăng nhập"
              name="identifier"
              rules={[
                { required: true, message: 'Vui lòng nhập tên đăng nhập!' },
                { validator: validator.validateUsername },
              ]}>
              <Input />
            </Form.Item>
            <Form.Item
              label="Mật khẩu"
              name="password"
              rules={[{ required: true, message: 'Vui lòng nhập mật khẩu!' }]}>
              <Input.Password />
            </Form.Item>
            <Button
              type={'primary'}
              block
              htmlType={'submit'}
              loading={confirmLoading}>
              {' '}
              Đăng nhập
            </Button>
            <Button style={{ marginTop: 8 }} type={'link'} block>
              {' '}
              Quên mật khẩu?
            </Button>
            {/*<Button style={{ marginTop: 0 }} type={'link'} block onClick={showModal}> Lịch cơ quan</Button>*/}
          </Form>
        )}
      </LoginWrapper>
    </>
  )

  return (
    <div>
      {currentUser != null ? (
        <DashboardLayout
          title={'Link People - Link the World'}
          backgroundColor={'#f2f3f8'}>
          <Helmet>
            <title>Dashboard | Can Tho Portal</title>
          </Helmet>
          <div style={{ position: 'relative' }}>
            <RowWrapper>
              <CardWrapper>
                {isAccessControl(
                  MODULE_CODE.van_ban_noi_bo,
                  ACL_ACTION_TYPE.menu__INTERNAL_DOCUMENT
                ) && (
                  <CounterCard
                    title={'Văn bản đến chưa đọc'}
                    counter={dashboardSideMenuCounter?.unread_incoming ?? 0}
                    icon={`${process.env.PUBLIC_URL}/assets/icons/document_outgoing_new.svg`}
                    onClick={onClickIncoming}>
                    <SpinnerInlineComponent
                      sizeSpin={'small'}
                      isLoading={isLoadingSidemenuCounter}
                      backGround={'#ffffffc7'}
                    />
                  </CounterCard>
                )}

                {isAccessControl(
                  MODULE_CODE.van_ban_noi_bo,
                  ACL_ACTION_TYPE.menu__INTERNAL_DOCUMENT
                ) && (
                  <CounterCard
                    title={'Văn bản đi chưa đọc'}
                    counter={dashboardSideMenuCounter?.unread_outgoing ?? 0}
                    icon={`${process.env.PUBLIC_URL}/assets/icons/document_incoming_new.svg`}
                    onClick={onClickOutgoing}>
                    <SpinnerInlineComponent
                      sizeSpin={'small'}
                      isLoading={isLoadingSidemenuCounter}
                      backGround={'#ffffffc7'}
                    />
                  </CounterCard>
                )}
                {isAccessControl(
                  MODULE_CODE.cong_viec,
                  ACL_ACTION_TYPE.menu__WORK
                ) && (
                  <CounterCard
                    title={'Công việc chưa đọc'}
                    counter={dashboardSideMenuCounter?.work ?? 0}
                    icon={`${process.env.PUBLIC_URL}/assets/icons/work_manager_draft.svg`}
                    onClick={onClickWorks}>
                    <SpinnerInlineComponent
                      sizeSpin={'small'}
                      isLoading={isLoadingSidemenuCounter}
                      backGround={'#ffffffc7'}
                    />
                  </CounterCard>
                )}
                {isAccessControl(
                  MODULE_CODE.nhiem_vu,
                  ACL_ACTION_TYPE.menu__MISSION
                ) && (
                  <CounterCard
                    title={'Nhiệm vụ quá hạn'}
                    counter={dashboardSideMenuCounter?.overdue_task ?? 0}
                    icon={`${process.env.PUBLIC_URL}/assets/icons/work_manager_overdue.svg`}
                    onClick={onClickMission}>
                    <SpinnerInlineComponent
                      sizeSpin={'small'}
                      isLoading={isLoadingSidemenuCounter}
                      backGround={'#ffffffc7'}
                    />
                  </CounterCard>
                )}
                {isAccessControl(
                  MODULE_CODE.hanh_chinh,
                  ACL_ACTION_TYPE.menu__PROPOSAL
                ) && (
                  <CounterCard
                    title={'Đơn hành chính chờ duyệt'}
                    counter={dashboardSideMenuCounter?.proposal_other ?? 0}
                    icon={`${process.env.PUBLIC_URL}/assets/icons/work_manager_processing.svg`}
                    onClick={onClickProposal}>
                    <SpinnerInlineComponent
                      sizeSpin={'small'}
                      isLoading={isLoadingSidemenuCounter}
                      backGround={'#ffffffc7'}
                    />
                  </CounterCard>
                )}
              </CardWrapper>
              {isAccessControl(
                MODULE_CODE.thong_bao_chung,
                ACL_ACTION_TYPE.menu__NEWS
              ) && (
                <GeneralNotif
                  header={<div>Thông báo chung </div>}
                  footer={false}
                  bordered
                  dataSource={newsList.slice(0, 4)}
                  renderItem={item => (
                    <List.Item
                      key={item.id}
                      onClick={() => {
                        history.push(
                          `/utility/general-notifications/view/${item.id}`
                        )
                      }}>
                      {item.subject}
                    </List.Item>
                  )}>
                  <SpinnerInlineComponent
                    sizeSpin={'small'}
                    isLoading={isLoadingTinTuc}
                    backGround={'#ffffffc7'}
                    tip={'Đang tải thông báo chung...'}
                  />
                </GeneralNotif>
              )}
            </RowWrapper>
            {isAccessControl(
              MODULE_CODE.lich_co_quan,
              ACL_ACTION_TYPE.menu__WORK_SCHEDULE
            ) && (
              <WorkScheduledCard
                title={'Lịch cơ quan'}
                extra={
                  <div>
                    <Tooltip title={'Tuần trước'}>
                      <Button
                        onClick={() => handleChangeWeek(days, 'prev')}
                        icon={<LeftOutlined />}
                      />
                    </Tooltip>
                    <DatePicker
                      allowClear={false}
                      onChange={date => handleChangeWeek(date, 'week')}
                      picker="week"
                      placeholder={'Chọn tuần'}
                      format={'Tuần w, YYYY'}
                      value={moment(days[0])}
                      style={{
                        height: 32,
                        width: 120,
                        margin: '0 10px 0 10px',
                      }}
                    />
                    <Tooltip title={'Tuần sau'}>
                      <Button
                        onClick={() => handleChangeWeek(days, 'next')}
                        icon={<RightOutlined />}
                      />
                    </Tooltip>
                  </div>
                }>
                <Tabs
                  centered
                  defaultActiveKey={days
                    .map(day => day.getDay())
                    .findIndex(day => day === moment().day())
                    .toString()}
                  size={'small'}>
                  {days.map(day => {
                    let dayIndex = days.indexOf(day)
                    let strDay = moment(day).format(DD_MM)
                    let renderDayTab = () => {
                      if (dayIndex !== 6) {
                        return (
                          <DayTab>
                            <p className="weekday">{`Thứ ${dayIndex + 2}`}</p>
                            <p className="monthday">{`(${strDay})`}</p>
                          </DayTab>
                        )
                      } else {
                        return (
                          <DayTab>
                            <p>Chủ nhật</p>
                            <p> {`(${strDay})`}</p>
                          </DayTab>
                        )
                      }
                    }
                    return (
                      <TabPane tab={renderDayTab()} key={dayIndex}>
                        <ItemCalendarContent>
                          {workScheduleOfDay(strDay).length !== 0 ? (
                            workScheduleOfDay(strDay).map(workScheduled => {
                              return (
                                <WorkScheduledItem
                                  key={workScheduled.schedule_code}>
                                  <div className="">
                                    {getShipScheduleContent(workScheduled)
                                      .slice(0, 5)
                                      .map(item => item)}
                                  </div>
                                  <div className="">
                                    {getShipScheduleContent(workScheduled)
                                      .slice(5)
                                      .map(item => item)}
                                  </div>
                                </WorkScheduledItem>
                              )
                            })
                          ) : !isLoadingCalendar ? (
                            <EmptyContent description={'Không có sự kiện!'} />
                          ) : (
                            <div style={{ height: 106 }} />
                          )}
                          <SpinnerInlineComponent
                            sizeSpin={'small'}
                            isLoading={isLoadingCalendar}
                            noBackground
                            tip={'Đang tải lịch cơ quan...'}
                          />
                        </ItemCalendarContent>
                      </TabPane>
                    )
                  })}
                </Tabs>
              </WorkScheduledCard>
            )}
          </div>
        </DashboardLayout>
      ) : (
        loginPage
      )}
    </div>
  )
}

export default memo(
  inject(
    'workScheduleStore',
    'loadingAnimationStore',
    'commonStore',
    'statisticStore',
    'fileStore',
    'authenticationStore',
    'accountStore',
    'userStore',
    'companyStore',
    'notificationStore',
    'commandStore',
    'taskManagementStore',
    'taskManagementCommentStore',
    'moduleStore',
    'taskStore',
    'workStore',
    'tintucStore',
    'aclStore'
  )(observer(DashboardPageV2))
)
