import React, { useEffect, useState } from 'react'
import {
  Button,
  Divider,
  Form,
  Input,
  message,
  Modal,
  Radio,
  Select,
  Space,
} from 'antd'
import { FormActionFooter } from '../ConnectedDocumentIncomingDocCreatePage/ConnectedDocumentIncomingDocCreatePageStyled'
import { CloseOutlined } from '@ant-design/icons'
import { inject, observer } from 'mobx-react'
import queryString from 'query-string'
import { ASSIGNEE_TYPE } from '../../constants'
import utils from '../../utils'
import { useRef } from 'react'
import RichEditor from '../../components/RichEditor/RichEditor'
import SpinnerInlineComponent from '../../components/SpinnerInlineComponent'
import { RiskSelects } from './RiskCreatePageStyled'
import SelectUserButton from '../../components/Common/SelectUserButton'
import SelectListComponent from '../../components/Common/SelectListComponent'
import { SelectPeopleWrapper } from '../WorkPage/PopupVote/PopupVoteStyled'
import { blue } from '../../color'
import RuiRoNhanDienTemplate from './RuiRoNhanDienTemplate'

const RiskIdentificationCreatePopup = ({
  handleCancel,
  isVisible,
  actionStore,
  riskStore,
  getRiskDetail,
  selectUserList,
  selectPeopleStore,
  handleVisibleSelectOneUserPopup,
}) => {
  const { riskDetail } = riskStore
  const {
    indexDetailAction,
    dataActions,
    setIndexDetailAction,
    setDataActions,
    createAction,
    updateAction,
  } = actionStore
  const {
    handleOpenSelectUserDepartmentPopup,
    setSelectUserData,
    selectUserData,
  } = selectPeopleStore
  const queryStringParse = queryString.parse(location.search)
  const [riskPoint, setRiskPoint] = useState({ p: 0, s: 0 })
  const [isRenderRichText, setIsRenderRichText] = useState(false)
  const [
    isVisibleRuiRoNhanDienTemplate,
    setIsVisibleRuiRoNhanDienTemplate,
  ] = useState(false)
  const [ruiRoNhanDien, setRuiRoNhanDien] = useState({})

  const [form] = Form.useForm()
  const CONTENT_REF = useRef()
  const NDRR_REF = useRef()
  const XDGPGTPN_REF = useRef()
  const BPXL_REF = useRef()

  useEffect(() => {
    if (!dataActions?.tier1[0] || indexDetailAction?.tier !== '1') {
      return
    }
    let fillData = dataActions.tier1[0]

    setSelectUserData({
      ...selectUserData,
      [ASSIGNEE_TYPE.USER]:
        fillData.I_nhan_dien_rui_ro_va_danh_gia_rui_ro
          .lanh_dao_don_vi_nddpv_phu_trach_chung,
      [ASSIGNEE_TYPE.lanh_dao_vimc]: fillData.II_xu_ly_rui_ro.lanh_dao_vimc,
    })
    form.setFieldsValue({
      //doi_tuong_thuc_hien
      nddpv_cua_vimc_tai_dntv:
        fillData.doi_tuong_thuc_hien?.nddpv_cua_vimc_tai_dntv,
      dvpt_cua_vimc: fillData.doi_tuong_thuc_hien?.dvpt_cua_vimc,
      cac_don_vi_thuoc_cq_vp_cua_vimc:
        fillData.doi_tuong_thuc_hien?.cac_don_vi_thuoc_cq_vp_cua_vimc,
      //I_nhan_dien_rui_ro_va_danh_gia_rui_ro -> cong_viec_linh_vuc_hoat_dong
      cong_viec_theo:
        fillData.I_nhan_dien_rui_ro_va_danh_gia_rui_ro
          .cong_viec_linh_vuc_hoat_dong.cong_viec_theo,
      noi_dung_cong_viec_khac_ko_thuoc_cac_nhom_tren:
        fillData.I_nhan_dien_rui_ro_va_danh_gia_rui_ro
          .cong_viec_linh_vuc_hoat_dong
          .noi_dung_cong_viec_khac_ko_thuoc_cac_nhom_tren,
      //I_nhan_dien_rui_ro_va_danh_gia_rui_ro -> cong_viec_thuc_hien
      noi_dung_cong_viec:
        fillData.I_nhan_dien_rui_ro_va_danh_gia_rui_ro.cong_viec_thuc_hien
          .noi_dung_cong_viec,
      nguoi_thuc_hien__cong_viec:
        fillData.I_nhan_dien_rui_ro_va_danh_gia_rui_ro.cong_viec_thuc_hien
          .nguoi_thuc_hien,
      thoi_gian_hoan_thanh:
        fillData.I_nhan_dien_rui_ro_va_danh_gia_rui_ro.cong_viec_thuc_hien
          .thoi_gian_hoan_thanh,
      //I_nhan_dien_rui_ro_va_danh_gia_rui_ro -> nhan_dien_rui_ro_va_danh_gia_rui_ro
      // nhan_dien_rui_ro:
      //   fillData.I_nhan_dien_rui_ro_va_danh_gia_rui_ro
      //     .nhan_dien_rui_ro_va_danh_gia_rui_ro.nhan_dien_rui_ro,
      // xay_dung_giai_phap_giam_thieu_phong_ngua:
      //   fillData.I_nhan_dien_rui_ro_va_danh_gia_rui_ro
      //     .nhan_dien_rui_ro_va_danh_gia_rui_ro
      //     .xay_dung_giai_phap_giam_thieu_phong_ngua,
      p:
        fillData.I_nhan_dien_rui_ro_va_danh_gia_rui_ro
          .nhan_dien_rui_ro_va_danh_gia_rui_ro.p,
      s:
        fillData.I_nhan_dien_rui_ro_va_danh_gia_rui_ro
          .nhan_dien_rui_ro_va_danh_gia_rui_ro.s,
      r:
        fillData.I_nhan_dien_rui_ro_va_danh_gia_rui_ro
          .nhan_dien_rui_ro_va_danh_gia_rui_ro.r,
      //

      nguoi_thuc_hien__sign:
        fillData.I_nhan_dien_rui_ro_va_danh_gia_rui_ro.nguoi_thuc_hien,
      // lanh_dao_don_vi_nddpv_phu_trach_chung:
      //   fillData.I_nhan_dien_rui_ro_va_danh_gia_rui_ro
      //     .lanh_dao_don_vi_nddpv_phu_trach_chung,
      //II_xu_ly_rui_ro -> de_xuat_loai_rui_ro_can_xu_ly
      loai_rui_ro_de_xuat:
        fillData.II_xu_ly_rui_ro.de_xuat_loai_rui_ro_can_xu_ly
          .loai_rui_ro_de_xuat,
      bo_phan_de_xuat:
        fillData.II_xu_ly_rui_ro.de_xuat_loai_rui_ro_can_xu_ly.bo_phan_de_xuat,
      bien_phap_xu_ly:
        fillData.II_xu_ly_rui_ro.de_xuat_loai_rui_ro_can_xu_ly.bien_phap_xu_ly,
      nguoi_thuc_hien__de_xuat:
        fillData.II_xu_ly_rui_ro.de_xuat_loai_rui_ro_can_xu_ly.nguoi_thuc_hien,
      //
      lanh_dao_quyet_dinh_bien_phap_xu_ly:
        fillData.II_xu_ly_rui_ro.lanh_dao_quyet_dinh_bien_phap_xu_ly,
      don_vi_thuc_hien_xu_ly_rui_ro:
        fillData.II_xu_ly_rui_ro.don_vi_thuc_hien_xu_ly_rui_ro,
      // lanh_dao_vimc: fillData.II_xu_ly_rui_ro.lanh_dao_vimc,
    })
  }, [indexDetailAction])
  useEffect(() => {
    if (isVisible) {
      setTimeout(() => {
        setIsRenderRichText(true)
      }, 400)
    } else {
      setIsRenderRichText(false)
    }
  }, [isVisible])

  const p = parseInt(
    dataActions?.tier1[0]?.I_nhan_dien_rui_ro_va_danh_gia_rui_ro
      ?.nhan_dien_rui_ro_va_danh_gia_rui_ro?.p
  )

  const s = parseInt(
    dataActions?.tier1[0]?.I_nhan_dien_rui_ro_va_danh_gia_rui_ro
      ?.nhan_dien_rui_ro_va_danh_gia_rui_ro?.s
  )

  useEffect(() => {
    const P = riskPoint.p ? riskPoint.p : p
    const S = riskPoint.s ? riskPoint.s : s
    if (!P || !S) return
    const r = Math.ceil(P * S)
    const displayedR = r >= 1 && r <= 5 ? '1' : r >= 6 && r <= 15 ? '2' : '3'
    form.setFieldsValue({
      r: displayedR,
      loai_rui_ro_de_xuat: displayedR,
    })
  }, [riskPoint])
  useEffect(() => {
    return () => {
      setRiskPoint({ p: 0, s: 0 })
    }
  }, [])
  useEffect(() => {
    if (ruiRoNhanDien) {
      NDRR_REF.current?.editor.setData(
        `<p>${ruiRoNhanDien.rui_ro_nhan_dien}</p>`
      )
      XDGPGTPN_REF.current?.editor.setData(
        `<p>${ruiRoNhanDien.bien_phap_phong_ngua_giam_thieu}</p>`
      )
    }
  }, [ruiRoNhanDien])

  const handleCancelModal = () => {
    handleCancel()
    // setTimeout(() => {
    //   setIndexDetailAction({ tiers: null, index: null })
    // }, 500)
  }

  const handleCreateRiskId = async value => {
    const submitData = {
      name: 'Tuyến phòng vệ thứ nhất',
      doi_tuong_thuc_hien: {
        nddpv_cua_vimc_tai_dntv: value?.nddpv_cua_vimc_tai_dntv,
        dvpt_cua_vimc: value?.dvpt_cua_vimc,
        cac_don_vi_thuoc_cq_vp_cua_vimc: value?.cac_don_vi_thuoc_cq_vp_cua_vimc,
      },
      I_nhan_dien_rui_ro_va_danh_gia_rui_ro: {
        cong_viec_linh_vuc_hoat_dong: {
          cong_viec_theo: value.cong_viec_theo,
          noi_dung_cong_viec_khac_ko_thuoc_cac_nhom_tren:
            value.noi_dung_cong_viec_khac_ko_thuoc_cac_nhom_tren,
        },
        cong_viec_thuc_hien: {
          noi_dung_cong_viec: CONTENT_REF.current.editor.getData(),
          nguoi_thuc_hien: value.nguoi_thuc_hien__cong_viec,
          thoi_gian_hoan_thanh: value.thoi_gian_hoan_thanh,
        },
        nhan_dien_rui_ro_va_danh_gia_rui_ro: {
          nhan_dien_rui_ro: NDRR_REF.current.editor.getData(),
          xay_dung_giai_phap_giam_thieu_phong_ngua: XDGPGTPN_REF.current.editor.getData(),
          // danh_gia_rui_ro: value.danh_gia_rui_ro,
          p: value.p,
          s: value.s,
          r: value.r,
        },
        nguoi_thuc_hien: value.nguoi_thuc_hien__sign,
        lanh_dao_don_vi_nddpv_phu_trach_chung: selectUserList.USER,
      },
      II_xu_ly_rui_ro: {
        de_xuat_loai_rui_ro_can_xu_ly: {
          loai_rui_ro_de_xuat: value.loai_rui_ro_de_xuat,
          bo_phan_de_xuat: value.bo_phan_de_xuat,
          bien_phap_xu_ly: BPXL_REF.current.editor.getData(),
          nguoi_thuc_hien: value.nguoi_thuc_hien__de_xuat,
        },
        lanh_dao_quyet_dinh_bien_phap_xu_ly:
          value.lanh_dao_quyet_dinh_bien_phap_xu_ly,
        don_vi_thuc_hien_xu_ly_rui_ro: value.don_vi_thuc_hien_xu_ly_rui_ro,
        lanh_dao_vimc: selectUserList.lanh_dao_vimc,
      },
    }
    if (riskDetail?.tiers) {
      if (riskDetail?.tiers[0]?.actions[0]) {
        await updateAction(riskDetail?.tiers[0]?.actions[0]?.code, {
          data: JSON.stringify(submitData),
          risk_tier_code: riskDetail?.tiers[0]?.code,
        })
      } else {
        await createAction({
          ...submitData,
          data: JSON.stringify(submitData),
          risk_tier_code: riskDetail?.tiers[0]?.code,
        })
      }
      message.success('Lưu thành công')
      handleCancelModal()
      getRiskDetail()
    } else {
      if (dataActions.tier1[0]) {
        dataActions.tier1[0] = submitData
        setDataActions({ ...dataActions, tier1: [...dataActions?.tier1] })
      } else {
        setDataActions({
          ...dataActions,
          tier1: [submitData],
        })
      }
      message.success('Lưu thành công')
      handleCancelModal()
    }
  }
  return (
    <>
      <Modal
        width={800}
        destroyOnClose={true}
        afterClose={() => {
          setIsRenderRichText(false)
        }}
        zIndex={500}
        style={{ top: 40,  }}
        title={'A. Tuyến phòng vệ thứ nhất'}
        visible={isVisible}
        maskClosable={false}
        // transitionName=""
        // maskTransitionName=""
        onCancel={() => {
          handleCancelModal()
        }}
        footer={null}>
        <Form
          form={form}
          layout={'vertical'}
          onFinish={handleCreateRiskId}
          preserve={false}>
          <h3>Đối tượng thực hiện</h3>
          {utils.checkRichType(
            queryStringParse.type,
            <Form.Item
              name={'nddpv_cua_vimc_tai_dntv'}
              label={
                '(1) NĐDPV của VIMC tại DNTV (trong trường hợp giao NĐDPV thực hiện công việc):'
              }>
              <Input placeholder={'Nhập nội dung'} />
            </Form.Item>,
            <Form.Item
              name={'dvpt_cua_vimc'}
              label={
                '(2) ĐVPT của VIMC (trong trường hợp ĐVPT thực hiện công việc):'
              }>
              <Input placeholder={'Nhập nội dung'} />
            </Form.Item>,
            <Form.Item
              name={'cac_don_vi_thuoc_cq_vp_cua_vimc'}
              label={
                '(3) Các Đơn vị thuộc cơ quan Văn phòng của VIMC (trong trường hợp Văn phòng cơ quan/ các Ban CMNV/TT CNTT thực hiện công việc):'
              }>
              <Input placeholder={'Nhập nội dung'} />
            </Form.Item>
          )}

          <h3>I. NHẬN DIỆN RỦI RO VÀ ĐÁNH GIÁ RỦI RO</h3>

          <h3>1. Công việc/lĩnh vực hoạt động</h3>
          <Form.Item name={'cong_viec_theo'}>
            <Radio.Group>
              <Space direction="vertical">
                <Radio value={'cong_viec_thuoc_chuc_nang_nhiem_vu'}>
                  a/ Công việc thuộc chức năng, nhiệm vụ
                </Radio>
                <Radio value={'cong_viec_theo_kpi_kri'}>
                  b/ Công việc theo KPI/KRI
                </Radio>
                <Radio value={'cong_viec_theo_muc_tieu_chien_luoc'}>
                  c/ Công việc theo mục tiêu, chiến lược
                </Radio>
              </Space>
            </Radio.Group>
          </Form.Item>
          <Form.Item
            name={'noi_dung_cong_viec_khac_ko_thuoc_cac_nhom_tren'}
            label={'d/ Nội dung công việc khác không thuộc các nhóm trên:'}>
            <Input placeholder={'Nhập nội dung'} />
          </Form.Item>

          <h3>2. Công việc thực hiện</h3>
          <Form.Item
            label={'a/ Nội dung công việc:'}
            name={'noi_dung_cong_viec'}>
            {!isRenderRichText && (
              <div style={{ height: 312, position: 'relative' }}>
                <SpinnerInlineComponent
                  sizeSpin={'small'}
                  tip="Đang tải dữ liệu..."
                  isLoading={!isRenderRichText}
                  backGround={'#ffffffc7'}
                />
              </div>
            )}
            {isRenderRichText && (
              <RichEditor
                EDITOR_REF={CONTENT_REF}
                placeholder={'Nhập nội dung...'}
                editorContent={
                  dataActions?.tier1[0]?.I_nhan_dien_rui_ro_va_danh_gia_rui_ro
                    ?.cong_viec_thuc_hien.noi_dung_cong_viec || ''
                }
              />
            )}
          </Form.Item>
          <Form.Item
            name={'nguoi_thuc_hien__cong_viec'}
            label={'b/ Người thực hiện:'}>
            <Input placeholder={'Nhập nội dung'} />
          </Form.Item>
          <Form.Item
            name={'thoi_gian_hoan_thanh'}
            label={'c/ Thời gian hoàn thành:'}>
            <Input placeholder={'Nhập nội dung'} />
          </Form.Item>

          <h3>
            3. Nhận diện rủi ro và đánh giá rủi ro{' '}
            <Button
              style={{ marginTop: 8, marginLeft: 8, color: blue }}
              onClick={() => {
                setIsVisibleRuiRoNhanDienTemplate(true)
              }}>
              Chọn
            </Button>
          </h3>
          <Form.Item name={'nhan_dien_rui_ro'} label={'a/ Nhận diện rủi ro:'}>
            {!isRenderRichText && (
              <div style={{ height: 312, position: 'relative' }}>
                <SpinnerInlineComponent
                  sizeSpin={'small'}
                  tip="Đang tải dữ liệu..."
                  isLoading={!isRenderRichText}
                  backGround={'#ffffffc7'}
                />
              </div>
            )}
            {isRenderRichText && (
              <RichEditor
                EDITOR_REF={NDRR_REF}
                placeholder={'Nhập nội dung...'}
                editorContent={
                  dataActions?.tier1[0]?.I_nhan_dien_rui_ro_va_danh_gia_rui_ro
                    .nhan_dien_rui_ro_va_danh_gia_rui_ro.nhan_dien_rui_ro || ''
                }
              />
            )}
          </Form.Item>
          <Form.Item
            name={'xay_dung_giai_phap_giam_thieu_phong_ngua'}
            label={'b/ Xây dựng giải pháp giảm thiểu, phòng ngừa:'}>
            {!isRenderRichText && (
              <div style={{ height: 312, position: 'relative' }}>
                <SpinnerInlineComponent
                  sizeSpin={'small'}
                  tip="Đang tải dữ liệu..."
                  isLoading={!isRenderRichText}
                  backGround={'#ffffffc7'}
                />
              </div>
            )}
            {isRenderRichText && (
              <RichEditor
                EDITOR_REF={XDGPGTPN_REF}
                placeholder={'Nhập nội dung...'}
                editorContent={
                  dataActions?.tier1[0]?.I_nhan_dien_rui_ro_va_danh_gia_rui_ro
                    .nhan_dien_rui_ro_va_danh_gia_rui_ro
                    .xay_dung_giai_phap_giam_thieu_phong_ngua || ''
                }
              />
            )}
          </Form.Item>
          <p>c/ Đánh giá rủi ro:</p>
          <RiskSelects>
            <Form.Item name={'p'} label={'P:'} style={{ width: '30%' }}>
              <Select
                onChange={value => {
                  setRiskPoint({ ...riskPoint, p: +value })
                }}>
                <Select.Option value="1">
                  1 - Rất thấp (Hiếm khi xảy ra)
                </Select.Option>
                <Select.Option value="2">
                  2 - Thấp (Đôi khi xảy ra)
                </Select.Option>
                <Select.Option value="3">
                  3 - Trung bình (Hay xảy ra)
                </Select.Option>
                <Select.Option value="4">
                  4 - Cao (Thường xuyên xảy ra)
                </Select.Option>
                <Select.Option value="5">
                  5 - Rất cao (Gần như chắc chắn xảy ra)
                </Select.Option>
              </Select>
            </Form.Item>
            <span style={{ marginTop: 16 }}>X</span>
            <Form.Item name={'s'} label={'S:'} style={{ width: '30%' }}>
              <Select
                onChange={value => {
                  setRiskPoint({ ...riskPoint, s: +value })
                }}>
                <Select.Option value="1">
                  1 - Không đáng kể (Ảnh hưởng thấp)
                </Select.Option>
                <Select.Option value="2">
                  2 - Vừa phải (Ảnh hưởng trung bình)
                </Select.Option>
                <Select.Option value="3">
                  3 - Quan trọng (Ảnh hưởng cao)
                </Select.Option>
                <Select.Option value="4">
                  4 - Trầm trọng (Ảnh hưởng nghiêm trọng)
                </Select.Option>
                <Select.Option value="5">
                  5 - Rất trầm trọng (Ảnh hưởng đặc biệt nghiêm trọng)
                </Select.Option>
              </Select>
            </Form.Item>
            <span style={{ marginTop: 16 }}>=</span>
            <Form.Item name={'r'} label={'R:'} style={{ width: '30%' }}>
              <Select>
                <Select.Option value="1">
                  1 -{'>'} 5 - Nguy cơ rủi ro thấp (Được phép thực hiện)
                </Select.Option>
                <Select.Option value="2">
                  6 -{'>'} 15 - Nguy cơ rủi ro trung bình (Hoạt động chỉ được
                  phép tiến hành với sự quản lý, kiểm soát thích hợp)
                </Select.Option>
                <Select.Option value="3">
                  16 -{'>'} 25 - Nguy cơ rủi ro cao (Hoạt động không được phép
                  tiến hành. Cần phải thiết lập các biện pháp kiểm soát chặt chẽ
                  hơn để giảm thiểu rủi ro)
                </Select.Option>
              </Select>
            </Form.Item>
          </RiskSelects>

          <Divider />

          <Form.Item name={'nguoi_thuc_hien__sign'} label={'Người thực hiện:'}>
            <Input placeholder={'Nhập nội dung'} />
          </Form.Item>

          <SelectPeopleWrapper>
            <SelectUserButton
              style={{ marginTop: 16 }}
              tooltipTitle="Chọn người"
              title="Lãnh đạo Đơn vị/NĐDPV phụ trách chung:"
              handleOnClick={() => {
                handleVisibleSelectOneUserPopup()
                handleOpenSelectUserDepartmentPopup(ASSIGNEE_TYPE.USER)
              }}
            />
            <SelectListComponent userList={selectUserList.USER} />
          </SelectPeopleWrapper>

          <Divider />

          <h3>II. XỬ LÝ RỦI RO</h3>
          <h3>1. Đề xuất loại rủi ro cần xử lý</h3>
          <Form.Item
            name={'loai_rui_ro_de_xuat'}
            label={'a/ Loại rủi ro đề xuất:'}>
            <Select>
              <Select.Option value="1">
                1 -{'>'} 5 - Nguy cơ rủi ro thấp (Được phép thực hiện)
              </Select.Option>
              <Select.Option value="2">
                6 -{'>'} 15 - Nguy cơ rủi ro trung bình (Hoạt động chỉ được phép
                tiến hành với sự quản lý, kiểm soát thích hợp)
              </Select.Option>
              <Select.Option value="3">
                16 -{'>'} 25 - Nguy cơ rủi ro cao (Hoạt động không được phép
                tiến hành. Cần phải thiết lập các biện pháp kiểm soát chặt chẽ
                hơn để giảm thiểu rủi ro)
              </Select.Option>
            </Select>
          </Form.Item>
          <Form.Item name={'bo_phan_de_xuat'} label={'b/ Bộ phận đề xuất:'}>
            <Input placeholder={'Nhập nội dung'} />
          </Form.Item>
          <Form.Item name={'bien_phap_xu_ly'} label={'c/ Biện pháp xử lý:'}>
            {!isRenderRichText && (
              <div style={{ height: 312, position: 'relative' }}>
                <SpinnerInlineComponent
                  sizeSpin={'small'}
                  tip="Đang tải dữ liệu..."
                  isLoading={!isRenderRichText}
                  backGround={'#ffffffc7'}
                />
              </div>
            )}
            {isRenderRichText && (
              <RichEditor
                EDITOR_REF={BPXL_REF}
                placeholder={'Nhập nội dung...'}
                editorContent={
                  dataActions?.tier1[0]?.II_xu_ly_rui_ro
                    .de_xuat_loai_rui_ro_can_xu_ly.bien_phap_xu_ly || ''
                }
              />
            )}
          </Form.Item>
          <Form.Item
            name={'nguoi_thuc_hien__de_xuat'}
            label={'d/ Người thực hiện:'}>
            <Input placeholder={'Nhập nội dung'} />
          </Form.Item>
          <h3>2. Lãnh đạo quyết định biện pháp xử lý</h3>
          <Form.Item
            name={'lanh_dao_quyet_dinh_bien_phap_xu_ly'}
            label={'Lãnh đạo:'}>
            <Input placeholder={'Nhập nội dung'} />
          </Form.Item>

          <Divider />

          <Form.Item
            name={'don_vi_thuc_hien_xu_ly_rui_ro'}
            label={'ĐƠN VỊ THỰC HIỆN XỬ LÝ RỦI RO:'}>
            <Input placeholder={'Nhập nội dung'} />
          </Form.Item>

          <SelectPeopleWrapper>
            <SelectUserButton
              tooltipTitle="Chọn người"
              style={{ marginTop: 16 }}
              title="LÃNH ĐẠO VIMC(theo phân công trách nhiệm quản lý rủi ro):"
              handleOnClick={() => {
                handleVisibleSelectOneUserPopup()
                handleOpenSelectUserDepartmentPopup(ASSIGNEE_TYPE.lanh_dao_vimc)
              }}
            />
            <SelectListComponent userList={selectUserList.lanh_dao_vimc} />
          </SelectPeopleWrapper>

          <FormActionFooter style={{ marginBottom: 0 }}>
            <Button icon={<CloseOutlined />} danger onClick={handleCancelModal}>
              Đóng
            </Button>
            <Button
              style={{ marginLeft: 10 }}
              type={'primary'}
              htmlType={'submit'}>
              Chỉnh sửa
            </Button>
          </FormActionFooter>
        </Form>
      </Modal>
      <RuiRoNhanDienTemplate
        isVisible={isVisibleRuiRoNhanDienTemplate}
        handleCancelModal={() => setIsVisibleRuiRoNhanDienTemplate(false)}
        riskStore={riskStore}
        setRuiRoNhanDien={setRuiRoNhanDien}
      />
    </>
  )
}

export default inject(
  'riskStore',
  'actionStore',
  'selectPeopleStore'
)(observer(RiskIdentificationCreatePopup))
