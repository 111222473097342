import React, { useCallback, useEffect, useRef, useState } from 'react'
// Component
import ApprovalList from '../../pages/Administrative/LeaveForm/ApproverListModal/Modal'
// Styled Component
import { FormButtonRow } from './SubmissionFormStyled'
import { SelectList } from '../../pages/MissionCreatePage/MissionCreatePageStyled'
// Ant design
import {
  BankOutlined,
  FileTextOutlined,
  TeamOutlined,
  UploadOutlined,
  UserOutlined,
} from '@ant-design/icons'
import {
  Button,
  Col,
  Form,
  Input,
  message,
  Row,
  Space,
  Tag,
  Tooltip,
  Upload,
  Select,
} from 'antd'
// Other
import { inject, observer } from 'mobx-react'
import { blue } from '../../color'
import { ASSIGNEE_TYPE, PROPOSAL_STATUS } from '../../constants'
import RichEditor from '../RichEditor/RichEditor'
import SelectPeoplePopup from '../SelectPeoplePopup/SelectPeoplePopup'
import SelectGroupPopup from '../SelectGroupPopup/SelectGroupPopup'
import { AvatarHasName } from '../Common/Avatar'
import ProposalStepApprove from './ProposalStepApprove'

const { TextArea } = Input

const currentStep = 0
const OtherSubmissionForm = props => {
  const {
    proposalStore,
    fileStore,
    loadingAnimationStore,
    authenticationStore,
    onCloseModal,
    proposalType,
    handleDownloadAttachment,
    selectPeopleStore,
    contentTemplateStore,
    approvalTemplateStore,
    selectedApprovalId,
    setSelectedApprovalId,
  } = props

  const { items, content } = contentTemplateStore

  const {
    selectUserData,
    selectDepartmentData,
    selectGroupData,
    isVisibleSelectGroupPopup,
    setIsVisibleSelectGroupPopup,
    isVisibleSelectUserDepartmentPopup,
    setIsVisibleSelectUserDepartmentPopup,
    handleOpenSelectUserDepartmentPopup,
    handleCancelSelectUserDepartment,
    handleOpenSelectGroupPopup,
    handleCancelSelectGroup,
  } = selectPeopleStore

  //Get proposal type
  const { formProposalType, formInputTemplate } = proposalStore

  const { form } = Form.useForm()
  const EDITOR_REF = useRef()

  const [fileList, setFileList] = useState([])
  const [editorContent, setEditorContent] = useState()
  const [handlerList, setHandlerList] = useState([])
  const [followerList, setFollowerList] = useState([])
  const [applyBieuMau, setApplyBieuMau] = useState(false)

  //Lấy approvalLevels
  const [approvalLevels, setApprovalLevels] = useState([])

  const getApprovalLevelTemplate = async code => {
    try {
      const response = await approvalTemplateStore.getApprovalLevelTemplateByATCode(
        code
      )
      const approvalLevelsChange = response.data.map(
        (approvalLevel, index) => ({
          status: index === 0 ? 'CREATED' : 'WAITING',
          allow_edit: approvalLevel.allow_edit,
          code: approvalLevel.code,
          isDeleted: approvalLevel.isDeleted,
          level: approvalLevel.level,
          name: approvalLevel.name,
          username: approvalLevel.username,
        })
      )

      setApprovalLevels(approvalLevelsChange)
    } catch (err) {
      console.log(err)
      message.error(err?.vi || 'Đã có lỗi xảy ra!')
    }
  }
  useEffect(() => {
    ;(async () => {
      try {
        await getApprovalLevelTemplate(selectedApprovalId)
      } catch (err) {
        console.log(err)
        message.error(err?.vi || 'Đã có lỗi xảy ra!')
      }
    })()
  }, [selectedApprovalId])

  useEffect(() => {
    setHandlerList([
      ...selectUserData[ASSIGNEE_TYPE.HANDLER],
      ...selectDepartmentData[ASSIGNEE_TYPE.HANDLER],
      ...selectGroupData[ASSIGNEE_TYPE.HANDLER],
    ])
    setFollowerList([
      ...selectUserData[ASSIGNEE_TYPE.FOLLOWER],
      ...selectDepartmentData[ASSIGNEE_TYPE.FOLLOWER],
      ...selectGroupData[ASSIGNEE_TYPE.FOLLOWER],
    ])
  }, [selectUserData, selectDepartmentData, selectGroupData])

  useEffect(() => {
    contentTemplateStore.load()

    return () => {
      contentTemplateStore.clear()
    }
  }, [])

  const onChange = value => {
    contentTemplateStore.getDetails(value)
  }

  const handleChangeFile = useCallback(
    info => {
      const filteredFileList = info.fileList.filter(
        (elem, index, fileArray) =>
          fileArray.findIndex(file => file.name === elem.name) === index
      )
      setFileList(filteredFileList)
    },
    [fileList]
  )

  /** Xử lý ghép API tạo phiếu trình nghỉ phép */
  const handleCreateOther = async data => {
    try {
      await proposalStore.createOther(data)
      proposalStore.setActiveTabProposal('')
      proposalStore.clearFilterProposal()
      proposalStore.resetFormProposalType()
      onCloseModal()
      await proposalStore.getProposals()
      message.success('Tạo mới thành công!')
    } catch (error) {
      console.log(error)
      message.error(error.vi || 'Có lỗi xảy ra, vui lòng thử lại!')
    }
  }
  const onFinish = async values => {
    let hasWarning = false
    proposalStore.defaultApprovalConfigUpdate.approvalLevels.forEach(item => {
      if (!item?.username) {
        message.warning(`Hãy chọn ${item.name}!`)
        hasWarning = true
      }
    })
    if (values.title.trim() == '') {
      message.warning(`Hãy nhập tiêu đề!`)
      hasWarning = true
    }
    if (hasWarning) return
    const submitValues = {
      ...values,
      title: values.title.trim(),
      proposal_type: 'DEFAULT_TYPE', //Set DEFAULT_TYPE cho các hành chính không fix cứng
      followers: followerList.map(el => el.id),
      // assignees: [...handlerAssignees, ...followerAssignees],
      reason: EDITOR_REF.current.editor.getData(),
      approval_levels: proposalStore.defaultApprovalConfigUpdate.approvalLevels.map(
        item => ({
          level: item.level,
          name: item.name,
          username: item.username,
          allow_edit: item.allow_edit,
          status:
            item.level === currentStep + 1
              ? PROPOSAL_STATUS.PENDING
              : item.status,
        })
      ),
    }
    const uploadFileList =
      submitValues.file_ids && submitValues.file_ids.fileList
        ? submitValues.file_ids.fileList.map(file => file.originFileObj)
        : []
    const batchUploadArr = []
    uploadFileList.forEach(file => {
      const formData = new FormData()
      formData.append('file', file)
      batchUploadArr.push(fileStore.uploadFile(formData))
    })
    let uploadedFiles = []
    try {
      loadingAnimationStore.showSpinner(true)
      const response = await Promise.all(batchUploadArr)
      uploadedFiles = response.map(response => response.data.file_id)
      submitValues.file_ids = uploadedFiles
      await handleCreateOther(submitValues)
      handleCloseAndReset()
      setFileList([])
      loadingAnimationStore.showSpinner(false)
    } catch (err) {
      console.log(err)
      message.error(err.vi || 'Có lỗi xảy ra')
    } finally {
      loadingAnimationStore.showSpinner(false)
    }
  }

  const onApplyBieuMauChange = value => {
    setApplyBieuMau(value)
    if (value) {
      if (formInputTemplate) {
        setEditorContent(formInputTemplate)
      }
    }
  }
  const handleCloseAndReset = () => {
    onCloseModal()
    contentTemplateStore.content = ''
    setSelectedApprovalId(null)
  }

  return (
    <Form
      scrollToFirstError={true}
      form={form}
      name={'phieu-trinh-khac-form'}
      layout={'vertical'}
      onFinish={onFinish}>
      <Row type={'flex'} gutter={15}>
        <Col xs={24} lg={24}>
          <Form.Item
            name={'title'}
            label={'Tiêu đề:'}
            rules={[
              { required: true, message: 'Vui lòng nhập tiêu đề' },
              { max: 500, message: 'Tiêu đề không được dài quá 500 kí tự!' },
            ]}>
            <Input />
          </Form.Item>
        </Col>
        <Col xs={24} lg={24}>
          <Form.Item
            label={
              <div>
                <p>Nội dung:</p>
                <Select
                  showSearch
                  placeholder="Chọn biểu mẫu"
                  optionFilterProp="children"
                  onChange={onChange}
                  filterOption={(input, option) =>
                    (option?.children ?? '')
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  style={{ width: '300px' }}>
                  {items?.map((item, index) => {
                    return (
                      <Select.Option key={index} value={item.key}>
                        {item.label}
                      </Select.Option>
                    )
                  })}
                </Select>
              </div>
            }>
            <RichEditor
              EDITOR_REF={EDITOR_REF}
              placeholder={'Nhập nội dung'}
              editorContent={content && content}
            />
          </Form.Item>
        </Col>
        <Col xs={24} lg={24}>
          <Form.Item name={'file_ids'} label={'Tài liệu đính kèm:'}>
            <Upload
              valuePropName={'fileList'}
              fileList={fileList}
              onChange={handleChangeFile}
              beforeUpload={() => false}
              multiple={true}
              iconRender={() => <FileTextOutlined />}>
              <Button icon={<UploadOutlined />}>Chọn tài liệu đính kèm</Button>
            </Upload>
          </Form.Item>
        </Col>
        <Col xs={24} lg={12}>
          <label style={{ display: 'inline-block', margin: '15px 8px 25px 0' }}>
            Người theo dõi:
          </label>

          <Space>
            <Tooltip title="Chọn người dùng" color={blue}>
              <Button
                icon={<UserOutlined style={{ color: blue }} />}
                onClick={() =>
                  handleOpenSelectUserDepartmentPopup(ASSIGNEE_TYPE.FOLLOWER)
                }
              />
            </Tooltip>
          </Space>

          <SelectList>
            {followerList.map(el => (
              <Tag
                className={'tag-selected'}
                color={'blue'}
                key={el.id}
                closable
                onClose={() => selectPeopleStore.handleRemoveSelect(el)}>
                <AvatarHasName
                  imgId={el.image_id}
                  size={22}
                  name={el.full_name}
                  icon={
                    (el.assignee_type === ASSIGNEE_TYPE.DEPARTMENT && (
                      <BankOutlined />
                    )) ||
                    (el.assignee_type === ASSIGNEE_TYPE.GROUP && (
                      <TeamOutlined />
                    ))
                  }
                />
              </Tag>
            ))}
          </SelectList>
        </Col>
      </Row>

      <ProposalStepApprove
        approvalLevelList={approvalLevels}
        styleCre={'create'}
      />

      <FormButtonRow>
        <Button
          onClick={() => {
            handleCloseAndReset()
          }}>
          Hủy bỏ
        </Button>
        <Button style={{ marginLeft: 10 }} htmlType={'submit'} type={'primary'}>
          Tạo mới
        </Button>
      </FormButtonRow>

      <ApprovalList />

      <SelectPeoplePopup
        noLoadSelectData
        disableSelectDepartment
        isOpenCollapse={true}
        handleCancelSelectUser={handleCancelSelectUserDepartment}
        handleSubmitSelectUser={() =>
          setIsVisibleSelectUserDepartmentPopup(false)
        }
        isVisibleSelectPeoplePopup={isVisibleSelectUserDepartmentPopup}
      />
      <SelectGroupPopup
        isVisibleSelectGroupPopup={isVisibleSelectGroupPopup}
        handleCancelSelectGroup={handleCancelSelectGroup}
        handleSubmitSelectGroup={() => setIsVisibleSelectGroupPopup(false)}
      />
    </Form>
  )
}

OtherSubmissionForm.propTypes = {}

export default inject(
  'leavePageStore',
  'proposalStore',
  'authenticationStore',
  'fileStore',
  'loadingAnimationStore',
  'selectUserStore',
  'selectPeopleStore',
  'contentTemplateStore',
  'approvalTemplateStore'
)(observer(OtherSubmissionForm))
