import React, {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { Helmet } from 'react-helmet/es/Helmet'
import ContentBlockWrapper from '../../components/ContentBlockWrapper'
import PageTitle from '../../components/PageTitle'
import { inject, observer } from 'mobx-react'
import { Dropdown, Form, Menu, message, Modal, Tooltip } from 'antd'
import {
  BlockLeft,
  BlockRight,
  DocumentContentWrapper,
  DocumentInfoList,
  HeadingButtonWrapper,
} from '../../layouts/ConnectedCommitteeDocumentDetailLayout/ConnectedCommitteeDocumentDetailLayoutStyled'
import ConnectedCommitteeDocumentUserReadingStatusModal from '../../components/ConnectedCommitteeDocumentUserReadingStatusModal'
import {
  CalendarOutlined,
  DeleteOutlined,
  FilePdfOutlined,
  HistoryOutlined,
  MoreOutlined,
  StopOutlined,
} from '@ant-design/icons'
import { useMediaQuery } from 'react-responsive' // PDF Viewer
import moment from 'moment'
import ConnectedCommitteeDocumentOutgoingHistoryBlock from '../../components/ConnectedCommitteeDocumentOutgoingHistoryBlock'
import utils from '../../utils'
import TextArea from 'antd/es/input/TextArea'
import { Link } from 'react-router-dom'
import FileDownload from 'js-file-download'
import PDFViewer from '../../components/PDFViewer/PDFViewer'
import DashboardLayout from '../../layouts/DashboardLayout/DashboardLayout'
import { TitleContentBox, WordBreak } from '../../components/Common/CellText'
import { blue, redPrimary } from '../../color'
import { DATE_FORMAT_DEFAULT } from '../../constants'

const ConnectedCommitteeDocumentOutgoingDocDetailPage = props => {
  const [recover, setShowRecover] = useState(false)
  const RECOVER = { id: '13', description: 'Yêu cầu lấy lại văn bản điện tử' }

  const [form] = Form.useForm()

  const isTabletOrMobile = useMediaQuery({
    query: '(max-width: 1299px)',
  })

  const {
    match,
    commonStore,
    connectedCommitteeDocumentStore,
    loadingAnimationStore,
    fileStore,
  } = props

  const [showUserReadingModal, setShowUserReadingModal] = useState(false)

  const { documentId } = match.params
  const {
    selectedOutgoingCommitteeDocument,
    selectedOutgoingCommitteeDocumentAttachment,
  } = connectedCommitteeDocumentStore
  const {
    attachments,
    bussiness_doc_type,
    code,
    history,
    receiver_organ_id,
    receiver_organ_name,
    sender_organ_id,
    sender_organ_name,
    sender_sent_time,
    status,
    title,
    edocs_bussiness,
  } = selectedOutgoingCommitteeDocument

  const [loading, setLoading] = useState(false)

  const handleDownloadAttachment = useCallback(
    attachmentName => {
      loadingAnimationStore.showSpinner(true)
      fileStore
        .getFileByEdocOutgoingId(documentId, attachmentName)
        .then(response => {
          const blob = new Blob([response.data], { type: 'application/pdf' })
          FileDownload(blob, attachmentName)
          return Promise.resolve()
        })
        .catch(error => {
          console.log('error', error)
          return Promise.reject()
        })
        .finally(() => loadingAnimationStore.showSpinner(false))
    },
    [documentId]
  )

  const handleDownloadDocumentEDXML = () => {
    loadingAnimationStore.showSpinner(true)
    connectedCommitteeDocumentStore
      .getSelectedOutgoingCommitteeDocumentEdxml(documentId)
      .then(response => {
        loadingAnimationStore.showSpinner(false)
        const blob = new Blob([response.data], {
          type: 'application/octet-stream',
        })
        FileDownload(blob, `${documentId}.edxml`)
      })
      .catch(() => {
        loadingAnimationStore.showSpinner(false)
      })
  }

  useEffect(() => {
    loadingAnimationStore.showSpinner(true)
    setLoading(true)
    connectedCommitteeDocumentStore
      .getOutgoingCommitteeDocumentById(documentId)
      .then(response => {
        if (response.data.attachments) {
          loadingAnimationStore.showSpinner(true)
          connectedCommitteeDocumentStore
            .getSelectedOutgoingCommitteeDocumentAttachment(
              documentId,
              response.data.attachments[0]
            )
            .finally(() => {
              loadingAnimationStore.showSpinner(false)
            })
        }
      })
      .catch(err => {
        props.history.push('/connected-committee-document/outgoing-document')
        console.log(err)
        message.error(err.vi || 'Đã có lỗi xảy ra!')
      })
      .finally(() => {
        setLoading(false)
        loadingAnimationStore.showSpinner(false)
      })

    return () => {
      form.resetFields()
      connectedCommitteeDocumentStore.clearSelectedOutgoingCommitteeDocument()
    }
  }, [documentId])
  const renderAction = bussiness_doc_type => {
    switch (bussiness_doc_type) {
      case '0':
        return 'tạo mới'
      case '1':
        return 'thu hồi'
      case '2':
        return 'cập nhật'
      case '3':
        return 'thay thế'
      default:
        return ''
    }
  }
  const renderInfoText = string => {
    if (string) {
      if (string.length <= 20) return string
      return (
        <Tooltip title={string}>
          {string.substring(0, 20).concat('...')}
        </Tooltip>
      )
    }
  }

  const reasonRecover = useRef(null)
  const handleRecover = () => {
    loadingAnimationStore.showSpinner(true)
    const reason = reasonRecover.current.state.value
    connectedCommitteeDocumentStore
      .updateStatusOutgoingCommitteeDocumentById(
        documentId,
        RECOVER.id,
        reason && reason.length > 0 ? reason : RECOVER.description,
        null
      )
      .then(() => {
        message.success('Gửi yêu cầu lấy lại văn bản thành công!')
      })
      .catch(error => {
        message.error(error.vi)
      })
      .finally(() => {
        setShowRecover(false)
        connectedCommitteeDocumentStore
          .getOutgoingCommitteeDocumentById(documentId)
          .finally(() => loadingAnimationStore.showSpinner(false))
      })
    reasonRecover.current.state.value = undefined
  }

  const renderAttachmentFile = useMemo(
    () => (
      <PDFViewer
        file={selectedOutgoingCommitteeDocumentAttachment}
        customStyleObj={{ height: isTabletOrMobile ? 500 : 1000 }}
      />
    ),
    [selectedOutgoingCommitteeDocumentAttachment]
  )

  const documentHandleDropdown = (
    <Menu>
      <Menu.Item
        style={{ color: blue }}
        icon={<HistoryOutlined />}
        onClick={() =>
          props.history.push(
            `/connected-committee-document/outgoing-document/update/${documentId}`
          )
        }>
        Cập nhật văn bản
      </Menu.Item>
      <Menu.Item
        style={{ color: 'red' }}
        onClick={() => setShowRecover(true)}
        icon={<StopOutlined />}>
        <span>Yêu cầu lấy lại văn bản</span>
      </Menu.Item>
      <Menu.Item
        danger
        onClick={() => message.warning('Chức năng đang cập nhật!')}
        icon={<DeleteOutlined />}>
        Xóa văn bản
      </Menu.Item>
    </Menu>
  )

  return (
    <DashboardLayout>
      <Helmet>
        <title>{`${title} | Can Tho Port Quản lý VB nội bộ`}</title>
      </Helmet>
      <PageTitle
        location={props.location}
        title={title ? title : 'Undefined'}
        showTitle={true}
        color={commonStore.appTheme.solidColor}>
        <HeadingButtonWrapper>
          {status !== '100' && status !== '200' && status !== '300' && (
            <Dropdown overlay={documentHandleDropdown} trigger={['click']}>
              <span className={'btn-style'}>
                <MoreOutlined />
              </span>
            </Dropdown>
          )}
        </HeadingButtonWrapper>
      </PageTitle>
      <DocumentContentWrapper>
        <BlockLeft>{renderAttachmentFile}</BlockLeft>

        <BlockRight>
          <ContentBlockWrapper>
            <TitleContentBox>Thông tin chung</TitleContentBox>
            <DocumentInfoList>
              <dt>Số văn bản</dt>
              <dd>{code && renderInfoText(code)}</dd>
              <dt>Nơi ban hành</dt>
              <dd>{sender_organ_name && WordBreak(sender_organ_name)}</dd>
              <dt>Nơi nhận</dt>
              <dd>{receiver_organ_name && WordBreak(receiver_organ_name)}</dd>
              <dt>Ngày gửi</dt>
              <dd>
                {sender_sent_time && (
                  <>
                    <CalendarOutlined style={{ color: blue, marginRight: 4 }} />
                    <span>
                      {moment(sender_sent_time).format(DATE_FORMAT_DEFAULT)}
                    </span>
                  </>
                )}
              </dd>
              <dt>Trạng thái</dt>
              <dd>
                {status !== undefined &&
                  utils.renderDocumentCommitteeStatus(status)}
              </dd>
              {edocs_bussiness &&
                edocs_bussiness.length > 0 &&
                edocs_bussiness.map(item => (
                  <Fragment key={item.doc_id}>
                    <dt>{`Văn bản bị ${renderAction(bussiness_doc_type)}`}</dt>
                    <dd>
                      <Tooltip title={item.title}>
                        <Link
                          to={`/connected-committee-document/outgoing-document/view/${item.doc_id}`}>
                          {item.code}
                        </Link>
                      </Tooltip>
                    </dd>
                  </Fragment>
                ))}
              <dt>Tài liệu đính kèm</dt>
              <dd>
                <div>
                  {attachments &&
                    attachments.map(file => (
                      <div style={{ display: 'block' }} key={file}>
                        <FilePdfOutlined style={{ color: redPrimary }} />
                        &nbsp;
                        <a
                          className={'file-item'}
                          onClick={() => handleDownloadAttachment(file)}
                          key={file}>
                          {file}
                        </a>
                      </div>
                    ))}
                </div>
              </dd>
              <dt>EDXML</dt>
              <dd>
                <a onClick={handleDownloadDocumentEDXML}>Tải file</a>
              </dd>
            </DocumentInfoList>
          </ContentBlockWrapper>
          <div style={{ marginBottom: 15 }} />
          <ContentBlockWrapper>
            <ConnectedCommitteeDocumentOutgoingHistoryBlock
              title={'Lịch sử văn bản đi'}
              history={history}
            />
          </ContentBlockWrapper>
        </BlockRight>
      </DocumentContentWrapper>

      <ConnectedCommitteeDocumentUserReadingStatusModal
        modalVisible={showUserReadingModal}
        onClose={() => setShowUserReadingModal(false)}
      />

      <Modal
        visible={recover}
        cancelText={'Huỷ'}
        onCancel={() => setShowRecover(false)}
        okText={'Yêu cầu lấy lại văn bản'}
        okType={'danger'}
        onOk={handleRecover}
        title={
          <Fragment>
            <StopOutlined
              style={{
                color: 'red',
                marginRight: 10,
              }}
            />
            Lấy lại văn bản
          </Fragment>
        }>
        <TextArea
          ref={reasonRecover}
          rows={4}
          placeholder={'Nhập lý do lấy lại'}
        />
      </Modal>
    </DashboardLayout>
  )
}

ConnectedCommitteeDocumentOutgoingDocDetailPage.propTypes = {}

export default inject(
  'commonStore',
  'connectedCommitteeDocumentStore',
  'loadingAnimationStore',
  'authenticationStore',
  'fileStore',
)(observer(ConnectedCommitteeDocumentOutgoingDocDetailPage))
