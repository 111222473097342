import React, { memo } from 'react'
import { Helmet } from 'react-helmet/es/Helmet'
// Component
import DashboardLayout from '../../../layouts/DashboardLayout'
import { Container } from '../../../layouts/Container/Container'
import ContentBlockWrapper from '../../../components/ContentBlockWrapper'
import PageTitle from '../../../components/PageTitle'
import { inject, observer } from 'mobx-react'
import InternalDocumentOutgoingDocCreateForm from './InternalDocumentOutgoingDocCreateForm'
import { useHistory } from 'react-router-dom'

const InternalDocumentOutgoingDocCreatePage = props => {
  const { authenticationStore } = props

  const history = useHistory()

  return (
    <DashboardLayout>
      <Helmet>
        <title>Tạo văn bản đi | Quản lý VB Can Tho Port</title>
      </Helmet>
      <PageTitle location={props.location} title={'Tạo văn bản đi'} />
      <ContentBlockWrapper>
        <Container maxWidth={1000}>
          <InternalDocumentOutgoingDocCreateForm
            handleCancel={() =>
              history.push('/internal-document/outgoing-document')
            }
          />
        </Container>
      </ContentBlockWrapper>
    </DashboardLayout>
  )
}

InternalDocumentOutgoingDocCreatePage.propTypes = {}

export default memo(
  inject('authenticationStore')(observer(InternalDocumentOutgoingDocCreatePage))
)
