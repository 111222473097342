import React, { useEffect, useState } from 'react'
import DashboardLayout from '../../layouts/DashboardLayout/DashboardLayout'
import { Helmet } from 'react-helmet/es/Helmet'
import PageTitle from '../../components/PageTitle'
import ContentBlockWrapper from '../../components/ContentBlockWrapper'
import { blue } from '../../color'
import { Button, message, Tooltip } from 'antd'
import { PlusOutlined, SaveOutlined, TrophyFilled } from '@ant-design/icons'
import SubTargetList from './SubTargetList'
import './KpiEditSubTargetPage.css'
import { inject, observer } from 'mobx-react'
import SpinnerInlineComponent from '../../components/SpinnerInlineComponent/SpinnerInlineComponent'
import SubTargetCreateModal from './SubTargetCreateModal'
import { useParams } from 'react-router-dom'
import { EmptyText } from '../../components/Common/CellText'
import { ACL_ACTION_TYPE } from '../../constants'
import AccessControlAction from '../../components/AccessControlAction/AccessControlAction'

//kpi/target/view/KPI27257840435000/KPI27336715164900
//Edit mục tiêu

const KpiEditSubTargetPage = props => {
  const { authenticationStore, kpiStore } = props

  const { kpi_quarter_code, kpiId, targetId } = useParams()

  const { targetKpiDetail, actualWeightTarget } = kpiStore

  const [isLoadingTargetKpi, setIsLoadingTargetKpi] = useState(false)

  useEffect(() => {
    kpiStore.quarterCode = kpi_quarter_code
  }, [kpi_quarter_code])

  useEffect(() => {
    // console.log('KpiEditTargetPage', kpiId, targetId)

    ;(async () => {
      try {
        setIsLoadingTargetKpi(true)
        await kpiStore.getTargetKpiDetail(targetId)
      } catch (err) {
        console.log(err)
        message.error(err.vi || 'Đã có lỗi xảy ra!')
      } finally {
        setIsLoadingTargetKpi(false)
      }
    })()

    return () => {
      kpiStore.clearTargetKpiDetail()
    }
  }, [kpiId, targetId])

  const save = async () => {
    setIsLoadingTargetKpi(true)

    await kpiStore.updateSubTargetValues()
    await kpiStore.getTargetKpiDetail(targetId)

    setIsLoadingTargetKpi(false)

    message.success('Cập nhật thành công')
  }

  const onDelete = async obj => {
    setIsLoadingTargetKpi(true)

    await kpiStore.delete(obj.code)
    await kpiStore.getTargetKpiDetail(targetId)

    setIsLoadingTargetKpi(false)

    message.success('Xóa thành công')
  }

  const [
    isVisibleSubTargetCreateModal,
    setIsVisibleSubTargetCreateModal,
  ] = useState(false)
  return (
    <DashboardLayout>
      <Helmet>
        <title>Chi tiết sửa mục tiêu KPI | Can Tho Port KPI</title>
      </Helmet>
      <PageTitle
        location={props.location}
        showTitle={true}
        title={'Chi tiết mục tiêu'}
      />
      <ContentBlockWrapper
        style={{
          position: 'relative',
          minHeight: 'calc(100vh - 190px)',
          overflow: 'hidden',
        }}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            gap: 20,
            marginBottom: 16,
          }}>
          <div>
            <div
              style={{
                fontSize: 14,
                fontWeight: 500,
                color: blue,
                textDecoration: 'underline',
              }}>
              <TrophyFilled
                style={{
                  fontSize: 16,
                  color: blue,
                  fontWeight: 700,
                  marginRight: 10,
                }}
              />
              Mục tiêu:
            </div>
            <div
              style={{
                fontSize: 16,
                fontWeight: 500,
              }}>
              {targetKpiDetail.name || (
                <EmptyText>Không rõ tên mục tiêu</EmptyText>
              )}
            </div>
          </div>
          <div className={'weight-number-sub-target'}>
            <div className={'summary-weight-value'}>
              Tỉ Trọng cần đạt:
              <span
                style={{
                  color: '#f5222d',
                  fontWeight: 700,
                  marginLeft: 4,
                  fontSize: 14,
                }}>
                {targetKpiDetail.expect_weight}%
              </span>
            </div>
            <div className={'summary-weight-value'}>
              Tỉ Trọng đạt được:
              <span
                style={{
                  color: '#389e0d',
                  fontWeight: 700,
                  marginLeft: 4,
                  fontSize: 14,
                }}>
                {!isNaN(actualWeightTarget) && actualWeightTarget}%
              </span>
            </div>
          </div>
        </div>
        <div
          style={{
            display: 'flex',
            gap: 10,
            marginBottom: 12,
          }}>
          <AccessControlAction aclActionType={ACL_ACTION_TYPE.create__KPI}>
            <Button
              block
              type={'dashed'}
              icon={<PlusOutlined style={{ color: blue }} />}
              onClick={() => setIsVisibleSubTargetCreateModal(true)}
              style={{
                fontWeight: 500,
                textTransform: 'uppercase',
                borderColor: blue,
              }}>
              Thêm chỉ tiêu
            </Button>
          </AccessControlAction>
          <AccessControlAction aclActionType={ACL_ACTION_TYPE.edit__KPI}>
            <Tooltip
              title={
                !kpiStore.allowSaveSubTarget
                  ? 'Số liệu tỉ trọng chưa hợp lệ!'
                  : ''
              }>
              <Button
                block
                icon={<SaveOutlined style={{ color: '#237804' }} />}
                type={'dashed'}
                disabled={!kpiStore.allowSaveSubTarget}
                onClick={save}
                style={{
                  fontWeight: 500,
                  textTransform: 'uppercase',
                  borderColor: blue,
                  cursor: 'pointer',
                }}>
                Lưu lại
              </Button>
            </Tooltip>
          </AccessControlAction>
        </div>
        <SubTargetList onDelete={onDelete} />
        <SpinnerInlineComponent
          alignItems={'start'}
          sizeSpin={'small'}
          isLoading={isLoadingTargetKpi}
        />
      </ContentBlockWrapper>
      <SubTargetCreateModal
        isVisibleSubTargetCreateModal={isVisibleSubTargetCreateModal}
        handleCloseSubTargetCreateModal={() =>
          setIsVisibleSubTargetCreateModal(false)
        }
      />
    </DashboardLayout>
  )
}

KpiEditSubTargetPage.propTypes = {}

export default inject(
  'kpiStore',
  'authenticationStore'
)(observer(KpiEditSubTargetPage))
