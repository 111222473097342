import React, { useEffect, useState } from 'react'
import { useRef } from 'react'
import { Button, Col, Form, Input, message, Modal, Row, Select } from 'antd'
import { FormActionFooter } from '../ConnectedDocumentIncomingDocCreatePage/ConnectedDocumentIncomingDocCreatePageStyled'
import { CloseOutlined } from '@ant-design/icons'
import { inject, observer } from 'mobx-react'
import { Label } from 'react-konva'
import utils from '../../utils'
import { SelectPeopleWrapper } from '../WorkPage/PopupVote/PopupVoteStyled'
import SelectUserButton from '../../components/Common/SelectUserButton'
import SelectListComponent from '../../components/Common/SelectListComponent'
import { ASSIGNEE_TYPE } from '../../constants'
import SpinnerInlineComponent from '../../components/SpinnerInlineComponent'
import RichEditor from '../../components/RichEditor/RichEditor'

const RiskControlResult = ({
  handleCancel,
  isVisible,
  actionStore,
  riskStore,
  getRiskDetail,
  selectUserList,
  selectPeopleStore,
  handleVisibleSelectOneUserPopup,
}) => {
  const { riskDetail } = riskStore
  const {
    indexDetailAction,
    dataActions,
    setIndexDetailAction,
    setDataActions,
    createAction,
    updateAction,
    deleteAction,
  } = actionStore
  const {
    handleOpenSelectUserDepartmentPopup,
    setSelectUserData,
    selectUserData,
  } = selectPeopleStore

  const [form] = Form.useForm()
  const [isRenderRichText, setIsRenderRichText] = useState(false)
  const OPINION_REF = useRef()

  useEffect(() => {
    if (isVisible) {
      setTimeout(() => {
        setIsRenderRichText(true)
      }, 500)
    } else {
      setIsRenderRichText(false)
    }
  }, [isVisible])

  useEffect(() => {
    const fillData = dataActions?.tier4[0]
    form.setFieldsValue({
      don_vi_thuc_hien_xu_ly_rui_ro:
        dataActions?.tier1[0]?.II_xu_ly_rui_ro.don_vi_thuc_hien_xu_ly_rui_ro,
    })

    if (!fillData || indexDetailAction?.tier !== '4') {
      return
    }
    setSelectUserData({
      ...selectUserData,
      [ASSIGNEE_TYPE.ban_pc_qtrr_ban_kt_ktnb_vimc]:
        fillData.ban_pc_qtrr_ban_kt_ktnb_vimc,
      [ASSIGNEE_TYPE.ban_dieu_hanh_hdqt_vimc]: fillData.ban_dieu_hanh_hdqt_vimc,
    })
    form.setFieldsValue({
      ket_qua: fillData.ket_qua,
      // ban_pc_qtrr_ban_kt_ktnb_vimc: fillData.ban_pc_qtrr_ban_kt_ktnb_vimc,
      // ban_dieu_hanh_hdqt_vimc: fillData.ban_dieu_hanh_hdqt_vimc,
    })
  }, [indexDetailAction])

  const handleCancelModal = () => {
    handleCancel()
  }
  const handleCreateRiskControlResult = async value => {
    const submitData = {
      ...value,
      ban_pc_qtrr_ban_kt_ktnb_vimc: selectUserList.ban_pc_qtrr_ban_kt_ktnb_vimc,
      ban_dieu_hanh_hdqt_vimc: selectUserList.ban_dieu_hanh_hdqt_vimc,
      cac_y_kien_lien_quan: OPINION_REF.current?.editor.getData(),
    }
    if (riskDetail?.tiers) {
      if (riskDetail?.tiers[3]) {
        await updateAction(riskDetail?.tiers[3]?.actions[0]?.code, {
          name: 'Rủi ro nhận diện 4 update',
          data: JSON.stringify(submitData),
          risk_tier_code: riskDetail?.tiers[3]?.code,
        })
      } else {
        await createAction({
          name: 'ignore',
          data: JSON.stringify(submitData),
          risk_tier_code: riskDetail?.tiers[3]?.code,
        })
      }
      message.success('Lưu thành công')
      handleCancelModal()
      getRiskDetail()
    } else {
      setDataActions({ ...dataActions, tier4: [submitData] })
      message.success('Lưu thành công')
      handleCancelModal()
    }
  }
  return (
    <Modal
      width={800}
      destroyOnClose={true}
      afterClose={() => {
        setIsRenderRichText(false)
      }}
      zIndex={500}
      style={{ top: 40, zIndex: 500}}
      title={'Tạo kết quả kiểm soát rủi ro'}
      visible={isVisible}
      maskClosable={false}
      // transitionName=""
      // maskTransitionName=""
      onCancel={() => {
        handleCancelModal()
      }}
      footer={null}>
      <Form
        form={form}
        layout={'vertical'}
        onFinish={handleCreateRiskControlResult}
        preserve={false}>
        <h3>V. KẾT QUẢ KIỂM SOÁT RỦI RO</h3>

        <Form.Item
          name={'ket_qua'}
          label={<h3>1. Kết quả</h3>}
          style={{ width: '50%' }}>
          <Select placeholder={"Chọn kết quả"}>
            <Select.Option value="1">
              a/ Rủi ro ở mức không cần xử lý (theo dõi)
            </Select.Option>
            <Select.Option value="2">b/ Rủi ro đã được xử lý</Select.Option>
            <Select.Option value="3">
              c/ Rủi ro đã xảy ra chưa được xử lý
            </Select.Option>
          </Select>
        </Form.Item>
        <h3>2. Các ý kiến liên quan </h3>
        <Form.Item name={'cac_y_kien_lien_quan'} label={'Ý kiến:'}>
          {!isRenderRichText && (
            <div style={{ height: 312, position: 'relative' }}>
              <SpinnerInlineComponent
                sizeSpin={'small'}
                tip="Đang tải dữ liệu..."
                isLoading={!isRenderRichText}
                backGround={'#ffffffc7'}
              />
            </div>
          )}
          {isRenderRichText && (
            <RichEditor
              EDITOR_REF={OPINION_REF}
              placeholder={'Nhập nội dung...'}
              editorContent={dataActions?.tier4[0]?.cac_y_kien_lien_quan || ''}
            />
          )}
          {/* <Input placeholder={'Nhập nội dung'} /> */}
        </Form.Item>
        <Form.Item
          name={'don_vi_thuc_hien_xu_ly_rui_ro'}
          label={'ĐƠN VỊ THỰC HIỆN XỬ LÝ RỦI RO:'}>
            <Input disabled  />
        </Form.Item>

        <SelectPeopleWrapper>
          <SelectUserButton
            style={{ marginTop: 16 }}
            title="BAN PC&QTRR/BAN KT-KTNB VIMC:"
            tooltipTitle="Chọn người"
            handleOnClick={() => {
              handleVisibleSelectOneUserPopup()
              handleOpenSelectUserDepartmentPopup(
                ASSIGNEE_TYPE.ban_pc_qtrr_ban_kt_ktnb_vimc
              )
            }}
          />
          <SelectListComponent
            userList={selectUserList.ban_pc_qtrr_ban_kt_ktnb_vimc}
          />
        </SelectPeopleWrapper>

        <SelectPeopleWrapper>
          <SelectUserButton
            style={{ marginTop: 16 }}
            title="BAN ĐIỀU HÀNH/HĐQT VIMC:"
            tooltipTitle="Chọn người"
            handleOnClick={() => {
              handleVisibleSelectOneUserPopup()
              handleOpenSelectUserDepartmentPopup(
                ASSIGNEE_TYPE.ban_dieu_hanh_hdqt_vimc
              )
            }}
          />
          <SelectListComponent
            userList={selectUserList.ban_dieu_hanh_hdqt_vimc}
          />
        </SelectPeopleWrapper>
        <FormActionFooter style={{ marginBottom: 0 }}>
          <Button icon={<CloseOutlined />} danger onClick={handleCancelModal}>
            Đóng
          </Button>
          <Button
            style={{ marginLeft: 10 }}
            type={'primary'}
            htmlType={'submit'}>
            Chỉnh sửa
          </Button>
        </FormActionFooter>
      </Form>
    </Modal>
  )
}

export default inject(
  'riskStore',
  'actionStore',
  'selectPeopleStore'
)(observer(RiskControlResult))
