import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet/es/Helmet'
// Layouts
import DashboardLayout from '../../layouts/DashboardLayout'
import { Container } from '../../layouts/Container/Container'
// Components
import ContentBlockWrapper from '../../components/ContentBlockWrapper'
import PageTitle from '../../components/PageTitle'
// ant
// Mobx
import { inject, observer } from 'mobx-react'
import MissionEditForm from './MissionEditForm'

const MissionEditPage = props => {
  const {
    loadingAnimationStore,
    history,
    taskStore,
    selectPeopleStore,
    match,
  } = props
  const missionId = match.params.id

  useEffect(() => {
    loadingAnimationStore.showSpinner(true)
    Promise.all([taskStore.getTaskLevelList()]).finally(() =>
      loadingAnimationStore.showSpinner(false)
    )
    return () => {
      selectPeopleStore.clearSelectPeopleStore()
      taskStore.clearCreateMissionStore()
    }
  }, [])

  return (
    <DashboardLayout>
      <Helmet>
        <title>Chỉnh sửa nhiệm vụ | Can Tho Port chỉnh sửa nhiệm vụ</title>
      </Helmet>
      <PageTitle
        showTitle={true}
        location={props.location}
        title={'Chỉnh sửa nhiệm vụ'}
      />
      <ContentBlockWrapper>
        <Container maxWidth={1000}>
          <MissionEditForm
            handleAfterUpdate={() => {
              history.push(`/mission/view/${missionId}`)
            }}
            handleCancelUpdate={() => {
              history.push(`/mission/view/${missionId}`)
            }}
            taskCode={missionId}
          />
        </Container>
      </ContentBlockWrapper>
    </DashboardLayout>
  )
}

export default inject(
  'loadingAnimationStore',
  'taskStore',
  'selectPeopleStore'
)(observer(MissionEditPage))
