import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet/es/Helmet'
// Component
import CanvasDoc from './CanvasDoc'
import { StatusTag } from '../../../components/Common/StatusTag'
import DashboardLayout from '../../../layouts/DashboardLayout/DashboardLayout'
import PageTitle from '../../../components/PageTitle'
// Styled Component
import { HeadingButtonWrapper } from '../../../layouts/InternalDocumentDetailLayout/InternalDocumentDetailLayoutStyled'
// Ant Design
import {
  DeleteOutlined,
  MoreOutlined,
  QuestionCircleOutlined,
} from '@ant-design/icons'
import { Dropdown, Menu, message, Modal } from 'antd'
// Other
import { inject, observer } from 'mobx-react'
import { ACL_ACTION_TYPE, TYPE_STATUS } from '../../../constants'
import { blue } from '../../../color'
import AccessControlAction from '../../../components/AccessControlAction'

const { confirm } = Modal

const InternalDocumentSignDocDetailPage = props => {
  const {
    loadingAnimationStore,
    history,
    fileStore,
    match,
    electronicDocumentStore,
    signedDocumentStore,
    notificationStore,
  } = props
  const { documentId } = match.params

  const { selectedElectronicDocument } = electronicDocumentStore

  useEffect(() => {
    ;(async () => {
      loadingAnimationStore.showSpinner(true)
      try {
        const {
          data,
        } = await electronicDocumentStore.getElectronicDocumentById(documentId)
        await fileStore.downloadAttachment(
          data.action_history.find(el => el.action === data.status).file_id
        )
      } catch (err) {
        history.push('/internal-document/sign-document')
        console.log(err)
        message.error(err.vi || 'Đã có lỗi xảy ra!')
      } finally {
        loadingAnimationStore.showSpinner(false)
      }
    })()
    return () => {
      electronicDocumentStore.clearSelectedElectronicDocument()
      electronicDocumentStore.clearCommentElectronicDocumentList()
      signedDocumentStore.clearDataPopupSign()
      signedDocumentStore.clearSignatureList()
      fileStore.clearDocumentAttachment()
      notificationStore.clearNotificationType()
    }
  }, [documentId])

  const handleDeleteElectronicDocument = async () => {
    try {
      loadingAnimationStore.showSpinner(true)
      await electronicDocumentStore.deleteElectronicDocument(documentId)
      history.push('/internal-document/sign-document')
      message.success('Xóa trình ký thành công!')
    } catch (err) {
      console.log(err)
      message.error(err.vi || 'Đã có lỗi xảy ra')
    } finally {
      loadingAnimationStore.showSpinner(false)
    }
  }
  const showConfirm = () => {
    confirm({
      title: 'Bạn có muốn xóa trình ký này không?',
      icon: <QuestionCircleOutlined style={{ color: 'red' }} />,
      okText: 'Đồng ý',
      cancelText: 'Không',
      okType: 'danger',
      onOk() {
        handleDeleteElectronicDocument()
      },
    })
  }

  const menuHandleDocument = (
    <Menu>
      <Menu.Item danger onClick={showConfirm} icon={<DeleteOutlined />}>
        Xóa trình ký
      </Menu.Item>
    </Menu>
  )
  return (
    <DashboardLayout>
      <Helmet>
        <title>Chi tiết trình ký | Quản lý VB Can Tho Port</title>
      </Helmet>
      <PageTitle
        location={props.location}
        showTitle={true}
        title={selectedElectronicDocument.title}
        status={StatusTag(
          TYPE_STATUS.ELECTRONIC_DOCUMENT,
          selectedElectronicDocument.status
        )}
        routerGoBack={'/internal-document/sign-document'}>
        <HeadingButtonWrapper>
          <AccessControlAction
            aclActionType={ACL_ACTION_TYPE.delete__PHAT_HANH_VB}>
            <Dropdown overlay={menuHandleDocument} trigger={['click']}>
              <span className={'btn-style'}>
                <MoreOutlined style={{ color: blue }} />
              </span>
            </Dropdown>
          </AccessControlAction>
        </HeadingButtonWrapper>
      </PageTitle>
      <CanvasDoc />
    </DashboardLayout>
  )
}

InternalDocumentSignDocDetailPage.propTypes = {}
export default inject(
  'selectUserStore',
  'departmentStore',
  'loadingAnimationStore',
  'fileStore',
  'internalDocumentStore',
  'signedDocumentStore',
  'electronicDocumentStore',
  'notificationStore'
)(observer(InternalDocumentSignDocDetailPage))
