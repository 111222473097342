import {
  BankOutlined,
  CloudUploadOutlined,
  SendOutlined,
  TeamOutlined,
  UploadOutlined,
  UserOutlined,
} from '@ant-design/icons'
import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  message,
  Popconfirm,
  Row,
  Space,
  Tag,
  Tooltip,
  Typography,
  Upload,
} from 'antd'
import { runInAction, toJS } from 'mobx'
import { inject, observer } from 'mobx-react'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet/es/Helmet'
import { useHistory } from 'react-router-dom'
import { blue } from '../../color'
import { AvatarHasName } from '../../components/Common/Avatar'
import ContentBlockWrapper from '../../components/ContentBlockWrapper'
import FileViewPopup from '../../components/FileViewPopup'
import PageTitle from '../../components/PageTitle'
import SelectPeoplePopup from '../../components/SelectPeoplePopup'
import ProposalStepApprove from '../../components/SubmissionForm/ProposalStepApprove'
import { ASSIGNEE_TYPE, PROPOSAL_STATUS } from '../../constants'
import DashboardLayout from '../../layouts/DashboardLayout/DashboardLayout'
import { SelectList } from '../MissionEditPage/MissionEditPageStyled'
import SalaryImportFileModal from '../SalaryPage/SalaryImportFileModal'
import SalaryInfo from '../SalaryPage/SalaryInfo'
import SalaryList from '../SalaryPage/SalaryList'

const { Paragraph, Text } = Typography

const show = {
  flex: '0 0 360px',
  width: 360,
  transition: '0.5s',
  overflow: 'hidden',
}
const hide = {
  flex: '0 0 0',
  width: 0,
  transition: '0.5s',
  overflow: 'hidden',
}
const ProposalSalaryCreate = props => {
  const {
    salaryStore,
    selectPeopleStore,
    authenticationStore,
    proposalStore,
    proposalSalaryStore,
    fileStore,
  } = props

  const { isShowSalaryInfo, salaryInfo } = salaryStore
  const [isLoadingSalary, setIsLoadingSalary] = useState(false)
  const [isVisibleFileViewPopup, setIsVisibleFileViewPopup] = useState(false)
  const currentStep = 0
  
  const [
    isVisibleSalaryImportFileModal,
    setIsVisibleSalaryImportFileModal,
  ] = useState(false)

  const [form] = Form.useForm()

  const [fileList, setFileList] = useState([])
  const [attachmentList, setAttachmentList] = useState([])
  const [followerList, setFollowerList] = useState([])
  const [handlerList, setHandlerList] = useState([])

  const history = useHistory()

  const {
    selectUserData,
    selectDepartmentData,
    selectGroupData,
    isVisibleSelectUserDepartmentPopup,
    setIsVisibleSelectUserDepartmentPopup,
    handleOpenSelectUserDepartmentPopup,
    handleCancelSelectUserDepartment,
  } = selectPeopleStore

  const handleChangeFile = info => {
    setFileList([info.file])
    setIsVisibleSalaryImportFileModal(true)
  }

  const handleChangeAttachments = info => {
    const filteredFileList = info.fileList.filter(
      (elem, index, fileArray) =>
        fileArray.findIndex(file => file.name === elem.name) === index
    )
    setAttachmentList(filteredFileList)
  }

  const [monthCreateSalary, setMonthCreateSalary] = useState(moment())
  const [isCreatingSalary, setIsCreatingSalary] = useState(false)
  const [approvalLevels, setApprovalLevels] = useState([])

  useEffect(() => {
    setHandlerList([
      ...selectUserData[ASSIGNEE_TYPE.HANDLER],
      ...selectDepartmentData[ASSIGNEE_TYPE.HANDLER],
      ...selectGroupData[ASSIGNEE_TYPE.HANDLER],
    ])
    setFollowerList([
      ...selectUserData[ASSIGNEE_TYPE.FOLLOWER],
      ...selectDepartmentData[ASSIGNEE_TYPE.FOLLOWER],
      ...selectGroupData[ASSIGNEE_TYPE.FOLLOWER],
    ])
  }, [selectUserData, selectDepartmentData, selectGroupData])

  useEffect(() => {
    ;(async () => {
      try {
        await proposalStore.getProposalTypeApprovalConfig('PROPOSAL_SALARY')
      } catch (error) {
        message.error('Loại thủ tục này chưa được thiết lập cấp duyệt')
      }
    })()

    return () => {
      runInAction(() => {
        proposalStore.proposalApproveConfig = {
          approvalCode: null,
          approvalLevels: [],
        }
        selectPeopleStore.clearSelected()
      })
    }
  }, [])

  useEffect(() => {
    const approvalLevelList = [
      ...proposalStore.proposalApproveConfig.approvalLevels,
    ]
    if (approvalLevelList.length === 0) {
      setApprovalLevels([])
      return
    }
    approvalLevelList[0].username = authenticationStore.currentUser?.username
    setApprovalLevels(approvalLevelList)
  }, [proposalStore.proposalApproveConfig, authenticationStore.currentUser])

  const handleCreateSalary = async (values) => {
    try {
      setIsCreatingSalary(true)
      const batchUploadList = []
      attachmentList.forEach(file => {
        const formData = new FormData()
        formData.append('file', file.originFileObj)
        batchUploadList.push(fileStore.uploadFile(formData))
      })
      const response = await Promise.all(batchUploadList)
      const payload = {
        salary_month: monthCreateSalary,
        title: values.title,
        from_file_code:
          salaryInfo.from_file_code || salaryInfo.salary_from_file_code,
        file_ids: response.map(response => response.data.file_id),
        followers: followerList.map(el => el.id),
        approval_levels: proposalStore.defaultApprovalConfigUpdate.approvalLevels.map(
          item => ({
            level: item.level,
            name: item.name,
            username: item.username,
            allow_edit: item.allow_edit,
            status:
              item.level === currentStep + 1
                ? PROPOSAL_STATUS.PENDING
                : item.status,
          })
        ),
      }

      await proposalSalaryStore.createSalary(payload)
      message.success('Tạo trình duyệt lương thành công!')
      history.push('/proposal-salary-request')
    } catch (err) {
      console.log(err)
      message.error(err.vi || 'Đã có lỗi xảy ra!')
    } finally {
      setIsCreatingSalary(false)
    }
  }

  return (
    <DashboardLayout>
      <Helmet>
        <title>Bảng lương | Can Tho Port bảng lương</title>
      </Helmet>
      <PageTitle
        title={'Tạo trình duyệt lương'}
        showTitle={true}
        location={props.location}
      />

      <ContentBlockWrapper style={{ marginBottom: 20 }}>
        <div>
          <div className={'btn-group-left'}>
            <Form form={form} layout={'vertical'} onFinish={handleCreateSalary}> 
              <Row type={'flex'} gutter={15}>
                <Col span={24} style={{ textAlign: 'right' }}>
                  {(salaryInfo.from_file_code ||
                    salaryInfo.salary_from_file_code)  && (
                      <Popconfirm
                        placement="top"
                        title={'Bạn có muồn trình duyệt bảng lương này?'}
                        onConfirm={() => form.submit()}
                        okText="Trình duyệt"
                        cancelText="Không">
                        <Button
                          htmlType={'submit'}
                          loading={isCreatingSalary}
                          type={'primary'}
                          style={{ textTransform: 'uppercase', marginTop: 22 }}
                          icon={<SendOutlined />}>
                          Trình duyệt
                        </Button>
                      </Popconfirm>
                    )}
                </Col>

                <Col span={12}>
                  <Form.Item name={'title'} label={'Tiêu đề:'}>
                    <Input placeholder={'Tiêu đề trình duyệt lương'} />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item
                    label={'Đây là bảng lương của tháng:'}>
                    <Space align="end" direction="horizontal">
                      <div>
                        <DatePicker
                          style={{ width: 173.7 }}
                          allowClear={false}
                          value={monthCreateSalary}
                          placeholder={'Chọn tháng'}
                          onChange={date => setMonthCreateSalary(moment(date))}
                          format={'MM-YYYY'}
                          picker="month"
                        />
                      </div>

                      <Upload
                        accept="application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                        style={{ marginLeft: 4, marginRight: 4 }}
                        valuePropName={'fileList'}
                        fileList={fileList}
                        onChange={handleChangeFile}
                        beforeUpload={() => false}
                        showUploadList={false}>
                        <Button icon={<CloudUploadOutlined />} type={'primary'}>
                          Nhập lương từ bảng tính excel
                        </Button>
                      </Upload>
                    </Space>
                  </Form.Item>
                </Col>

                <Col span={24}>
                  <label
                    style={{
                      display: 'inline-block',
                      margin: '15px 8px 25px 0',
                    }}>
                    Người theo dõi:
                  </label>

                  <Space>
                    <Tooltip title="Chọn người dùng" color={blue}>
                      <Button
                        icon={<UserOutlined style={{ color: blue }} />}
                        onClick={() =>
                          handleOpenSelectUserDepartmentPopup(
                            ASSIGNEE_TYPE.FOLLOWER
                          )
                        }
                      />
                    </Tooltip>
                  </Space>

                  <SelectList>
                    {followerList.map(el => (
                      <Tag
                        className={'tag-selected'}
                        color={'blue'}
                        key={el.id}
                        closable
                        onClose={() =>
                          selectPeopleStore.handleRemoveSelect(el)
                        }>
                        <AvatarHasName
                          imgId={el.image_id}
                          size={22}
                          name={el.full_name}
                          icon={
                            (el.assignee_type === ASSIGNEE_TYPE.DEPARTMENT && (
                              <BankOutlined />
                            )) ||
                            (el.assignee_type === ASSIGNEE_TYPE.GROUP && (
                              <TeamOutlined />
                            ))
                          }
                        />
                      </Tag>
                    ))}
                  </SelectList>
                </Col>
              </Row>
            </Form>
          </div>

          {/*tài liệu đính kèm*/}
          <div>
            <div className={'label-date-picker'}>Tài liệu đính kèm:</div>
            <Upload
              valuePropName={'fileList'}
              fileList={attachmentList}
              multiple={true}
              beforeUpload={() => false}
              onChange={handleChangeAttachments}>
              <Button icon={<UploadOutlined />}>Chọn tài liệu</Button>
            </Upload>
          </div>
          <SalaryImportFileModal
            fileList={fileList}
            handleChangeFile={handleChangeFile}
            isVisibleSalaryImportFileModal={isVisibleSalaryImportFileModal}
            handleCloseSalaryImportFileModal={() =>
              setIsVisibleSalaryImportFileModal(false)
            }
          />

          <ProposalStepApprove approvalLevelList={approvalLevels} />
        </div>

        <SelectPeoplePopup
          disableSelectDepartment
          isOpenCollapse={true}
          handleCancelSelectUser={handleCancelSelectUserDepartment}
          handleSubmitSelectUser={() =>
            setIsVisibleSelectUserDepartmentPopup(false)
          }
          isVisibleSelectPeoplePopup={isVisibleSelectUserDepartmentPopup}
        />
      </ContentBlockWrapper>

      <ContentBlockWrapper style={{ minHeight: 'calc(100vh - 500px)' }}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            marginBottom: 4,
            alignItems: 'center',
          }}>
          <Paragraph
            ellipsis={{
              rows: 1,
            }}
            strong
            style={{
              marginBottom: 0,
              fontSize: 16,
              opacity: salaryInfo.file_name ? 1 : 0,
            }}>
            <span>Chi tiết bảng lương từ file</span>
            <Tooltip title={'Xem file'}>
              <span
                onClick={() => setIsVisibleFileViewPopup(true)}
                style={{
                  marginLeft: 6,
                  color: blue,
                  textDecoration: 'underline',
                  cursor: 'pointer',
                }}>
                {salaryInfo.file_name}
              </span>
            </Tooltip>
          </Paragraph>
        </div>

        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%',
          }}>
          <SalaryList
            isLoadingSalary={isLoadingSalary}
            isShowSalaryInfo={isShowSalaryInfo}
          />
          <div style={isShowSalaryInfo ? show : hide}>
            <SalaryInfo />
          </div>
        </div>
      </ContentBlockWrapper>

      <FileViewPopup
        file={{
          file_name: salaryInfo.file_name,
          file_id: salaryInfo.file_id,
        }}
        isVisibleFileViewPopup={isVisibleFileViewPopup}
        handleCloseFileViewPopup={() => setIsVisibleFileViewPopup(false)}
      />
    </DashboardLayout>
  )
}

ProposalSalaryCreate.propTypes = {}

export default inject(
  'salaryStore',
  'selectPeopleStore',
  'proposalSalaryStore',
  'proposalStore',
  'fileStore',
  'authenticationStore'
)(observer(ProposalSalaryCreate))
