import {
  Button,
  Divider,
  Empty,
  Input,
  message,
  Pagination,
  Popconfirm,
  Row,
  Select,
  Tooltip,
} from 'antd'
import { inject, observer } from 'mobx-react'
import React, { useEffect, useState } from 'react'
import TableComponent from '../../../components/Common/TableComponent'
import {
  DeleteOutlined,
  EditOutlined,
  PlusCircleOutlined,
  QuestionCircleOutlined,
} from '@ant-design/icons'
import { SearchBar } from '../../UserAccountManagementPage/UserAcountManagementPageStyled'
import RiskTemplateCreateModal from './RiskTemplateCreateModal'
import { useHistory, useParams } from 'react-router-dom'
import queryString from 'query-string'

const { Search } = Input
const { Option } = Select

const ApprovalTemplateList = props => {
  const queryStringParse = queryString.parse(location.search, {
    arrayFormat: 'index',
  })
  const history = useHistory()
  const params = useParams()
  const { authenticationStore, riskTemplateStore } = props
  const { riskTemplateTypes, riskTemplateList } = riskTemplateStore

  const [editRisk, setEditRisk] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const [filter, setFilter] = useState({ type: null, keyword: null })
  const [isRefresh, setIsRefresh] = useState(true)
  const [showCreateModal, setShowCreateModal] = useState(false)
  useEffect(() => {
    ;(async () => {
      await riskTemplateStore.getRiskTemplateTypes()
    })()
  }, [])
  useEffect(() => {
    ;(async () => {
      if (riskTemplateTypes) {
        setFilter({
          keyword: queryStringParse.keyword || undefined,
          type: queryStringParse.type || riskTemplateTypes[0]?.code,
        })
        await loadRiskTemplateList({
          keyword: queryStringParse.keyword || '',
          type: queryStringParse.type || riskTemplateTypes[0]?.code,
        })
        setIsRefresh(false)
      }
    })()
  }, [JSON.stringify(riskTemplateTypes)])
  useEffect(() => {
    if (isRefresh) return
    const queryStringStringify = queryString.stringify(filter, {
      skipNull: true,
    })
    history.replace(
      `/administrative-management/risk-template?${queryStringStringify}`
    )
    ;(async () => {
      await loadRiskTemplateList(filter)
    })()
  }, [filter])

  const loadRiskTemplateList = async params => {
    try {
      setIsLoading(true)
      await riskTemplateStore.getRiskTemplateList(params || filter)
    } finally {
      setIsLoading(false)
    }
  }

  const handleChangeType = type => {
    setFilter({ ...filter, type: type })
  }

  const handleCancelCreateModal = () => {
    setShowCreateModal(false)
  }
  const handleCancelEditModal = () => {
    setEditRisk(null)
    setShowCreateModal(false)
  }
  const handleShowCreateModal = () => {
    setShowCreateModal(true)
  }
  const handleDeleteRisk = async code => {
    try {
      setIsLoading(true)
      await riskTemplateStore.delete(code)
      await loadRiskTemplateList(filter)
      message.success('Đã xóa!')
    } catch (err) {
      console.log(err.vi || 'Đã có lỗi xảy ra!')
    } finally {
      setIsLoading(false)
    }
  }
  const handleClickEditRisk = record => {
    setEditRisk(record)
    setShowCreateModal(true)
  }

  const handleSearch = value => {
    setFilter({ ...filter, keyword: value })
  }

  const tableColumns = [
    {
      title: 'Tiêu đề',
      render: record => <span>{record.rui_ro_nhan_dien}</span>,
      width: '70%',
    },
    {
      title: 'Tác vụ',
      align: 'right',
      width: '30%',
      render: record => (
        <>
          <Tooltip title={'Sửa thông tin'}>
            <EditOutlined
              className={'anticon-color'}
              onClick={() => handleClickEditRisk(record)}
            />
          </Tooltip>
          <Divider type={'vertical'} />
          <Tooltip title={'Xoá'}>
            <Popconfirm
              onConfirm={() => handleDeleteRisk(record.id)}
              okText={'Đồng ý'}
              cancelText={'Không'}
              okType={'danger'}
              icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
              title={'Bạn có muốn xoá rủi ro nhận diện này?'}>
              <DeleteOutlined style={{ color: '#fc0000' }} />
            </Popconfirm>
          </Tooltip>
        </>
      ),
    },
  ]
  return (
    <>
      <Row style={{ justifyContent: 'space-between' }}>
        <div
          style={{
            display: 'flex',
            gap: 10,
            marginBottom: 14,
          }}>
          <SearchBar>
            <Search
              allowClear
              onSearch={handleSearch}
              placeholder={'Tìm kiếm rủi ro nhận diện...'}
              defaultValue={queryStringParse?.keyword}
            />
          </SearchBar>
          <Select
            style={{ minWidth: '250px' }}
            name={'proposal_status'}
            placeholder={'-- Lọc theo loại --'}
            allowClear
            value={
              filter?.type || (riskTemplateTypes && riskTemplateTypes[0]?.code)
            }
            onChange={value => handleChangeType(value)}>
            {riskTemplateTypes?.length > 0 &&
              riskTemplateTypes.map(type => (
                <Option key={type.code} value={type.code}>
                  {type.name}
                </Option>
              ))}
          </Select>
        </div>
        <Button type={'primary'} onClick={handleShowCreateModal}>
          <PlusCircleOutlined />
          Tạo mới
        </Button>
      </Row>
      <TableComponent
        rowKey={record => record.code}
        dataSource={riskTemplateList}
        columns={tableColumns}
        pagination={false}
        locale={{
          emptyText: (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description={'Không có dữ liệu'}
            />
          ),
        }}
        loading={isLoading}
      />
      <RiskTemplateCreateModal
        showCreateModal={showCreateModal}
        handleCancelCreateModal={handleCancelCreateModal}
        handleCancelEditModal={handleCancelEditModal}
        type={filter?.type}
        riskTemplateStore={riskTemplateStore}
        loadRiskTemplateList={loadRiskTemplateList}
        risk={editRisk}
        setEditRisk={setEditRisk}
      />
    </>
  )
}

export default inject('riskTemplateStore')(observer(ApprovalTemplateList))
