import React, { useEffect, useState } from 'react'
import { Button, Form, message, Modal, Select } from 'antd'
import EmptyContent from '../../../components/EmptyContent'
import { inject, observer } from 'mobx-react'
import configDefaultGroupStore from '../../../stores/configDefaultGroupStore'
import { LoadingOutlined } from '@ant-design/icons'

const { Option } = Select

const ConfigDefaultGroupModal = props => {
  const { isNhomNhiemVu, isVisibleModal, handleCancel, userGroupStore } = props

  const { dataGroupStoreList, groupActive } = userGroupStore
  const { isLoading, preSelectedGroupId } = configDefaultGroupStore
  const [isProcessing, setIsProcessing] = useState(false)

  const [form] = Form.useForm()
  // const [groupId, setGroupId] = useState()

  useEffect(() => {
    if (isVisibleModal) {
      load()
    }

    return () => {
      configDefaultGroupStore.clear()
    }
  }, [isVisibleModal])

  const load = async () => {
    try {
      await configDefaultGroupStore.getDefaultGroupByKey(key())
    } catch (e) {
      console.log('Lỗi', e.errorCode)
      if (e?.errorCode === 'NOT_FOUND' || e.errorCode === undefined) {
        message.warning('Chưa cấu hình chỉ định cho nhóm')
      } else {
        message.error('Đã có lỗi xảy ra!')
      }
    } finally {
      configDefaultGroupStore.isLoading = false
    }
  }

  useEffect(() => {
    onGroupChange(preSelectedGroupId)
  }, [preSelectedGroupId])

  const onGroupChange = groupId => {
    console.log('onGroupChange', groupId)
    form.setFieldsValue({
      group_id: groupId,
    })
  }

  const save = async () => {
    try {
      setIsProcessing(true)
      if (
        form.getFieldValue('group_id') === null ||
        form.getFieldValue('group_id') === undefined
      ) {
        message.warning('Vui lòng chọn nhóm người dùng')
      } else {
        await configDefaultGroupStore.setDefaultGroupByKey(
          key(),
          form.getFieldValue('group_id')
        )
        message.success('Cấu hình thành công')
        onCancel()
      }
    } catch (e) {
      message.error('Đã có lỗi xảy ra!')
    } finally {
      setIsProcessing(false)
    }
  }

  const onCancel = () => {
    form.resetFields()
    handleCancel()
  }

  const title = () =>
    `Thiết lập chỉ định nhóm ${isNhomNhiemVu ? `Thư ký` : `Văn thư`}`

  const key = () =>
    isNhomNhiemVu ? 'default_group_thu_ky_id' : 'default_group_van_thu_id'

  return (
    <Modal
      title={title()}
      footer={null}
      closable={true}
      visible={isVisibleModal}
      onCancel={onCancel}>
      {isLoading ? (
        <LoadingOutlined spin={true} />
      ) : (
        <Form form={form} layout={'vertical'}>
          <Form.Item label={'Chọn nhóm người dùng'} name={'group_id'}>
            <Select
              notFoundContent={<EmptyContent />}
              onChange={onGroupChange}
              placeholder={'-- Chọn nhóm --'}>
              {dataGroupStoreList.map(group => (
                <Option key={group.id_group} value={group.id_group}>
                  {group.name_group}
                </Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item>
            <Button
              style={{ float: 'right' }}
              loading={isProcessing}
              onClick={save}
              type={'primary'}>
              Cập nhật
            </Button>
          </Form.Item>
        </Form>
      )}
    </Modal>
  )
}

ConfigDefaultGroupModal.propTypes = {}

export default inject(
  'userGroupStore',
  'authenticationStore'
)(observer(ConfigDefaultGroupModal))
