import { CloseOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons'
import {
  Button,
  Col,
  Form,
  Input,
  message,
  Modal,
  Row,
  Space,
  Spin,
} from 'antd'
import { runInAction } from 'mobx'
import { inject, observer } from 'mobx-react'
import React, { useEffect, useState } from 'react'
import { ButtonFooter } from '../../WorkPage/PopupWorkForm/PopupWorkFormStyled'
import ApprovalLevelCreate from './ApprovalLevelCreate'
import ApprovalTemplateSortableLevels from './ApprovalTemplateSortableLevels'
import { AddButton, ApprovalLevel } from './ApproveTemplateStyled'

export const getInfoUser = (username, departmentWithUserList) => {
  const user = {
    full_name: username,
    image_id: null,
    username: username,
  }
  departmentWithUserList.forEach(department => {
    const userTarget = department.users.find(
      user => user.user_name === username
    )

    if (userTarget) {
      user.full_name = userTarget.name_uppercase || username
      user.image_id = userTarget.image_id
    }
  })
  return user
}

const ApprovalTemplateUpdate = props => {
  const [form] = Form.useForm()

  const [isVisibleLevelCreateModal, setIsVisibleLevelCreateModal] = useState(
    false
  )
  const [checkAddMore, setCheckAddMore] = useState(true)
  const {
    approvalTemplateStore,
    selectPeopleStore,
    authenticationStore,
  } = props

  const { originDepartmentWithUserList, companyCode } = selectPeopleStore
  const { approvalTemplateDetail } = approvalTemplateStore

  const [isUpdating, setIsUpdating] = useState(false)

  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    getApprovalTemplateDetail(props.templateId)

    return () => approvalTemplateStore.clearApprovalTemplateDetail()
  }, [props.templateId])

  useEffect(() => {
    form.setFieldsValue({
      name: approvalTemplateDetail.name,
      description: approvalTemplateDetail.description,
    })

    runInAction(() => {
      approvalTemplateStore.approvalTemplateSortableLevels = approvalTemplateDetail.approval_levels
        .slice(1)
        .map(tempLevel => {
          const handler = getInfoUser(
            tempLevel.username,
            originDepartmentWithUserList
          )
          return {
            level: tempLevel.level,
            code: tempLevel.code,
            name: tempLevel.name,
            allow_edit: tempLevel.allow_edit,
            is_deleted: false,
            handler: {
              full_name: handler.full_name,
              username: handler.username,
            },
          }
        })
    })

    return () => approvalTemplateStore.clearApprovalTemplateSortableLevels()
  }, [approvalTemplateDetail])
  const [countLevelCreateModal, setCountLevelCreateModal] = useState(0)
  useEffect(() => {
    setCountLevelCreateModal(
      approvalTemplateStore.approvalTemplateSortableLevels.length
    )
  }, [approvalTemplateDetail])
  useEffect(() => {
    countLevelCreateModal >= 5 ? setCheckAddMore(false) : setCheckAddMore(true)
  }, [countLevelCreateModal])

  const getApprovalTemplateDetail = async id => {
    try {
      setIsLoading(true)
      await approvalTemplateStore.getApprovalTemplateDetail(id)
    } catch (error) {
      console.log(error)
    } finally {
      setIsLoading(false)
    }
  }
  const handleToggleLevelCreateModal = bool => event => {
    setIsVisibleLevelCreateModal(bool)
    if (!bool) {
      selectPeopleStore.clearSelected()
    }
  }

  const onFinish = async value => {
    try {
      setIsUpdating(true)

      let level = 0

      if (
        approvalTemplateStore.approvalTemplateSortableLevels.filter(
          level => !level.is_deleted
        ).length === 0
      ) {
        message.error('Số cấp duyệt phải từ 2 cấp trở lên')
        return
      }

      const approvalTemplate = {
        name: value.name.trim().replace(/\s+/g, ' '),
        description: value.description.trim(),
        approval_levels: [
          approvalTemplateStore.approvalTemplateDetail.approval_levels[0],
          ...approvalTemplateStore.approvalTemplateSortableLevels.map(
            approvalLevel => {
              if (!approvalLevel.is_deleted) level += 1

              return {
                code: approvalLevel.is_temp ? null : approvalLevel.code,
                level: approvalLevel.is_deleted ? approvalLevel.level : level,
                name: approvalLevel.name,
                allow_edit: approvalLevel.allow_edit,
                username: approvalLevel.handler?.username,
                is_deleted: approvalLevel.is_deleted,
              }
            }
          ),
        ],
      }

      await approvalTemplateStore.updateApprovalTemplate(
        props.templateId,
        approvalTemplate
      )
      message.success('Chỉnh sửa mẫu duyệt thành công')
      props.onCancel()
      await approvalTemplateStore.getApprovalTemplateList()
    } catch (error) {
      console.log(error)
      message.error('Lỗi thêm cấp duyệt')
      props.onCancel()
    } finally {
      setIsUpdating(false)
    }
  }

  return (
    <>
      <Form onFinish={onFinish} form={form} layout="vertical">
        <Spin spinning={isLoading}>
          <Row gutter={[16, 16]}>
            <Col span={8}>
              <Form.Item
                label={'Tên:'}
                name={'name'}
                rules={[
                  { required: true, message: 'Vui lòng nhập tên mẫu duyệt' },
                  {
                    max: 500,
                    message: 'Tên mẫu duyệt không được dài quá 500 ký tự!',
                  },
                ]}>
                <Input placeholder={'Nhập tên cấp duyệt...'} />
              </Form.Item>

              <Form.Item
                name={'description'}
                label={'Mô tả:'}
                rules={[
                  { required: true, message: 'Vui lòng nhập mô tả mẫu duyệt!' },
                ]}>
                <Input.TextArea rows={4} placeholder={'Nhập mô tả cấp duyệt'} />
              </Form.Item>
            </Col>

            <Col span={16}>
              <p style={{ fontWeight: 500, marginBottom: 9 }}>Cấp độ duyệt</p>
              <ApprovalLevel>
                <Row style={{ justifyContent: 'flex-end', marginBottom: 0 }}>
                  <Col span={22} className="approval-level__content">
                    <dt>Tên</dt>
                    <dd>Bộ phận đề nghị</dd>
                    <dt>Người xử lý</dt>
                    <dd>Mặc định</dd>
                  </Col>
                </Row>
              </ApprovalLevel>
              <ApprovalTemplateSortableLevels
                countLevelCreateModal={countLevelCreateModal}
                setCountLevelCreateModal={setCountLevelCreateModal}
              />
              {checkAddMore ? (
                <AddButton onClick={handleToggleLevelCreateModal(true)}>
                  <PlusOutlined size={18} />
                  Thêm cấp duyệt
                </AddButton>
              ) : (
                <div style={{ color: 'red', textAlign: 'right' }}>
                  * Đã đạt mức tối đa 5 cấp duyệt
                </div>
              )}
            </Col>
          </Row>
        </Spin>
        <ButtonFooter>
          <Space>
            <Button icon={<CloseOutlined />} danger onClick={props.onCancel}>
              Đóng
            </Button>
            <Button
              icon={<EditOutlined />}
              type={'primary'}
              htmlType={'submit'}
              loading={isUpdating}>
              Lưu
            </Button>
          </Space>
        </ButtonFooter>
      </Form>

      <Modal
        width={800}
        title={'Thêm cấp duyệt'}
        visible={isVisibleLevelCreateModal}
        maskClosable={false}
        onCancel={handleToggleLevelCreateModal(false)}
        footer={null}
        destroyOnClose>
        {isVisibleLevelCreateModal && (
          <ApprovalLevelCreate
            onCancel={handleToggleLevelCreateModal(false)}
            countLevelCreateModal={countLevelCreateModal}
            setCountLevelCreateModal={setCountLevelCreateModal}
          />
        )}
      </Modal>
    </>
  )
}
export default inject(
  'approvalTemplateStore',
  'selectPeopleStore',
  'authenticationStore'
)(observer(ApprovalTemplateUpdate))
