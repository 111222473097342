import { ExclamationCircleOutlined } from '@ant-design/icons'
import { Button, Form, Input, message, Modal, Row, Space } from 'antd'
import React from 'react'
import { useRef } from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import RichEditor from '../../../components/RichEditor/RichEditor'
import SpinnerInlineComponent from '../../../components/SpinnerInlineComponent'
import commonStore from '../../../stores/commonStore'

const RiskTemplateCreateModal = ({
  showCreateModal,
  handleCancelCreateModal,
  type,
  riskTemplateStore,
  loadRiskTemplateList,
  handleCancelEditModal,
  risk,
  setEditRisk
}) => {
  const { createRiskTemplate, updateRiskTemplate } = riskTemplateStore
  const [form] = Form.useForm()
  const CAUSE_REF = useRef()
  const MEASURE_REF = useRef()
  const [isRenderRichText, setIsRenderRichText] = useState(false)
  useEffect(() => {
    if (!risk) {
      return
    }
    form.setFieldsValue({
      rui_ro_nhan_dien: risk.rui_ro_nhan_dien,
    })
  }, [risk])
  useEffect(() => {
    if (showCreateModal) {
      setTimeout(() => {
        setIsRenderRichText(true)
      }, 400)
    } else {
      setIsRenderRichText(false)
    }
  }, [showCreateModal])
  const handleCreateRisk = async value => {
    const submitData = {
      rui_ro_nhan_dien: value.rui_ro_nhan_dien,
      nguyen_nhan: CAUSE_REF.current.editor.getData(),
      bien_phap_phong_ngua_giam_thieu: MEASURE_REF.current.editor.getData(),
      type: type,
    }
    try {
      risk
        ? await updateRiskTemplate(risk.id, submitData)
        : await createRiskTemplate(submitData)
      await loadRiskTemplateList()
      risk
        ? message.success('Cập nhật thành công')
        : message.success('Lưu thành công')
      handleCancelCreateModal()
      setEditRisk(null)
    } catch {
      message.error('Đã có lỗi xảy ra')
    }
  }
  return (
    <Modal
      visible={showCreateModal}
      footer={null}
      destroyOnClose={true}
      afterClose={() => {
        setIsRenderRichText(false)
      }}
      width={600}
      onCancel={risk ? handleCancelEditModal : handleCancelCreateModal}
      title={
        <>
          <ExclamationCircleOutlined
            style={{
              color: commonStore.appTheme.solidColor,
              marginRight: 10,
            }}
          />
          {risk ? 'Cập nhật rủi ro nhận diện' : 'Tạo mới rủi ro nhận diện'}
        </>
      }>
      <Form
        layout={'vertical'}
        onFinish={handleCreateRisk}
        preserve={false}
        form={form}>
        <Form.Item
          label={'Rủi ro nhận diện'}
          name={'rui_ro_nhan_dien'}
          rules={[{ required: true, message: 'Vui lòng nhập !' }]}>
          <Input placeholder={'Nhập rủi ro nhận diện'} />
        </Form.Item>
        <Form.Item label={'Nguyên nhân'} name={'nguyen_nhan'}>
          {!isRenderRichText && (
            <div style={{ height: 312, position: 'relative' }}>
              <SpinnerInlineComponent
                sizeSpin={'small'}
                tip="Đang tải dữ liệu..."
                isLoading={!isRenderRichText}
                backGround={'#ffffffc7'}
              />
            </div>
          )}
          {isRenderRichText && (
            <RichEditor
              EDITOR_REF={CAUSE_REF}
              placeholder={'Nhập nội dung...'}
              editorContent={risk?.nguyen_nhan}
            />
          )}
        </Form.Item>
        <Form.Item
          label={'Biện pháp phòng ngừa giảm thiểu'}
          name={'bien_phap_phong_ngua_giam_thieu'}>
          {!isRenderRichText && (
            <div style={{ height: 312, position: 'relative' }}>
              <SpinnerInlineComponent
                sizeSpin={'small'}
                tip="Đang tải dữ liệu..."
                isLoading={!isRenderRichText}
                backGround={'#ffffffc7'}
              />
            </div>
          )}
          {isRenderRichText && (
            <RichEditor
              EDITOR_REF={MEASURE_REF}
              placeholder={'Nhập nội dung...'}
              editorContent={risk?.bien_phap_phong_ngua_giam_thieu}
            />
          )}
        </Form.Item>
        <Row justify={'end'}>
          <Space>
            <Button
              onClick={risk ? handleCancelEditModal : handleCancelCreateModal}>
              Hủy
            </Button>
            <Button type={'primary'} htmlType={'submit'}>
              {risk ? 'Cập nhật' : 'Tạo mới'}
            </Button>
          </Space>
        </Row>
      </Form>
    </Modal>
  )
}

export default RiskTemplateCreateModal
