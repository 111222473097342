import React from 'react'
import PropTypes from 'prop-types'
import DashboardLayout from '../DashboardLayout'
import ContentBlockWrapper from '../../components/ContentBlockWrapper'
import { Menu } from 'antd'
import { Link } from 'react-router-dom'
import { inject, observer } from 'mobx-react'
import { ACL_ACTION_TYPE } from '../../constants'

const DocumentsLayout = props => {
  const {
    children,
    title,
    selectedKey,
    linkToIncomingDocument,
    linkToOutgoingDocument,
    linkToSignDocument,
    linkToConfigDocument,
    linkToConnectedCommitteeOrg,
    aclStore,
  } = props
  // Không xóa dòng khai báo aclActionsByUser (dù k dùng)
  const { aclActionsByUser } = aclStore
  const pathName = window.location.pathname

  const isAccessTabVBDen =
    (aclStore.checkAccessControlAction(
      ACL_ACTION_TYPE.tab_vb_den__INTERNAL_DOCUMENT
    ) &&
      pathName.includes('internal-document')) ||
    (aclStore.checkAccessControlAction(
      ACL_ACTION_TYPE.tab_vb_den__LIEN_THONG_VB
    ) &&
      pathName.includes('connected-document')) ||
    (aclStore.checkAccessControlAction(ACL_ACTION_TYPE.tab_vb_den__UBQLV) &&
      pathName.includes('connected-committee-document'))

  const isAccessTabVBDi =
    (aclStore.checkAccessControlAction(
      ACL_ACTION_TYPE.tab_vb_di__INTERNAL_DOCUMENT
    ) &&
      pathName.includes('internal-document')) ||
    (aclStore.checkAccessControlAction(
      ACL_ACTION_TYPE.tab_vb_di__LIEN_THONG_VB
    ) &&
      pathName.includes('connected-document')) ||
    (aclStore.checkAccessControlAction(ACL_ACTION_TYPE.tab_vb_di__UBQLV) &&
      pathName.includes('connected-committee-document'))

  const isAccessTabPhatHanhVB =
    pathName.includes('internal-document') &&
    aclStore.checkAccessControlAction(
      ACL_ACTION_TYPE.tab_phat_hanh_vb__INTERNAL_DOCUMENT
    )

  const isAccessTabCauHinhVB =
    pathName.includes('internal-document') &&
    aclStore.checkAccessControlAction(
      ACL_ACTION_TYPE.tab_cau_hinh_vb__INTERNAL_DOCUMENT
    )

  const isAccessTabConnectedCommitteeOrg =
    pathName.includes('connected-committee-document') &&
    aclStore.checkAccessControlAction(ACL_ACTION_TYPE.tab_cty__UBQLV)

  return (
    <DashboardLayout>
      {title}
      <ContentBlockWrapper>
        <Menu
          mode="horizontal"
          selectedKeys={[selectedKey]}
          style={{ marginBottom: 16 }}>
          {isAccessTabVBDen && (
            <Menu.Item key="incoming-document">
              <Link to={linkToIncomingDocument}>Văn bản đến</Link>
            </Menu.Item>
          )}
          {isAccessTabVBDi && (
            <Menu.Item key="outgoing-document">
              <Link to={linkToOutgoingDocument}>Văn bản đi</Link>
            </Menu.Item>
          )}
          {/* {isAccessTabPhatHanhVB && (
            <Menu.Item key="sign-document">
              <Link to={linkToSignDocument}>Phát hành văn bản điện tử</Link>
            </Menu.Item>
          )} */}
          {/* {isAccessTabCauHinhVB && (
            <Menu.Item key="config-document">
              <Link to={linkToConfigDocument}>Cấu hình văn bản</Link>
            </Menu.Item>
          )} */}
          {/* {isAccessTabConnectedCommitteeOrg && (
            <Menu.Item key="connected-committee-organization">
              <Link to={linkToConnectedCommitteeOrg}>Công ty nhận</Link>
            </Menu.Item>
          )} */}
        </Menu>
        {children}
      </ContentBlockWrapper>
    </DashboardLayout>
  )
}

DocumentsLayout.propTypes = {
  children: PropTypes.node,
  title: PropTypes.node,
  selectedKey: PropTypes.string,
}

export default inject('aclStore')(observer(DocumentsLayout))
