import { BankOutlined, TeamOutlined, UserOutlined } from '@ant-design/icons'
import { Button, Form, message, Select, Space, Tag, Tooltip } from 'antd'
import { runInAction } from 'mobx'
import { inject, observer } from 'mobx-react'
import React, { useEffect } from 'react'
import { useState } from 'react'
import { Helmet } from 'react-helmet'
import { useHistory } from 'react-router-dom'
import { blue } from '../../../color'
import { AvatarHasName } from '../../../components/Common/Avatar'
import SelectPeoplePopup from '../../../components/SelectPeoplePopup'
import {
  ASSIGNEE_TYPE,
  DOCUMENT_ACCESS_TYPE,
  DOCUMENT_TYPE,
  MODULE_CODE,
} from '../../../constants'
import { SelectList } from '../../MissionCreatePage/MissionCreatePageStyled'
import { FormButtonGroup } from '../../MissionDetailPage/MissionDetailPageStyled'
import InternalDocumentLayout from './InternalDocumentLayout'

const DocumentAccessibility = props => {
  const { selectPeopleStore, documentAccessibilityStore, authenticationStore, loadingAnimationStore } = props
  const {
    isVisibleSelectUserDepartmentPopup,
    setIsVisibleSelectUserDepartmentPopup,
    handleOpenSelectUserDepartmentPopup,
    handleCancelSelectUserDepartment,
    selectUserData,
    selectDepartmentData,
    selectGroupData,
  } = selectPeopleStore

  const [form] = Form.useForm()

  const formStyle = {
    paddingLeft: '16px',
  }

  const formItemStyle = {
    width: '300px',
  }

  const labelStyle = {
    display: 'inline-block',
    margin: '15px 8px 25px 0',
  }

  const [followerList, setFollowerList] = useState([])
  const [type, setType] = useState()
  const [accessType, setAccessType] = useState()

  const history = useHistory()

  useEffect(() => {
    if(props.moduleStore.moduleList.length === 0) return 

    if (!props.moduleStore.checkAccessModule(MODULE_CODE.van_ban_noi_bo)) {
      message.error('Không được quyền truy cập vào trang này')
      history.push('/dashboard')
    }
  }, [props.moduleStore.moduleList])

  useEffect(() => {
    return () => {
      selectPeopleStore.clearSelected()
      runInAction(() => {
        documentAccessibilityStore.documentAccessibilities = []
      })
    }
  }, [])
  useEffect(() => {
    ;(async () => {
      if (!type || !accessType) return
      try {
        selectPeopleStore.clearSelected()
        loadingAnimationStore.showSpinner(true)
        await documentAccessibilityStore.getDocumentAccessibilities(type, accessType)
      } catch (error) {
        message.error(error.vi)
        console.log(error)
      } finally {
        loadingAnimationStore.showSpinner(false)
      }
    })()
  }, [type, accessType])

  useEffect(() => {
    documentAccessibilityStore.documentAccessibilities
      .map(accessibility => accessibility.assignee)
      .forEach(follower => {
        if (follower?.user_name) {
          followerList.push({
            assignee_type: ASSIGNEE_TYPE.USER,
            full_name: follower.name_uppercase,
            id: follower.user_name,
            image_id: null,
            permission: ASSIGNEE_TYPE.USER,
          })
        }
      })

    if (followerList.length > 0) {
      selectPeopleStore.setSelectUserData({
        ...selectUserData,
        USER: followerList,
      })
    }
  }, [documentAccessibilityStore.documentAccessibilities])

  useEffect(() => {
    setFollowerList([
      ...selectUserData[ASSIGNEE_TYPE.USER],
      ...selectDepartmentData[ASSIGNEE_TYPE.USER],
      ...selectGroupData[ASSIGNEE_TYPE.USER],
    ])
  }, [selectUserData, selectDepartmentData, selectGroupData])

  const onChangeType = value => {
    setType(value)
  }

  const onChangeAccessType = value => {
    setAccessType(value)
  }

  const onFinish = async values => {
    try {
      await documentAccessibilityStore.updateDocumentAccessibilities({
        type: values.type,
        access_type: values.accessType,
        username_list: followerList.map(follow => follow.id),
      })
      message.success('Cập nhật quyền truy cập văn bản thành công!')
    } catch (error) {
      message.error(error.vi)
      console.log(error)
    }
  }

  return (
    <InternalDocumentLayout>
      <Helmet>
        <title>
          Quyền xem văn bản | Văn bản nội bộ | Quản trị | Can Tho Port Quản lý VB Can Tho Port
        </title>
      </Helmet>
      <Form
        style={formStyle}
        onFinish={onFinish}
        scrollToFirstError={true}
        name={'document-accessbilities'}
        layout={'vertical'}
        form={form}>
        <p>
          Những người được cấp quyền chỉ có thể xem toàn bộ văn bản mới được tạo
          tính từ thời điểm cấu hình được áp dụng trở về sau
        </p>

        <Form.Item
          label="Loại dữ liệu"
          style={formItemStyle}
          name="type"
          rules={[
            {
              required: true,
              message: 'Vui lòng chọn loại dữ liệu',
            },
          ]}>
          <Select placeholder="Loại dữ liệu" allowClear onChange={onChangeType}>
            <Select.Option value={DOCUMENT_TYPE.INCOMING}>
              Văn bản đến
            </Select.Option>
            <Select.Option value={DOCUMENT_TYPE.OUTGOING}>
              Văn bản đi
            </Select.Option>
          </Select>
        </Form.Item>

        <Form.Item
          style={formItemStyle}
          label="Quyền truy cập văn bản"
          name="accessType"
          allowClear
          rules={[
            {
              required: true,
              message: 'Vui lòng chọn quyền truy cập văn bản!',
            },
          ]}>
          <Select
            placeholder="Quyền truy cập văn bản"
            onChange={onChangeAccessType}>
            <Select.Option value={DOCUMENT_ACCESS_TYPE.VIEW_ALL}>
              Xem toàn bộ
            </Select.Option>
          </Select>
        </Form.Item>
        <div>
          <label style={labelStyle}>Người được cấp quyền:</label>

          <Space>
            <Tooltip title="Chọn người dùng" color={blue}>
              <Button
                icon={<UserOutlined style={{ color: blue }} />}
                onClick={() =>
                  handleOpenSelectUserDepartmentPopup(ASSIGNEE_TYPE.USER)
                }
              />
            </Tooltip>
          </Space>

          {followerList.length > 0 && (
            <SelectList style={{maxHeight: 'unset'}}>
              {followerList.map(el => (
                <Tag
                  className={'tag-selected'}
                  color={'blue'}
                  key={el.id}
                  closable
                  onClose={() => selectPeopleStore.handleRemoveSelect(el)}>
                  <AvatarHasName
                    imgId={el.image_id}
                    size={22}
                    name={el.full_name}
                    icon={
                      (el.assignee_type === ASSIGNEE_TYPE.DEPARTMENT && (
                        <BankOutlined />
                      )) ||
                      (el.assignee_type === ASSIGNEE_TYPE.GROUP && (
                        <TeamOutlined />
                      ))
                    }
                  />
                </Tag>
              ))}
            </SelectList>
          )}
        </div>
        <FormButtonGroup>
          <Button type={'primary'} htmlType={'submit'}>
            Lưu
          </Button>
        </FormButtonGroup>

        <SelectPeoplePopup
          allowChangeCompany={false}
          companyCode={authenticationStore.currentUser?.company?.code}
          disableSelectDepartment
          handleCancelSelectUser={handleCancelSelectUserDepartment}
          handleSubmitSelectUser={() =>
            setIsVisibleSelectUserDepartmentPopup(false)
          }
          isVisibleSelectPeoplePopup={isVisibleSelectUserDepartmentPopup}
        />
      </Form>
    </InternalDocumentLayout>
  )
}

export default inject(
  'selectUserStore',
  'moduleStore',
  'documentAccessibilityStore',
  'selectPeopleStore',
  'loadingAnimationStore',
  'authenticationStore'
)(observer(DocumentAccessibility))
