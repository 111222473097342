import {
  BarsOutlined,
  CheckCircleFilled,
  ExclamationCircleFilled,
  InfoCircleFilled,
  NodeExpandOutlined,
  PicRightOutlined,
  PlusOutlined,
} from '@ant-design/icons'
// Ant design
import {
  Button,
  DatePicker,
  message,
  Popconfirm,
  Select,
  Tabs,
  Tooltip,
} from 'antd'
import axios from 'axios'
//other
import { inject, observer } from 'mobx-react'
import moment from 'moment'
import queryString from 'query-string'
import React, { useEffect, useMemo, useReducer, useState } from 'react'
import { Helmet } from 'react-helmet/es/Helmet'
import { useLocation } from 'react-router-dom'
// Components
import ContentBlockWrapper from '../../components/ContentBlockWrapper'
import PageTitle from '../../components/PageTitle'
import {
  ACL_ACTION_TYPE,
  ASSIGNEE_TYPE,
  ISO_DATE_FORMAT,
  MM_YYYY,
} from '../../constants'
// Layout
import DashboardLayout from '../../layouts/DashboardLayout'
import MissionPageList from './MissionPageList'
// Styled component
import { FilterButtonWrapper, TimeMissionWrapper } from './MissionPageStyled'
import Search from './Search'
import AccessControlAction from '../../components/AccessControlAction'
import MissionCreatePopup from '../MissionCreatePage/MissionCreatePopup'
import SearchFull from './SearchFull'

const { Option, OptGroup } = Select
const { TabPane } = Tabs

const reducer = (state, action) => {
  switch (action.type) {
    case ASSIGNEE_TYPE.LEADER:
      return {
        ...state,
        [ASSIGNEE_TYPE.LEADER]:
          action.value &&
          `assignee=${encodeURIComponent(action.value)},${
            action.assigneeType
          },${ASSIGNEE_TYPE.LEADER},${action.fullName || 'unknown'}`,
      }
    case ASSIGNEE_TYPE.HANDLER:
      return {
        ...state,
        [ASSIGNEE_TYPE.HANDLER]:
          action.value &&
          `assignee=${encodeURIComponent(action.value)},${
            action.assigneeType
          },${ASSIGNEE_TYPE.HANDLER},${action.fullName || 'unknown'}`,
      }
    case ASSIGNEE_TYPE.COMBINER:
      return {
        ...state,
        [ASSIGNEE_TYPE.COMBINER]:
          action.value &&
          `assignee=${encodeURIComponent(action.value)},${
            action.assigneeType
          },${ASSIGNEE_TYPE.COMBINER},${action.fullName || 'unknown'}`,
      }
    case 'CHANGE_TAB':
      if (action.value.type === 'ALL')
        return {
          ...state,
          [ASSIGNEE_TYPE.HANDLER]: null,
          [ASSIGNEE_TYPE.COMBINER]: null,
        }
      if (action.value.type === ASSIGNEE_TYPE.COMBINER)
        return {
          ...state,
          [ASSIGNEE_TYPE.COMBINER]:
            action.value.value &&
            `assignee=${encodeURIComponent(action.value.value)},${
              ASSIGNEE_TYPE.USER
            },${ASSIGNEE_TYPE.COMBINER},${action.fullName || 'unknown'}`,
          [ASSIGNEE_TYPE.HANDLER]: null,
        }
      if (action.value.type === ASSIGNEE_TYPE.HANDLER)
        return {
          ...state,
          [ASSIGNEE_TYPE.HANDLER]:
            action.value.value &&
            `assignee=${encodeURIComponent(action.value.value)},${
              ASSIGNEE_TYPE.USER
            },${ASSIGNEE_TYPE.HANDLER},${action.fullName || 'unknown'}`,
          [ASSIGNEE_TYPE.COMBINER]: null,
        }
    default:
      return state
  }
}

const MissionPage = props => {
  const location = useLocation()
  const queryStringParse = queryString.parse(location.search, {
    arrayFormat: 'index',
  })
  const {
    taskStore,
    history,
    authenticationStore,
    departmentStore,
    loadingAnimationStore,
    selectPeopleStore,
    aclStore,
  } = props

  const { currentUser } = authenticationStore
  const {
    taskList,
    unitList,
    taskLevelList,
    missionQueryParams,
    setMissionQueryParams,
    counterTask,
  } = taskStore

  const [loadingList, setLoadingList] = useState(false)
  const [showAdvanceFilter, setShowAdvanceFilter] = useState(false)
  const [countFilter, setCountFilter] = useState(0)
  const [textLoading, setTextLoading] = useState('Đang tải nhiệm vụ...')
  const [isRefreshPage, setIsRefreshPage] = useState(true)
  const [
    isVisibleCreateMissionPopup,
    setIsVisibleCreateMissionPopup,
  ] = useState(false)

  const [usersFilter, dispatchUserFilter] = useReducer(reducer, {
    [ASSIGNEE_TYPE.LEADER]:
      queryStringParse.assignee && Array.isArray(queryStringParse.assignee)
        ? queryStringParse.assignee.find(el =>
            el.includes(ASSIGNEE_TYPE.LEADER)
          )
        : null,
    [ASSIGNEE_TYPE.HANDLER]:
      queryStringParse.assignee && Array.isArray(queryStringParse.assignee)
        ? queryStringParse.assignee.find(el =>
            el.includes(ASSIGNEE_TYPE.HANDLER)
          )
        : null,
    [ASSIGNEE_TYPE.COMBINER]:
      queryStringParse.assignee && Array.isArray(queryStringParse.assignee)
        ? queryStringParse.assignee.find(el =>
            el.includes(ASSIGNEE_TYPE.COMBINER)
          )
        : null,
  })

  useEffect(() => {
    ;(async () => {
      try {
        loadingAnimationStore.showSpinner(true)
        await Promise.all([
          taskStore.getTaskLevelList(),
          departmentStore.getDepartmentWithUsers(),
        ])
        await Promise.all([
          taskStore.getTaskListByGroup(),
          taskStore.getTaskList(),
        ])

        setIsRefreshPage(false)
      } catch (err) {
        console.log(err)
        message.error(err?.vi || 'Đã có lỗi xảy ra!')
      } finally {
        loadingAnimationStore.showSpinner(false)
      }
    })()
    return () => {
      taskStore.clearTaskStoreFilter()
      taskStore.clearTaskList()
      departmentStore.clearDepartmentList()
      selectPeopleStore.clearSelected()
    }
  }, [])

  useEffect(() => {
    if (isRefreshPage) return
    const source = axios.CancelToken.source()
    ;(async () => {
      try {
        setLoadingList(true)
        await taskStore.getTaskList(source.token)
      } catch (err) {
        console.log(err)
        message.error(err.vi || 'Đã có lỗi xảy ra!')
        setLoadingList(false)
      } finally {
        setLoadingList(false)
      }
    })()
    return () => {
      source.cancel()
    }
  }, [missionQueryParams])

  const onChangeMonth = value =>
    taskStore.handleChangeMonth(value, queryStringParse, history, 'mission')

  const handleChangeDateBy = value =>
    taskStore.handleChangeDateBy(value, queryStringParse, history, 'mission')

  const renderUnitList = useMemo(() => {
    return unitList.map((unit, index) => {
      return (
        <OptGroup
          label={
            <b
              style={{
                fontSize: 15,
                fontWeight: 500,
                color: '#000',
              }}>
              {unit.name_unit}
            </b>
          }
          key={unit.id_unit}>
          {unitList[index].user.map(user => {
            return (
              <Option value={user.id_user} key={user.id_user}>
                {user.name_user}
              </Option>
            )
          })}
        </OptGroup>
      )
    })
  }, [unitList])

  useEffect(() => {
    // Đếm số filter hiện tại
    let count = 0
    if (queryStringParse.tien_do) {
      count++
    }
    if (queryStringParse.id_user_unit) {
      count++
    }
    if (queryStringParse.id_task_level) {
      count++
    }
    if (queryStringParse.loai_nhiem_vu) {
      count++
    }
    if (queryStringParse.tag) {
      count++
    }
    if (queryStringParse.LEAD) {
      count++
    }
    if (
      queryStringParse.PIC &&
      queryStringParse.PIC !== currentUser?.username
    ) {
      count++
    }
    if (
      queryStringParse.COOR &&
      queryStringParse.COOR !== currentUser?.username
    ) {
      count++
    }
    setCountFilter(count)
  }, [queryStringParse, currentUser])

  const [activeTab, setActiveTab] = useState(ASSIGNEE_TYPE.HANDLER)
  useEffect(() => {
    if (!currentUser) return
    if (queryStringParse.assignee && Array.isArray(queryStringParse.assignee)) {
      queryStringParse.assignee.forEach(item => {
        const itemInfo = item.split(',')
        const username = itemInfo[0].split('=')[1]
        if (itemInfo.includes(ASSIGNEE_TYPE.USER)) {
          selectPeopleStore.setSelectUserData({
            ...selectPeopleStore.selectUserData,
            [itemInfo[2]]: [
              {
                assignee_type: ASSIGNEE_TYPE.USER,
                full_name: itemInfo[3],
                id: username,
                permission: itemInfo[2],
                department_code: null,
                department_name: null,
                image_id: null,
                position_name: null,
              },
            ],
          })
        }
        if (itemInfo.includes(ASSIGNEE_TYPE.DEPARTMENT)) {
          selectPeopleStore.setSelectDepartmentData({
            ...selectPeopleStore.selectDepartmentData,
            [itemInfo[2]]: [
              {
                assignee_type: ASSIGNEE_TYPE.DEPARTMENT,
                full_name: itemInfo[3],
                id: username,
                permission: itemInfo[2],
                department_code: null,
                department_name: null,
                image_id: null,
                position_name: null,
              },
            ],
          })
        }
        if (itemInfo.includes(ASSIGNEE_TYPE.GROUP)) {
          selectPeopleStore.setSelectGroupData({
            ...selectPeopleStore.selectGroupData,
            [itemInfo[2]]: [
              {
                assignee_type: ASSIGNEE_TYPE.GROUP,
                full_name: itemInfo[3],
                id: username,
                permission: itemInfo[2],
                department_code: null,
                department_name: null,
                image_id: null,
                position_name: null,
              },
            ],
          })
        }
      })
    }

    if (queryStringParse.COOR === currentUser.username) {
      dispatchUserFilter({
        type: 'CHANGE_TAB',
        value: {
          type: ASSIGNEE_TYPE.COMBINER,
          value: currentUser.username,
          fullName: currentUser.name_uppercase,
          assigneeType: ASSIGNEE_TYPE.USER,
        },
      })
      setActiveTab(ASSIGNEE_TYPE.COMBINER)
      return
    }
    if (queryStringParse.PIC === currentUser.username) {
      setActiveTab(ASSIGNEE_TYPE.HANDLER)
      dispatchUserFilter({
        type: 'CHANGE_TAB',
        value: {
          type: ASSIGNEE_TYPE.HANDLER,
          value: currentUser.username,
          fullName: currentUser.name_uppercase,
          assigneeType: ASSIGNEE_TYPE.USER,
        },
      })
      return
    }
    setActiveTab('ALL')
  }, [currentUser])

  const handleChangeTabs = tab => {
    taskStore.clearTaskList()
    setLoadingList(true)
    setActiveTab(tab)
    dispatchUserFilter({
      type: 'CHANGE_TAB',
      value: {
        type: tab,
        value: currentUser.username,
        fullName: currentUser.name_uppercase,
        assigneeType: ASSIGNEE_TYPE.USER,
      },
    })
    if (tab === ASSIGNEE_TYPE.HANDLER || tab === 'ALL') {
      selectPeopleStore.clearSelectedByType(ASSIGNEE_TYPE.COMBINER)
    }
    if (tab === ASSIGNEE_TYPE.COMBINER || tab === 'ALL') {
      selectPeopleStore.clearSelectedByType(ASSIGNEE_TYPE.HANDLER)
    }
  }

  useEffect(() => {
    taskStore.setMissionQueryParams({
      date_by: queryStringParse.date_by || 'DISPLAY_DATE',
      tien_do: queryStringParse.tien_do,
      from_date: !queryStringParse.all_time
        ? queryStringParse.from_date ||
          moment().startOf('month').format(ISO_DATE_FORMAT)
        : null,
      to_date: !queryStringParse.all_time
        ? queryStringParse.to_date ||
          moment().endOf('month').format(ISO_DATE_FORMAT)
        : null,
      id_unit: queryStringParse.id_unit,
      id_user_unit: queryStringParse.id_user_unit,
      id_task_level: queryStringParse.id_task_level,
      loai_nhiem_vu: queryStringParse.loai_nhiem_vu,
      tag: queryStringParse.tag,
      assignee: Object.values(usersFilter)
        .filter(item => item)
        .map(item => {
          const assignee = item.split(',')
          if (assignee.length === 3) {
            return assignee.join()
          }
          assignee.pop()
          return assignee.join()
        })
        .join('&'),
    })
    const queryStringStringify = queryString.stringify(
      {
        ...queryStringParse,
        assignee: Object.values(usersFilter),
        [ASSIGNEE_TYPE.LEADER]:
          typeof usersFilter[ASSIGNEE_TYPE.LEADER] === 'string'
            ? usersFilter[ASSIGNEE_TYPE.LEADER].split(',')[0].substring(9)
            : null,
        [ASSIGNEE_TYPE.HANDLER]:
          typeof usersFilter[ASSIGNEE_TYPE.HANDLER] === 'string'
            ? usersFilter[ASSIGNEE_TYPE.HANDLER].split(',')[0].substring(9)
            : null,
        [ASSIGNEE_TYPE.COMBINER]:
          typeof usersFilter[ASSIGNEE_TYPE.COMBINER] === 'string'
            ? usersFilter[ASSIGNEE_TYPE.COMBINER].split(',')[0].substring(9)
            : null,
      },
      {
        skipNull: true,
        arrayFormat: 'index',
      }
    )
    history.replace(`/mission?${queryStringStringify}`)
  }, [usersFilter])

  const handleNextMonth = (taskCode, type) =>
    taskStore.handleNextMonth(taskCode, type, setLoadingList, setTextLoading)

  const [selectUserDataBefore, setSelectUserDataBefore] = useState()
  const [selectDepartmentDataBefore, setSelectDepartmentDataBefore] = useState()
  const [selectGroupDataBefore, setSelectGroupDataBefore] = useState()

  const getTaskList = async () => {
    try {
      setLoadingList(true)
      await taskStore.getTaskList()
    } catch (err) {
      console.log(err)
      message.error(err?.vi || 'Đã có lỗi xảy ra!')
    } finally {
      setLoadingList(false)
    }
  }

  const handleCancelCreateMissionPopup = async(refesh) => {
    if (selectUserDataBefore) {
      selectPeopleStore.setSelectUserData(selectUserDataBefore)
    }
    if (selectDepartmentDataBefore) {
      selectPeopleStore.setSelectDepartmentData(selectDepartmentDataBefore)
    }
    if (selectGroupDataBefore) {
      selectPeopleStore.setSelectGroupData(selectGroupDataBefore)
    }
    setIsVisibleCreateMissionPopup(false)
    if (refesh === true) {
      await getTaskList()
    }
  }
  return (
    <DashboardLayout>
      <Helmet>
        <title>Nhiệm vụ | Can Tho Port Nhiệm vụ</title>
      </Helmet>
      <PageTitle
        location={props.location}
        title={'Danh sách nhiệm vụ tuần'}
        hiddenGoBack>
        <AccessControlAction aclActionType={ACL_ACTION_TYPE.create__MISSION}>
          <Button
            type="primary"
            onClick={() => {
              setSelectUserDataBefore(selectPeopleStore.selectUserData)
                  setSelectDepartmentDataBefore(
                    selectPeopleStore.selectDepartmentData
                  )
                  setSelectGroupDataBefore(selectPeopleStore.selectGroupData)
                  selectPeopleStore.clearSelected()
              setIsVisibleCreateMissionPopup(true)} }>
            <PlusOutlined style={{ marginRight: 5 }} />
            Thêm nhiệm vụ
          </Button>
        </AccessControlAction>
      </PageTitle>
      <ContentBlockWrapper>
        <TimeMissionWrapper>Danh sách nhiệm vụ</TimeMissionWrapper>
        <div style={{ display: 'flex', marginBottom: 16 }}>
          <div style={{ flex: '0 0 300px' }}>
            <Select
              style={{ width: 172, marginRight: 8 }}
              value={missionQueryParams.date_by}
              onChange={handleChangeDateBy}>
              <Option value="DISPLAY_DATE">Lọc theo Tháng hiển thị</Option>
              <Option value="CREATED_DATE">Lọc theo Ngày tạo</Option>
            </Select>
            <DatePicker
              style={{ width: 120 }}
              onChange={onChangeMonth}
              defaultValue={
                !queryStringParse.all_time
                  ? (queryStringParse.from_date &&
                      moment(queryStringParse.from_date)) ||
                    moment()
                  : null
              }
              picker="month"
              placeholder={'Chọn tháng'}
              format={MM_YYYY}
            />
          </div>
          <FilterButtonWrapper>
            <AccessControlAction
              aclActionType={ACL_ACTION_TYPE.chuyen_sang_thang__MISSION}>
              <Popconfirm
                title="Bạn có chắc chắn muốn chuyển toàn bộ nhiệm vụ chưa hoàn thành này sang tháng không ?"
                onConfirm={() => handleNextMonth(null, 'ALL')}
                okText="Đồng ý"
                cancelText="Không">
                <Button icon={<NodeExpandOutlined />}>
                  Chuyển tất cả sang tháng
                </Button>
              </Popconfirm>
            </AccessControlAction>
            <AccessControlAction
              aclActionType={ACL_ACTION_TYPE.hien_thi_full__MISSION}>
              <a href="/mission/full" target={'_blank'}>
                <Button icon={<PicRightOutlined />}>Hiển thị đầy đủ</Button>
              </a>
            </AccessControlAction>
          </FilterButtonWrapper>
        </div>
        {/* {showAdvanceFilter && ( */}
        {/* <SearchFull isVisibleMissionFullCreatePopup={isVisibleCreateMissionPopup}
          isVisibleMissionFullDetailModal={false}
          handleNextMonth={handleNextMonth}
          dispatchUserFilter={dispatchUserFilter}
          renderUnitList={renderUnitList}
          taskLevelList={taskLevelList}></SearchFull> */}
        <Search
          isVisibleCreateMissionPopup={isVisibleCreateMissionPopup}
          dispatchUserFilter={dispatchUserFilter}
          renderUnitList={renderUnitList}
          taskLevelList={taskLevelList}
        />
        {/* )} */}
        <Tabs activeKey={activeTab} onChange={handleChangeTabs}>
          {aclStore.checkAccessControlAction(
            ACL_ACTION_TYPE.bang_tong_hop__MISSION
          ) && <TabPane tab="Bảng tổng hợp nhiệm vụ" key={'ALL'} />}
          <TabPane tab="Xử lý" key={ASSIGNEE_TYPE.HANDLER} />
          <TabPane tab="Phối hợp" key={ASSIGNEE_TYPE.COMBINER} />
        </Tabs>
        {(activeTab === ASSIGNEE_TYPE.HANDLER ||
          activeTab === ASSIGNEE_TYPE.COMBINER) && (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              backgroundColor: '#E9F0FD',
              padding: '12px 20px 12px 10px',
            }}>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}>
              <BarsOutlined style={{ fontSize: 18, marginRight: 12 }} />
              <span
                style={{
                  fontWeight: 500,
                  fontSize: 14,
                }}>
                Nhiệm vụ tôi {activeTab === ASSIGNEE_TYPE.HANDLER && 'xử lý'}
                {activeTab === ASSIGNEE_TYPE.COMBINER && 'phối hợp'}
              </span>
            </div>
            <div>
              <span>
                <CheckCircleFilled
                  style={{ color: '#52c41a', marginRight: 4 }}
                />
                Hoàn thành:{' '}
                <span style={{ fontWeight: 500 }}>{counterTask.completed}</span>
              </span>
              <span
                style={{
                  paddingLeft: '10px',
                  marginLeft: '10px',
                  borderLeft: `2px solid  #a0bbdc`,
                }}>
                <ExclamationCircleFilled
                  style={{ color: '#ff4d4f', marginRight: 4 }}
                />
                Chưa hoàn thành:{' '}
                <span style={{ fontWeight: 500 }}>
                  {counterTask.uncompleted}
                </span>
              </span>
            </div>
          </div>
        )}
        <MissionPageList
          handleNextMonth={handleNextMonth}
          activeTab={activeTab}
          loadingList={loadingList}
          textLoading={textLoading}
        />
        <MissionCreatePopup
          isMissionFull={true}
          isModalOpen={isVisibleCreateMissionPopup}
          handleCancel={handleCancelCreateMissionPopup}
        />
      </ContentBlockWrapper>
    </DashboardLayout>
  )
}

MissionPage.propTypes = {}

export default inject(
  'taskStore',
  'commonStore',
  'loadingAnimationStore',
  'authenticationStore',
  'departmentStore',
  'selectPeopleStore',
  'aclStore',
)(observer(MissionPage))
