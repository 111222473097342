import React, { useEffect, useState } from 'react'
import { Empty, message, Modal, Select } from 'antd'
import OtherSubmissionForm from './otherSubmissionForm'
import { inject, observer } from 'mobx-react'
import FileDownload from 'js-file-download'
import selectUserStore from '../../stores/selectUserStore'
import { runInAction } from 'mobx'

const SubmissionForm = props => {
  const {
    handleCancel,
    approvalTemplates,
    showModal,
    onCloseModal,
    proposalStore,
    loadingAnimationStore,
    contentTemplateStore,
  } = props

  const [selectedApprovalId, setSelectedApprovalId] = useState(null)

  /** Render thông tin khi thay đổi form phiếu trình  */
  const handleChangeFormType = value => {
    setSelectedApprovalId(value)
  }
  /** Xử lý download văn bản mẫu */
  const handleDownloadAttachment = (fileId, fileTitle) => {
    loadingAnimationStore.showSpinner(true)
    proposalStore
      .downloadAttachment(fileId)
      .then(response => {
        loadingAnimationStore.showSpinner(false)
        const blob = new Blob([response.data], {
          type:
            'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        })
        FileDownload(blob, fileTitle)
      })
      .catch(() => {
        loadingAnimationStore.showSpinner(false)
      })
  }

  /** Render form phiếu trình tương ứng */
  const renderSubmissionForm = () => {
    if (selectedApprovalId) {
      return (
        <OtherSubmissionForm
          onCloseModal={onCloseModal}
          handleDownloadAttachment={handleDownloadAttachment}
          selectedApprovalId={selectedApprovalId}
          setSelectedApprovalId={setSelectedApprovalId}
        />
      )
    } else {
      return <Empty description={<span>Bạn chưa chọn loại!</span>} />
    }
  }
  const handleCancelAndReset = () => {
    handleCancel()
    contentTemplateStore.content = ''
    setSelectedApprovalId(null)
  }
  return (
    <Modal
      title={'Tạo mới'}
      visible={showModal}
      onCancel={handleCancelAndReset}
      width={1000}
      maskClosable={false}
      footer={null}>
      <label style={{ fontWeight: 'bold' }}>Loại:</label>
      <Select
        style={{ width: '100%', marginBottom: 30, marginTop: 5 }}
        placeholder="Chọn loại"
        onChange={handleChangeFormType}
        value={selectedApprovalId}>
        {approvalTemplates.map(item => (
          <Option name={item.name} value={item.code} key={item.code}>
            {item.name}
          </Option>
        ))}
      </Select>

      {renderSubmissionForm()}
    </Modal>
  )
}

SubmissionForm.propTypes = {}

export default inject(
  'proposalStore',
  'selectUserStore',
  'loadingAnimationStore',
  'contentTemplateStore'
)(observer(SubmissionForm))
