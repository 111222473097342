import { requests } from './DefaultRequest'

export default {
  getApprovalTemplateList: () => requests.get(`/api/v1/approval_template`),

  getApprovalTemplateDetail: code =>
    requests.get(`/api/v1/approval_template/${code}`),

  createApprovalTemplate: payload =>
    requests.post(`/api/v1/approval_template`, payload),

  updateApprovalTemplate: (code, payload) =>
    requests.put(`/api/v1/approval_template/${code}`, payload),

  delete: code => requests.delete(`/api/v1/approval_template/${code}`),

  applyApprovalTemplate: (code, payload) =>
    requests.patch(`/api/v1/approval_template/${code}`, payload),

  getApprovalLevelTemplateByATCode: code =>
    requests.get(`/api/v1/approval_template/get-levels/${code}`),
}
