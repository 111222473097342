import React, { useCallback, useEffect, useMemo } from 'react'

import ApproverListModal from '../../../../components/ApproverListModal'
import {
  BlockLeft,
  BlockRight,
  ContentWrapper,
  ViewDetailContent,
} from './ProposalDetailPageStyle'
import DashboardLayout from '../../../../layouts/DashboardLayout'
import { Helmet } from 'react-helmet/es/Helmet'
import PageTitle from '../../../../components/PageTitle'
import ContentBlockWrapper from '../../../../components/ContentBlockWrapper'
import { inject, observer } from 'mobx-react'
import ProposalDetailVehiclePage from './ProposalDetail/ProposalDetailVehicle'
import ProposalDetailLeavesPage from './ProposalDetail/ProposalDetailLeaves'
import ProposalDetailOtherPage from './ProposalDetail/ProposalDetailOther'
import loadingAnimationStore from '../../../../stores/loadingAnimationStore'
import {
  EmptyText,
  TitleContentBox,
} from '../../../../components/Common/CellText'
import { Dropdown, Menu, message, Modal } from 'antd'
import { HeadingButtonWrapper } from '../../../MissionDetailPage/MissionDetailPageStyled'
import {
  CheckOutlined,
  DeleteOutlined,
  EditOutlined,
  FileExcelOutlined,
  FileImageOutlined,
  FilePdfOutlined,
  FileTextOutlined,
  FileWordOutlined,
  FileZipOutlined,
  MoreOutlined,
  QuestionCircleOutlined,
  StopOutlined,
} from '@ant-design/icons'
import { blue, green, purple9, redPrimary } from '../../../../color'
import {
  ACL_ACTION_TYPE,
  PROPOSAL_STATUS,
  TYPE_STATUS,
} from '../../../../constants'
import ProposalComment from './ProposalComment'
import { StatusTag } from '../../../../components/Common/StatusTag'
import SpinnerInlineComponent from '../../../../components/SpinnerInlineComponent'
import ProposalDetailAction from './ProposalDetail/ProposalDetailAction'
import { AttachmentList } from './ProposalDetail/ProposalDetailStyled'
import FileItem from '../../../../components/Common/FileItemComponent/FileItem'
import utils from '../../../../utils'
import { DocumentInfoList } from '../../../../layouts/ThongTinChungLayout/ThongTinChungLayoutStyled'
import PopoverWatchTime from '../../../../components/PopoverWatchTime'
import { useState } from 'react'
import ProposalDetailUpdate from './ProposalDetailUpdate'

const { confirm } = Modal

const ProposalDetailPage = props => {
  const {
    proposalStore,
    match,
    history,
    notificationStore,
    aclStore,
    commonStore,
    authenticationStore,
  } = props
  const { proposalId, typePage } = match.params
  const { otherDetail } = proposalStore
  // Không xóa dòng khai báo aclActionsByUser (dù k dùng)
  const { aclActionsByUser } = aclStore
  const [isVisibleUpdateModal, setIsVisibleUpdateModal] = useState(false)
  const { isAccountAdmin } = authenticationStore

  const handleApprovalProposal = async data => {
    loadingAnimationStore.showSpinner(true)
    try {
      await proposalStore.approvalProposalOther(data)
      await proposalStore.getOtherDetail(proposalId)
      await proposalStore.getOtherComment(proposalId)
      if (data.type === PROPOSAL_STATUS.REJECT) {
        return message.success('Hủy thành công!')
      }
      return message.success('Duyệt thành công!')
    } catch (e) {
      console.log(e)
      if (data.type === PROPOSAL_STATUS.REJECT) {
        return message.error('Hủy thất bại!')
      }
      return message.error('Duyệt thất bại!')
    } finally {
      loadingAnimationStore.showSpinner(false)
    }
  }

  const handleDeleteProposal = async () => {
    try {
      loadingAnimationStore.showSpinner(true)
      await proposalStore.deleteProposalItem(proposalId)
      history.push('/proposal')
      message.success('Xóa thành công!')
    } catch (err) {
      console.log(err)
      message.error(err.vi || 'Đã có lỗi xảy ra, vui lòng thử lại!')
    } finally {
      loadingAnimationStore.showSpinner(false)
    }
  }

  const showConfirmApproval = type => {
    confirm({
      title: `Bạn có muốn ${
        type === PROPOSAL_STATUS.APPROVAL ? 'duyệt' : 'hủy'
      } thông tin này không?`,
      icon:
        type === PROPOSAL_STATUS.APPROVAL ? (
          <CheckOutlined style={{ color: blue }} />
        ) : (
          <StopOutlined style={{ color: 'red' }} />
        ),
      okText: 'Đồng ý',
      cancelText: 'Không',
      okType: type === PROPOSAL_STATUS.APPROVAL ? 'primary' : 'danger',
      onOk() {
        handleApprovalProposal({
          proposalCode: proposalId,
          type: type,
        }).then(() => Promise.resolve())
      },
    })
  }

  const showConfirmDelete = () => {
    confirm({
      title: 'Bạn có muốn xóa thông tin này không?',
      icon: <QuestionCircleOutlined style={{ color: 'red' }} />,
      okText: 'Đồng ý',
      cancelText: 'Không',
      okType: 'danger',
      onOk() {
        handleDeleteProposal()
      },
    })
  }

  useEffect(() => {
    return () => {
      notificationStore.clearNotificationType()
      proposalStore.clearOtherCommentList()
    }
  }, [])

  const handleCancelUpdateModal = () => {
    setIsVisibleUpdateModal(false)
    proposalStore.clearFormProposalType()
  }

  const handleOpenUpdateModal = () => {
    setIsVisibleUpdateModal(true)
  }

  const checkShowHandleDocument = () => {
    return (
      (otherDetail.proposal_status === PROPOSAL_STATUS.PENDING &&
        otherDetail?.has_no_config_approval_levels) ||
      (otherDetail &&
        !otherDetail?.has_no_config_approval_levels &&
        checkHasNoArppovedLevel())
    )
  }

  const checkAccessControlUpdateAction = () => {
    return (
      authenticationStore.currentUser.username ===
      otherDetail.create_user?.user_name
    )
  }

  const checkHasNoArppovedLevel = () => {
    if (otherDetail.has_no_config_approval_levels) return true

    if (otherDetail.approval_levels)
      return otherDetail.approval_levels
        .slice(1)
        .every(level => level.status !== 'APPROVED')
  }

  const menuHandleDocument = (
    <Menu>
      {otherDetail.proposal_status === PROPOSAL_STATUS.PENDING &&
        otherDetail?.has_no_config_approval_levels && (
          <>
            {aclStore.checkAccessControlAction(
              ACL_ACTION_TYPE.approve__PROPOSAL
            ) && (
              <Menu.Item
                onClick={() => showConfirmApproval(PROPOSAL_STATUS.APPROVAL)}
                style={{ color: blue }}
                icon={<CheckOutlined />}>
                Duyệt phiếu
              </Menu.Item>
            )}
            {aclStore.checkAccessControlAction(
              ACL_ACTION_TYPE.approve__PROPOSAL
            ) && (
              <Menu.Item
                onClick={() => showConfirmApproval(PROPOSAL_STATUS.REJECT)}
                style={{ color: 'red' }}
                icon={<StopOutlined />}>
                Từ chối
              </Menu.Item>
            )}
          </>
        )}

      {otherDetail &&
        !otherDetail?.has_no_config_approval_levels &&
        checkHasNoArppovedLevel() && (
          <>
            {checkAccessControlUpdateAction() && (
              <Menu.Item
                style={{ color: blue }}
                onClick={handleOpenUpdateModal}
                icon={<EditOutlined />}>
                Chỉnh sửa
              </Menu.Item>
            )}
            {(aclStore.checkAccessControlAction(
              ACL_ACTION_TYPE.delete__PROPOSAL
            ) ||
              isAccountAdmin ||
              checkAccessControlUpdateAction()) && (
              <Menu.Item
                danger
                onClick={showConfirmDelete}
                icon={<DeleteOutlined />}>
                Xóa
              </Menu.Item>
            )}
          </>
        )}
    </Menu>
  )

  // const renderTitlePage = useMemo(() => {
  //   switch (otherDetail.proposal_type) {
  //     case 'BUSINESS_TRIP':
  //       return 'Đăng ký công tác'
  //     case 'EVALUATE_MEMBER':
  //       return 'Đánh giá cán bộ < Nhân viên >'
  //     case 'EVALUATE_LEADER':
  //       return 'Đánh giá cán bộ < Trưởng ban >'
  //     case 'TIMEKEEPER':
  //       return 'Tổng hợp chấm công'
  //     case 'STATIONERY':
  //       return 'Văn phòng phẩm'
  //     case 'MEETING':
  //       return 'Mời họp'
  //     case 'LEAVE':
  //       return 'Nghỉ phép'
  //     case 'LEAVE_ANNUAL':
  //       return 'Nghỉ phép hàng năm'
  //     case 'LEAVE_NO_SALARY':
  //       return 'Nghỉ phép không lương'
  //     case 'VEHICLE':
  //       return 'Xin xe'
  //     case 'PROPOSAL_SALARY':
  //       return 'Duyệt lương'
  //   }
  // }, [otherDetail])

  /** Hiển thị danh sách tài liệu đính kèm */
  const renderFileIcon = file_type => {
    let iconFile = <FileTextOutlined />
    if (file_type === 'pdf') {
      iconFile = <FilePdfOutlined style={{ color: redPrimary }} />
      return iconFile
    }
    if (file_type === 'doc' || file_type === 'docx') {
      iconFile = <FileWordOutlined style={{ color: blue }} />
      return iconFile
    }
    if (
      [
        'jpg',
        'bmp',
        'dib',
        'jpe',
        'jfif',
        'gif',
        'tif',
        'tiff',
        'png',
        'heic',
      ].includes(file_type)
    ) {
      iconFile = <FileImageOutlined style={{ color: purple9 }} />
      return iconFile
    }
    if (file_type === 'rar' || file_type === 'zip') {
      iconFile = <FileZipOutlined style={{ color: '#e67e22' }} />
      return iconFile
    }
    if (file_type === 'xlsx') {
      iconFile = <FileExcelOutlined style={{ color: green }} />
      return iconFile
    }
  }

  const renderAttachmentList = useCallback(() => {
    return otherDetail.attachments?.map(item => {
      return (
        <FileItem
          key={item.file_id}
          file_id={item.file_id}
          file_name={item.file_name}
          file_type={utils.getExtensionFile(item.file_name)}
        />
      )
    })
  }, [commonStore.appTheme, renderFileIcon, otherDetail])

  return (
    <DashboardLayout>
      <Helmet>
        <title>Chi tiết hành chính| Hành Chính</title>
      </Helmet>
      <PageTitle
        location={props.location}
        showTitle={true}
        title={otherDetail.title}
        status={StatusTag(TYPE_STATUS.PROPOSAL, otherDetail.proposal_status)}>
        <HeadingButtonWrapper>
          {checkShowHandleDocument() ? (
            <Dropdown overlay={menuHandleDocument} trigger={['click']}>
              <span className={'btn-style'}>
                <MoreOutlined style={{ color: blue }} />
              </span>
            </Dropdown>
          ) : (
            <></>
          )}
        </HeadingButtonWrapper>
      </PageTitle>

      {otherDetail && !otherDetail?.has_no_config_approval_levels ? (
        <ProposalDetailAction />
      ) : (
        <></>
      )}

      <ViewDetailContent>
        <ContentWrapper>
          <BlockLeft>
            <ContentBlockWrapper>
              <TitleContentBox>Nội dung</TitleContentBox>
              {typePage === 'vehicle' ? (
                <ProposalDetailVehiclePage proposalId={proposalId} />
              ) : typePage === 'other' ? (
                <ProposalDetailOtherPage proposalId={proposalId} />
              ) : (
                <ProposalDetailLeavesPage proposalId={proposalId} />
              )}
            </ContentBlockWrapper>
          </BlockLeft>
          <BlockRight>
            <ContentBlockWrapper>
              <TitleContentBox>Thông tin chung</TitleContentBox>
              <DocumentInfoList>
                {/* <dt>Loại</dt>
                <dd>{renderTitlePage}</dd> */}

                <dt>Tài liệu đính kèm</dt>
                <dd>
                  {otherDetail.attachments?.length !== 0 ? (
                    <>
                      <AttachmentList>{renderAttachmentList()}</AttachmentList>
                    </>
                  ) : (
                    <EmptyText>Không có tài liệu đính kèm.</EmptyText>
                  )}
                </dd>

                <dt>Người theo dõi</dt>
                <dd>
                  {proposalStore.otherDetail.followers &&
                  proposalStore.otherDetail.followers.length > 0 ? (
                    <PopoverWatchTime
                      userList={proposalStore.otherDetail.followers.map(
                        item => {
                          return {
                            full_name: item.name_lowercase,
                            id: item.user_name,
                            assignee_type: 'USER',
                            last_date_read: item.last_date_read,
                          }
                        }
                      )}
                      title={'Danh sách người theo dõi'}
                    />
                  ) : (
                    <EmptyText>Không có người theo dõi.</EmptyText>
                  )}
                </dd>
              </DocumentInfoList>
            </ContentBlockWrapper>

            <div style={{ marginBottom: 15 }} />

            <ContentBlockWrapper style={{ position: 'relative' }}>
              <ProposalComment proposalId={proposalId} />
              <SpinnerInlineComponent sizeSpin={'small'} />
            </ContentBlockWrapper>
          </BlockRight>
        </ContentWrapper>
      </ViewDetailContent>

      <ApproverListModal />

      <Modal
        title={'Chỉnh sửa hành chính'}
        onCancel={handleCancelUpdateModal}
        width={1000}
        footer={null}
        maskClosable={false}
        visible={isVisibleUpdateModal}
        destroyOnClose>
        {isVisibleUpdateModal && (
          <ProposalDetailUpdate onCancel={handleCancelUpdateModal} />
        )}
      </Modal>
    </DashboardLayout>
  )
}

ProposalDetailPage.propTypes = {}

export default inject(
  'proposalStore',
  'notificationStore',
  'aclStore',
  'commonStore',
  'authenticationStore'
)(observer(ProposalDetailPage))
