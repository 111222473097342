import React, { useEffect, useState } from 'react'
import DashboardLayout from '../../layouts/DashboardLayout/DashboardLayout'
import { Helmet } from 'react-helmet/es/Helmet'
import PageTitle from '../../components/PageTitle'
import ContentBlockWrapper from '../../components/ContentBlockWrapper'
import { inject, observer } from 'mobx-react'
import queryString from 'query-string'
import { message } from 'antd'
import { useHistory, useLocation } from 'react-router-dom'
import MySalaryList from './MySalaryList'

const MySalaryPage = props => {
  const { salaryStore } = props
  const location = useLocation()
  const history = useHistory()
  const queryStringParse = queryString.parse(location.search)
  const [isLoadingMySalary, setIsLoadingMySalary] = useState(false)
  const [isRefreshPage, setIsRefreshPage] = useState(true)
  const loadMySalaryList = async () => {
    const queryParams = {
      page: salaryStore.filterPageMySalary,
      size: salaryStore.filterSizeMySalary,
    }
    try {
      setIsLoadingMySalary(true)
      await salaryStore.getMySalaryList()
      const queryStringStringify = queryString.stringify(queryParams, {
        skipNull: true,
      })
      history.replace(`/my-salary?${queryStringStringify}`)
    } catch (err) {
      console.log(err)
      message.error(err.vi || 'Đã có lỗi xảy ra!')
    } finally {
      setIsLoadingMySalary(false)
    }
  }

  useEffect(() => {
    salaryStore.setQueryParams(
      'filterPageMySalary',
      queryStringParse.page ? parseInt(queryStringParse.page) : 0
    )
    salaryStore.setQueryParams(
      'filterSizeMySalary',
      queryStringParse.size ? parseInt(queryStringParse.size) : 10
    )
    ;(async () => {
      await loadMySalaryList()
      setIsRefreshPage(false)
    })()
    return () => {
      salaryStore.clearMySalaryList()
    }
  }, [])

  useEffect(() => {
    if (isRefreshPage) return
    ;(async () => {
      await loadMySalaryList()
    })()
  }, [salaryStore.filterPageMySalary, salaryStore.filterSizeMySalary])

  return (
    <DashboardLayout>
      <Helmet>
        <title>Bảng lương của tôi | Can Tho Port bảng lương của tôi</title>
      </Helmet>
      <PageTitle location={props.location} />
      <ContentBlockWrapper style={{ minHeight: 'calc(100vh - 150px)' }}>
        <MySalaryList isLoading={isLoadingMySalary} />
      </ContentBlockWrapper>
    </DashboardLayout>
  )
}

MySalaryPage.propTypes = {}
export default inject('salaryStore')(observer(MySalaryPage))
