import React, { memo, useEffect, useState } from 'react'
import { Button, Form, Input, message, Modal } from 'antd'
import validator from '../../validator'
import departmentStore from '../../stores/departmentStore'
import authenticationStore from '../../stores/authenticationStore'
import { MODAL_MODE } from '../../ui_constants'
import { inject, observer } from 'mobx-react'
import { toJS } from 'mobx'

const CreateDepartmentModal = props => {
  const {
    isVisibleCreateDepartmentModal,
    mode,
    handleCloseCreateDepartmentModal,
    currentDepartment,

    userStore,
    onFinishUpdate,
    onFinishCreate,
  } = props

  const [isLoading, setIsLoading] = useState(false)

  const [form] = Form.useForm()

  useEffect(() => {
    if (isVisibleCreateDepartmentModal && mode === MODAL_MODE.UPDATE) {
      form.setFieldsValue({
        name: currentDepartment?.name,
      })
    }
  }, [isVisibleCreateDepartmentModal])

  const onFinishForm = async values => {
    try {
      setIsLoading(true)
      const newDepartment = {
        code: currentDepartment.code,
        name: values.name,
      }

      const { name } = values
      if (mode === MODAL_MODE.UPDATE) {
        await departmentStore.update(currentDepartment.code, name)
        await onFinishUpdate(newDepartment)
      } else {
        const { data } = await departmentStore.create(name)
        await onFinishCreate(data)
      }

      closeAndResetForm()

      message.success(
        `${
          mode === MODAL_MODE.UPDATE ? `Chỉnh sửa` : `Tạo mới`
        } phòng ban thành công`
      )
    } catch (e) {
      if (e.errorCode && e.errorCode === 'DEPARTMENT-102') {
        message.error('Tên phòng ban đã tồn tại')
      } else {
        message.error('Đã có lỗi xảy ra!')
      }
    } finally {
      setIsLoading(false)
    }
  }

  const closeAndResetForm = () => {
    form.resetFields()
    handleCloseCreateDepartmentModal()
  }

  const validateDepartmentName = async (rule, value) => {
    if (value && value.trim() === '') {
      return Promise.reject('Cần nhập ký tự, vui lòng không bỏ trống')
    } else {
      if (value === undefined) return
      try {
        if (
          mode === MODAL_MODE.CREATE ||
          (mode === MODAL_MODE.UPDATE && value !== currentDepartment.name)
        ) {
          const response = await departmentStore.getWarningDepartmentName(value)
          if (response.data.status === 'WARNING') {
            return Promise.reject(response.data.message)
          } else {
            return Promise.resolve()
          }
        }
      } catch (error) {
        console.log(error)
        message.error(error?.vi || 'Đã có lỗi xảy ra!')
      }
    }
  }

  return (
    <Modal
      title={`${
        mode === MODAL_MODE.UPDATE ? `Chỉnh sửa` : `Tạo mới`
      } phòng ban`}
      footer={null}
      closable={true}
      visible={isVisibleCreateDepartmentModal}
      onCancel={closeAndResetForm}>
      <Form form={form} layout={'vertical'} onFinish={onFinishForm}>
        <Form.Item
          name={'name'}
          rules={[
            { required: true, message: 'Vui lòng nhập tên phòng ban!' },
            { validator: validateDepartmentName },
          ]}
          label={'Tên phòng ban:'}>
          <Input placeholder={'Nhập tên phòng ban'} />
        </Form.Item>

        <Form.Item>
          <Button loading={isLoading} htmlType={'submit'} type={'primary'}>
            {mode === MODAL_MODE.UPDATE ? `Chỉnh sửa` : `Tạo mới`}
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  )
}

CreateDepartmentModal.propTypes = {}

export default memo(inject('userStore')(observer(CreateDepartmentModal)))
