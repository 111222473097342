import React, { useEffect, useState } from 'react'
import DashboardLayout from '../../layouts/DashboardLayout/DashboardLayout'
import { Helmet } from 'react-helmet/es/Helmet'
import PageTitle from '../../components/PageTitle'
import ContentBlockWrapper from '../../components/ContentBlockWrapper'
import { message, Tooltip, Typography } from 'antd'
import SalaryFilter from './SalaryFilter'
import SalaryStep from './SalaryStep'
import { inject, observer } from 'mobx-react'
import SalaryList from './SalaryList'
import { CalendarOutlined } from '@ant-design/icons'
import { blue } from '../../color'
import SalaryInfo from './SalaryInfo'
import { toJS } from 'mobx'
import FileViewPopup from '../../components/FileViewPopup'
import { useParams } from 'react-router-dom'
import SpinnerInlineComponent from '../../components/SpinnerInlineComponent'
import moment from 'moment'
import { DATE_FORMAT_SLASH } from '../../constants'
import FileItem from '../../components/Common/FileItemComponent/FileItem'
import utils from '../../utils'
import { EmptyText } from '../../components/Common/CellText'

const { Paragraph, Text } = Typography

const show = {
  flex: '0 0 360px',
  width: 360,
  transition: '0.5s',
  overflow: 'hidden',
}
const hide = {
  flex: '0 0 0',
  width: 0,
  transition: '0.5s',
  overflow: 'hidden',
}
const SalaryPage = props => {
  const { salaryStore } = props

  const { isShowSalaryInfo, salaryInfo } = salaryStore
  const [isLoadingSalary, setIsLoadingSalary] = useState(false)

  console.log('salaryInfo', toJS(salaryInfo))
  const { id: salaryCode } = useParams()

  const [isLoadingDetailSalary, setIsLoadingDetailSalary] = useState(false)

  useEffect(() => {
    if (salaryCode) {
      ;(async () => {
        try {
          setIsLoadingDetailSalary(true)
          await salaryStore.getSalaryDetail(salaryCode)
        } catch (err) {
          console.log(err)
          message.error(err.vi || 'Đã có lỗi xảy ra!')
        } finally {
          setIsLoadingDetailSalary(false)
        }
      })()
    }
    return () => {
      salaryStore.clearSalary()
    }
  }, [salaryCode])

  const [isVisibleFileViewPopup, setIsVisibleFileViewPopup] = useState(false)

  return (
    <DashboardLayout>
      <Helmet>
        <title>Bảng lương | Can Tho Port bảng lương</title>
      </Helmet>
      <PageTitle
        title={`${
          !!salaryCode ? 'Chi tiết bảng lương' : 'Tạo trình duyệt lương'
        } `}
        showTitle={true}
        location={props.location}
      />
      {isLoadingDetailSalary && (
        <ContentBlockWrapper style={{ height: 600, position: 'relative' }}>
          <SpinnerInlineComponent
            noBackground
            alignItems
            isLoading={isLoadingDetailSalary}
            tip={'Đang tải bảng lương...'}
          />
        </ContentBlockWrapper>
      )}

      {!isLoadingDetailSalary && (
        <ContentBlockWrapper style={{ marginBottom: 20 }}>
          <SalaryStep />
          <SalaryFilter />
        </ContentBlockWrapper>
      )}

      {!isLoadingDetailSalary && (
        <ContentBlockWrapper style={{ minHeight: 'calc(100vh - 500px)' }}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              marginBottom: 4,
              alignItems: 'center',
            }}>
            <Paragraph
              ellipsis={{
                rows: 1,
              }}
              strong
              style={{
                marginBottom: 0,
                fontSize: 16,
                opacity: salaryInfo.file_name ? 1 : 0,
              }}>
              <span>Chi tiết bảng lương từ file</span>
              <Tooltip title={'Xem file'}>
                <span
                  onClick={() => setIsVisibleFileViewPopup(true)}
                  style={{
                    marginLeft: 6,
                    color: blue,
                    textDecoration: 'underline',
                    cursor: 'pointer',
                  }}>
                  {salaryInfo.file_name}
                </span>
              </Tooltip>
            </Paragraph>
            {salaryInfo.created_date && (
              <div style={{ fontSize: 14 }}>
                <CalendarOutlined style={{ marginRight: 4 }} />
                Ngày trình:
                <span style={{ marginLeft: 8, fontWeight: 500 }}>
                  {moment(salaryInfo.created_date).format(DATE_FORMAT_SLASH)}
                </span>
              </div>
            )}
          </div>
          {salaryInfo.status && (
            <div
              style={{
                marginBottom: 5,
                borderBottom: '1px dashed #f0f0f0',
                paddingBottom: 15,
                display: 'flex',
                marginTop: 10,
                gap: 10,
              }}>
              <span style={{ fontWeight: 500 }}>Tài liệu đính kèm:</span>
              <div>
                {salaryInfo.attachments && salaryInfo.attachments.length > 0 ? (
                  salaryInfo.attachments.map(file => (
                    <FileItem
                      key={file.file_id}
                      file_id={file.file_id}
                      file_name={file.file_name}
                      file_type={utils.getExtensionFile(file.file_name)}
                    />
                  ))
                ) : (
                  <EmptyText>Không có.</EmptyText>
                )}
              </div>
            </div>
          )}

          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              width: '100%',
            }}>
            <SalaryList
              isLoadingSalary={isLoadingSalary}
              isShowSalaryInfo={isShowSalaryInfo}
            />
            <div style={isShowSalaryInfo ? show : hide}>
              <SalaryInfo />
            </div>
          </div>
        </ContentBlockWrapper>
      )}

      <FileViewPopup
        file={{
          file_name: salaryInfo.file_name,
          file_id: salaryInfo.file_id,
        }}
        isVisibleFileViewPopup={isVisibleFileViewPopup}
        handleCloseFileViewPopup={() => setIsVisibleFileViewPopup(false)}
      />
    </DashboardLayout>
  )
}

SalaryPage.propTypes = {}

export default inject('salaryStore')(observer(SalaryPage))
