import React, { useEffect, useState } from 'react'
import DashboardLayout from '../../layouts/DashboardLayout/DashboardLayout'
import { Helmet } from 'react-helmet/es/Helmet'
import PageTitle from '../../components/PageTitle'
import { inject, observer } from 'mobx-react'
import ContentBlockWrapper from '../../components/ContentBlockWrapper'
import { message } from 'antd'
import SpinnerInlineComponent from '../../components/SpinnerInlineComponent'
import ModuleHeader from './ModuleHeader'
import ModuleList from './ModuleList'
import { useHistory } from 'react-router-dom'

const ModulePage = props => {
  const { moduleStore, authenticationStore } = props
  const history = useHistory()

  const [isLoadingModuleList, setIsLoadingModuleList] = useState(false)

  useEffect(() => {
    if (authenticationStore.isSuperAdmin || !authenticationStore.currentUser) {
      return
    }

    history.replace('/')
    message.error('Bạn không có quyền truy cập trang này!')
  }, [authenticationStore.currentUser])

  
  useEffect(() => {
    ;(async () => {
      try {
        setIsLoadingModuleList(true)
        await moduleStore.getModuleList()
      } catch (err) {
        console.log(err)
        message.error(err.vi || 'Đã có lỗi xảy ra!')
      } finally {
        setIsLoadingModuleList(false)
      }
    })()
    return () => {
      moduleStore.clearModuleStore()
    }
  }, [])

  return (
    <DashboardLayout isSuperAdminPage>
      <Helmet>
        <title>Quản lý phân hệ | Can Tho Port Quản lý phân hệ</title>
      </Helmet>
      <PageTitle location={props.location} />
      <ContentBlockWrapper
        style={{ minHeight: 'calc(100vh - 140px)', position: 'relative' }}>
        <div
          style={{
            maxWidth: 700,
            margin: '0 auto',
          }}>
          <ModuleHeader />
          <ModuleList />
        </div>
        {isLoadingModuleList && (
          <SpinnerInlineComponent
            alignItems
            noBackground
            tip={'Đang tải module...'}
            isLoading={isLoadingModuleList}
          />
        )}
      </ContentBlockWrapper>
    </DashboardLayout>
  )
}

ModulePage.propTypes = {}

export default inject('moduleStore', 'authenticationStore')(observer(ModulePage))
