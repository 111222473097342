import { Tabs } from 'antd'
import React from 'react'
import { Helmet } from 'react-helmet'
import { DocumentManagementTabLayout } from '../../DocumentManagement/DocumentManagementStyled'
import RiskTemplateList from '../RiskTemplate/RiskTemplateList'
import AdministrativeManagementLayout from './AdministrativeManagementLayout'

const AdministrativeManagementRiskTemplate = () => {
  return (
    <AdministrativeManagementLayout selectedKey="risk-template">
      <Helmet>
        <title>Phiếu rủi ro | Quản trị | Hành chính</title>
      </Helmet>
      <DocumentManagementTabLayout>
        <Tabs
          tabPosition={'left'}
          // activeKey={activeKey}
          // onChange={onChangeTabs}
        >
          <Tabs.TabPane tab="Rủi ro nhận diện" key="connected-truc-document" />
        </Tabs>
        <div style={{ flexGrow: 1 }}>
          <RiskTemplateList />
        </div>
      </DocumentManagementTabLayout>
    </AdministrativeManagementLayout>
  )
}

export default AdministrativeManagementRiskTemplate
