import React, {
  Fragment,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'

import { Helmet } from 'react-helmet/es/Helmet'
import PageTitle from '../../components/PageTitle'
import ContentBlockWrapper from '../../components/ContentBlockWrapper'
import DashboardLayout from '../../layouts/DashboardLayout'
import { inject, observer } from 'mobx-react'
import {
  DraggerUploadStyled,
  FormButtonGroup,
} from './TaskManagementCreatePageStyled'
import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  message,
  Row,
  Select,
  TreeSelect,
  Upload,
} from 'antd'
import validator from '../../validator'
import utils from '../../utils'
import { CloudUploadOutlined, FilePdfOutlined } from '@ant-design/icons'
import SampleDocumentDrawer from '../../components/SampleDocumentDrawer'
import moment from 'moment'
import { Container } from '../../layouts/Container/Container'
import RichEditor from '../../components/RichEditor/RichEditor'

const { Option } = Select
const { Dragger } = Upload
const { SHOW_PARENT } = TreeSelect

const TaskManagementCreatePage = props => {
  const [form] = Form.useForm()
  const [fileList, setFileList] = useState([])

  const {
    history,
    commonStore,
    taskManagementStore,
    fileStore,
    loadingAnimationStore,
    departmentStore,
    staffStore,
    authenticationStore,
    companyStore,
    internalDocumentStore,
  } = props
  const documentId = props?.location?.state?.code
  const { selectedInternalDocumentIncomingAttachment } = internalDocumentStore
  const { departmentList } = departmentStore
  const { currentUser } = authenticationStore
  const { leaderList } = companyStore
  const [showSampleDocumentDrawer, setShowSampleDocumentDrawer] = useState(
    false
  )
  const [editorContent, setEditorContent] = useState(null)
  const EDITOR_REF = useRef()

  const handleCreateTask = useCallback(
    taskData => {
      taskManagementStore
        .createTask(taskData)
        .then(() => {
          message.success('Tạo công việc thành công!')
          history.push('/my-tasks')
          loadingAnimationStore.showSpinner(false)
        })
        .catch(error => {
          message.error(error.vi)
          loadingAnimationStore.showSpinner(false)
        })
    },
    [history, loadingAnimationStore, taskManagementStore]
  )
  const layout = {
    labelCol: { span: 7 },
    wrapperCol: { span: 14 },
  }
  const onFinish = useCallback(
    values => {
      let submitValues = {
        ...values,
        type: null,
        code: values.code.replace(/ /g, '').toUpperCase(),
        person_in_charge: values.person_in_charge.value,
        task_description: EDITOR_REF.current.editor.getData(),
        co_op: {
          department_codes: [],
          user_codes: [],
        },
        followers: {
          department_codes: [],
          user_codes: [],
        },
        ...(!utils.isNullish(values.deadline) && {
          deadline: moment(values.deadline).toISOString(),
        }),
      }
      values.co_op &&
        values.co_op.forEach(entry => {
          entry.value.includes('USR')
            ? submitValues.co_op.user_codes.push(entry.value)
            : submitValues.co_op.department_codes.push(entry.value)
        })
      values.followers &&
        values.followers.forEach(entry => {
          entry.value.includes('USR')
            ? submitValues.followers.user_codes.push(entry.value)
            : submitValues.followers.department_codes.push(entry.value)
        })
      if (documentId) {
        // Công việc từ văn bản nội bộ
        submitValues.type = '300'
      } else {
        // Công việc tạo tay
        submitValues.type = '301'
      }
      loadingAnimationStore.showSpinner(true)
      if (fileList.length === 0 && !documentId) {
        submitValues.attachments = []
        handleCreateTask(submitValues)
        return
      }
      const uploadFileList = fileList.map(file => file.originFileObj)
      const batchUploadArr = []
      uploadFileList.forEach(file => {
        const formData = new FormData()
        formData.append('file', file)
        batchUploadArr.push(fileStore.uploadFile(formData))
      })
      let uploadedFiles = []
      Promise.all(batchUploadArr)
        .then(response => {
          uploadedFiles = response.map(response => response.data.file_id)
          if (values.internalFile) {
            submitValues.attachments = [values.internalFile, ...uploadedFiles]
          } else {
            submitValues.attachments = uploadedFiles
          }
          handleCreateTask(submitValues)
        })
        .catch(error => {
          message.error(error.vi)
          loadingAnimationStore.showSpinner(false)
        })
    },
    [documentId, loadingAnimationStore, fileList, handleCreateTask, fileStore]
  )
  const handleAddFileToUpload = useCallback(() => false, [])
  const handleChangeFile = useCallback(info => {
    const filteredFileList = info.fileList.filter(
      (elem, index, fileArray) =>
        fileArray.findIndex(file => file.name === elem.name) === index
    )
    setFileList(filteredFileList)
  }, [])
  const handleAddSampleDocument = useCallback(data => {
    setEditorContent(data)
  }, [])

  useEffect(() => {
    if (!currentUser) return
    loadingAnimationStore.showSpinner(true)
    Promise.all([
      departmentStore.getDepartmentWithUsers(),
      companyStore.getUserListWithRole('LEADER', currentUser.company.code),
    ]).finally(() => loadingAnimationStore.showSpinner(false))
    return () => staffStore.clearSelectedStaffList()
  }, [
    companyStore,
    currentUser,
    departmentStore,
    loadingAnimationStore,
    staffStore,
  ])
  useEffect(() => {
    if (!documentId) return
    loadingAnimationStore.showSpinner(true)
    internalDocumentStore
      .getInternalDocumentIncomingById(documentId)
      .then(response => {
        if (response.data.file_id) {
          internalDocumentStore.getSelectedInternalDocumentAttachment(
            documentId,
            response.data.file_id
          )
          form.setFieldsValue({
            internalFile: response.data.file_id,
          })
        }
      })
      .catch(error => {
        message.error(error.vi)
      })
      .finally(() => {
        loadingAnimationStore.showSpinner(false)
      })
    return () => {
      form.resetFields()
      internalDocumentStore.clearSelectedInternalDocumentIncoming()
    }
  }, [documentId, form, internalDocumentStore, loadingAnimationStore])

  return (
    <DashboardLayout>
      <Helmet>
        <title>Tạo mới công việc | Can Tho Port Quản lý công việc</title>
      </Helmet>
      <PageTitle location={props.location} title={'Thêm công việc'} />
      <ContentBlockWrapper>
        <Container maxWidth={820}>
          <Form
            {...layout}
            scrollToFirstError={true}
            form={form}
            style={{ paddingTop: '2rem' }}
            onFinish={onFinish}
            name={'create-task-form'}>
            <Form.Item
              name={'title'}
              label={'Tiêu đề'}
              rules={[
                { required: true, message: 'Vui lòng nhập tiêu đề công việc!' },
                { validator: validator.validateInputString },
              ]}>
              <Input placeholder={'Tiêu đề công việc'} />
            </Form.Item>

            <Form.Item
              name={'code'}
              label={'Mã công việc'}
              rules={[
                { required: true, message: 'Vui lòng nhập mã công việc!' },
                { validator: validator.validateInputString },
              ]}>
              <Input placeholder={'Mã công việc'} />
            </Form.Item>

            <Form.Item
              name={'work_type'}
              label={'Loại công việc'}
              rules={[
                {
                  required: true,
                  message: 'Vui lòng lựa chọn loại công việc!',
                },
              ]}>
              <Select placeholder={'-- Chọn loại công việc --'}>
                <Option value={'010'}>Công việc từ văn bản</Option>
                <Option value={'011'}>Công việc xin ý kiến</Option>
                <Option value={'012'}>Công việc hành chính</Option>
              </Select>
            </Form.Item>

            <Form.Item
              name={'process'}
              label={'Quy trình'}
              rules={[
                {
                  required: true,
                  message: 'Vui lòng lựa chọn quy trình công việc!',
                },
              ]}>
              <Select placeholder={'-- Chọn quy trình công việc --'}>
                <Option value={'020'}>Bình thường</Option>
                <Option value={'021'}>Quy trình 2 bước</Option>
                <Option value={'022'}>Quy trình 3 bước</Option>
              </Select>
            </Form.Item>

            <Form.Item name={'leader'} label={'Lãnh đạo giao việc'}>
              <Select
                showSearch
                filterOption={true}
                optionFilterProp={'name'}
                allowClear
                placeholder={'-- Chọn lãnh đạo --'}>
                {leaderList.map(leader => (
                  <Option
                    key={leader.user_code}
                    name={leader.name_uppercase}
                    value={leader.user_code}>
                    {leader.name_uppercase &&
                      `${utils.getNameInCapitalize(leader.name_uppercase)} (${
                        leader.email
                      })`}
                  </Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item
              name={'processing_unit'}
              label={'Đơn vị xử lý'}
              rules={[{ validator: validator.validateInputString }]}>
              <Input placeholder={'Đơn vị xử lý'} />
            </Form.Item>

            <Form.Item
              label={'Người xử lý'}
              name={'person_in_charge'}
              rules={[
                { required: true, message: 'Vui lòng chọn người xử lý' },
                { validator: validator.validatorSelectUser },
              ]}>
              <TreeSelect
                treeDataSimpleMode
                treeData={departmentList}
                style={{ width: '100%' }}
                filterTreeNode
                treeNodeFilterProp={'name'}
                showArrow
                allowClear
                showSearch
                labelInValue={true}
                placeholder={'-- Chọn người xử lý --'}
              />
            </Form.Item>

            <Form.Item label={'Người phối hợp'} name={'co_op'}>
              <TreeSelect
                treeDataSimpleMode
                treeData={departmentList}
                style={{ width: '100%' }}
                showArrow
                allowClear
                showSearch
                filterTreeNode
                treeNodeFilterProp={'name'}
                labelInValue={true}
                treeCheckable={true}
                showCheckedStrategy={SHOW_PARENT}
                placeholder={'-- Chọn người phối hợp --'}
              />
            </Form.Item>

            <Form.Item label={'Người theo dõi'} name={'followers'}>
              <TreeSelect
                treeDataSimpleMode
                treeData={departmentList}
                style={{ width: '100%' }}
                showArrow
                allowClear
                showSearch
                filterTreeNode
                treeNodeFilterProp={'name'}
                labelInValue={true}
                treeCheckable={true}
                showCheckedStrategy={SHOW_PARENT}
                placeholder={'-- Chọn người theo dõi --'}
              />
            </Form.Item>

            <Form.Item
              name={'deadline'}
              label={'Hạn xử lý'}
              rules={[
                { required: true, message: 'Vui lòng nhập thời hạn xử lý' },
              ]}>
              <DatePicker
                showTime
                style={{ width: '100%' }}
                format={`DD-MM-YYYY HH:mm`}
                placeholder={'Chọn thời hạn xử lý'}
              />
            </Form.Item>

            <Form.Item
              name={'urgency'}
              label={'Mức độ ưu tiên / Độ ảnh hưởng'}
              rules={[{ validator: validator.validateInputString }]}>
              <Input placeholder={'Mức độ ảnh hưởng'} />
            </Form.Item>
            <Form.Item
              label={
                <div
                  style={{
                    display: 'flex',
                    width: '100%',
                    justifyContent: 'space-between',
                  }}>
                  <span>Nội dung</span>
                </div>
              }>
              <RichEditor
                EDITOR_REF={EDITOR_REF}
                editorContent={editorContent}
              />
              <a
                onClick={() => setShowSampleDocumentDrawer(true)}
                style={{
                  color: commonStore.appTheme.solidColorm,
                  marginTop: 8,
                  display: 'inline-block',
                }}>
                Nội dung hành chính
              </a>
            </Form.Item>
            <Form.Item name={'attachments'} label={'Tài liệu đính kèm'}>
              <Dragger
                valuePropName={'fileList'}
                fileList={fileList}
                multiple={true}
                onChange={handleChangeFile}
                beforeUpload={handleAddFileToUpload}>
                <DraggerUploadStyled>
                  <CloudUploadOutlined />
                  <span>Kéo thả hoặc click vào để tải lên tài liệu</span>
                </DraggerUploadStyled>
              </Dragger>
            </Form.Item>
            {selectedInternalDocumentIncomingAttachment && (
              <Fragment>
                <Form.Item style={{ display: 'none' }} name={'internalFile'}>
                  <Input />
                </Form.Item>
                <Form.Item label="File đã tải lên">
                  <a
                    download
                    href={URL.createObjectURL(
                      selectedInternalDocumentIncomingAttachment
                    )}>
                    <FilePdfOutlined /> Bấm để tải
                  </a>
                </Form.Item>
              </Fragment>
            )}
            <Row>
              <Col span={14} offset={7}>
                <FormButtonGroup>
                  <Button htmlType={'submit'} type={'primary'}>
                    Tạo công việc
                  </Button>
                  <Button
                    onClick={() => history.push('/my-tasks')}
                    style={{ marginLeft: 15 }}>
                    Huỷ bỏ
                  </Button>
                </FormButtonGroup>
              </Col>
            </Row>
          </Form>
        </Container>
      </ContentBlockWrapper>
      <SampleDocumentDrawer
        onAddDocument={handleAddSampleDocument}
        onClose={() => setShowSampleDocumentDrawer(false)}
        drawerVisible={showSampleDocumentDrawer}
      />
    </DashboardLayout>
  )
}

TaskManagementCreatePage.propTypes = {}

export default inject(
  'commonStore',
  'taskManagementStore',
  'staffStore',
  'loadingAnimationStore',
  'departmentStore',
  'authenticationStore',
  'companyStore',
  'fileStore',
  'internalDocumentStore',
)(observer(TaskManagementCreatePage))
