import React from 'react'
import { Helmet } from 'react-helmet/es/Helmet'
import ConnectedDocumentLayout from '../../layouts/ConnectedDocumentLayout'
import { Container } from '../../layouts/Container/Container'
import ContentBlockWrapper from '../../components/ContentBlockWrapper'
import PageTitle from '../../components/PageTitle'
import { Button, DatePicker, Form, Input, Select, Upload } from 'antd'
import { CloudUploadOutlined } from '@ant-design/icons'
import validator from '../../validator'
import {
  DraggerUploadStyled,
  FormActionFooter,
} from './ConnectedDocumentIncomingDocCreatePageStyled'
import { inject, observer } from 'mobx-react'
import { DATE_FORMAT_LIST } from '../../constants'

const { Option } = Select
const { TextArea } = Input
const { Dragger } = Upload

const ConnectedDocumentIncomingDocCreatePage = props => {
  const { commonStore, history } = props

  const [form] = Form.useForm()

  const onFinish = values => {
    console.log(values)
  }

  const handleAddFileToUpload = () => {
    return false
  }

  const handleCancelCreate = () => {
    form.resetFields()
    history.push('/eOffice/incoming-document')
  }

  return (
    <ConnectedDocumentLayout>
      <Helmet>
        <title>Tạo văn bản đến | Can Tho Port Liên thông văn bản</title>
      </Helmet>
      <ContentBlockWrapper>
        <Container maxWidth={640}>
          <PageTitle
            location={props.location}
            title={'Tạo văn bản đến'}
            marginBottom={30}
            marginTop={30}
            color={commonStore.appTheme.solidColor}
          />
          <Form
            scrollToFirstError={true}
            form={form}
            name={'create-incoming-document'}
            layout={'vertical'}
            onFinish={onFinish}>
            <Form.Item
              label={'Nhóm sổ văn bản'}
              name={'group_book'}
              rules={[
                { required: true, message: ' Vui lòng chọn nhóm sổ văn bản!' },
              ]}>
              <Select placeholder={'-- Chọn nhóm sổ văn bản --'}></Select>
            </Form.Item>

            <Form.Item
              label={'Sổ văn bản'}
              name={'book'}
              rules={[
                { required: true, message: ' Vui lòng chọn sổ văn bản!' },
              ]}>
              <Select placeholder={'-- Chọn sổ văn bản --'}></Select>
            </Form.Item>

            <Form.Item
              label={'Số hiệu'}
              name={'document_number'}
              rules={[
                { required: true, message: ' Vui lòng nhập số hiệu văn bản!' },
                { validator: validator.validateInputString },
              ]}>
              <Input placeholder={'Nhập số hiệu văn bản'} />
            </Form.Item>

            <Form.Item
              label={'Sổ đến'}
              name={'incoming_number'}
              rules={[
                { required: true, message: ' Vui lòng nhập số đến văn bản!' },
                { validator: validator.validateInputString },
              ]}>
              <Input placeholder={'Nhập số đến văn bản'} />
            </Form.Item>

            <Form.Item
              label={'Ngày đến'}
              name={'incoming_date'}
              rules={[{ required: true, message: ' Vui lòng chọn ngày đến!' }]}>
              <DatePicker style={{ width: '100%' }} format={DATE_FORMAT_LIST} />
            </Form.Item>

            <Form.Item
              label={'Cấp cơ quan'}
              name={'authority_level'}
              rules={[
                { required: true, message: ' Vui lòng chọn cấp cơ quan!' },
              ]}>
              <Select placeholder={'-- Chọn cấp cơ quan --'}></Select>
            </Form.Item>

            <Form.Item
              label={'Cơ quan ban hành'}
              name={'authority_issued'}
              rules={[
                { required: true, message: ' Vui lòng chọn cơ quan ban hành!' },
              ]}>
              <Select placeholder={'-- Chọn cơ quan ban hành --'}></Select>
            </Form.Item>

            <Form.Item
              label={'Trích yếu'}
              name={'document_title'}
              rules={[
                { required: true, message: ' Vui lòng nhập số hiệu văn bản!' },
                { validator: validator.validateInputString },
              ]}>
              <TextArea rows={4} placeholder={'Nhập trích yếu văn bản'} />
            </Form.Item>

            <Form.Item label={'Ngày văn bản'} name={'document_date'}>
              <DatePicker style={{ width: '100%' }} format={DATE_FORMAT_LIST} />
            </Form.Item>

            <Form.Item label={'Tài liệu đính kèm'}>
              <Dragger beforeUpload={handleAddFileToUpload}>
                <DraggerUploadStyled>
                  <CloudUploadOutlined />
                  <span>Kéo thả hoặc click vào để tải lên tài liệu</span>
                </DraggerUploadStyled>
              </Dragger>
            </Form.Item>

            <Form.Item label={'Người ký'} name={'document_signer'}>
              <Input placeholder={'Nhập tên người ký'} />
            </Form.Item>

            <FormActionFooter>
              <Button onClick={handleCancelCreate}>Huỷ bỏ</Button>
              <Button
                style={{ marginLeft: 10 }}
                type={'primary'}
                htmlType={'submit'}>
                Tạo văn bản
              </Button>
            </FormActionFooter>
          </Form>
        </Container>
      </ContentBlockWrapper>
    </ConnectedDocumentLayout>
  )
}

ConnectedDocumentIncomingDocCreatePage.propTypes = {}

export default inject('commonStore')(
  observer(ConnectedDocumentIncomingDocCreatePage),
)
