import React, { useEffect, useState } from 'react'
import { inject, observer } from 'mobx-react'
import DashboardLayout from '../../layouts/DashboardLayout/DashboardLayout'
import { Helmet } from 'react-helmet/es/Helmet'
import PageTitle from '../../components/PageTitle'
import KpiList from './KpiList/KpiList'
import { Dropdown, Menu, message, Modal } from 'antd'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import {
  DeleteOutlined,
  MoreOutlined,
  QuestionCircleOutlined,
} from '@ant-design/icons'
import { blue, redPrimary } from '../../color'
import { HeadingButtonWrapper } from '../../layouts/InternalDocumentDetailLayout/InternalDocumentDetailLayoutStyled'
import AccessControlAction from '../../components/AccessControlAction'
import { ACL_ACTION_TYPE } from '../../constants'

const { confirm } = Modal

const KpiPage = props => {
  const { kpiStore, kpiQuarterPageStore } = props

  const location = useLocation()
  const history = useHistory()

  const { kpi_quarter_code } = useParams()

  const [isLoadingKpiList, setIsLoadingKpiList] = useState(false)

  useEffect(() => {
    kpiStore.quarterCode = kpi_quarter_code

    if (kpiStore.quarterCode != null) {
      ;(async () => {
        try {
          setIsLoadingKpiList(true)
          await kpiStore.getKpiList()
        } catch (err) {
          console.log(err)
          message.error(err.vi || 'Đã có lỗi xảy ra!')
        } finally {
          setIsLoadingKpiList(false)
        }
      })()
    }
    return () => {
      kpiStore.clearKpiList()
    }
  }, [kpi_quarter_code])

  const menuHandle = (
    <Menu>
      <Menu.Item
        onClick={() => {
          confirm({
            title: 'Bạn có muốn xóa dữ liệu này không?',
            icon: <QuestionCircleOutlined style={{ color: 'red' }} />,
            okText: 'Đồng ý',
            cancelText: 'Không',
            okType: 'danger',
            async onOk() {
              await kpiQuarterPageStore.delete(kpi_quarter_code)
              history.push('/kpi_quarter')
            },
          })
        }}
        icon={<DeleteOutlined style={{ color: redPrimary }} />}>
        <span style={{ color: redPrimary }}>Xóa</span>
      </Menu.Item>
    </Menu>
  )

  return (
    <DashboardLayout>
      <Helmet>
        <title>KPI | Can Tho Port KPI</title>
      </Helmet>
      <PageTitle location={props.location}>
        <HeadingButtonWrapper>
          <AccessControlAction aclActionType={ACL_ACTION_TYPE.delete__KPI}>
            <Dropdown overlay={menuHandle} trigger={['click']}>
              <span className={'btn-style'}>
                <MoreOutlined style={{ color: blue }} />
              </span>
            </Dropdown>
          </AccessControlAction>
        </HeadingButtonWrapper>
      </PageTitle>

      <KpiList isLoading={isLoadingKpiList} />
    </DashboardLayout>
  )
}

KpiPage.propTypes = {}

export default inject('kpiStore', 'kpiQuarterPageStore')(observer(KpiPage))
