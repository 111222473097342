import React, { useEffect } from 'react'
import {
  Button,
  Divider,
  Form,
  Input,
  message,
  Modal,
  Radio,
} from 'antd'
import { FormActionFooter } from '../ConnectedDocumentIncomingDocCreatePage/ConnectedDocumentIncomingDocCreatePageStyled'
import { CheckOutlined, CloseOutlined } from '@ant-design/icons'
import { inject, observer } from 'mobx-react'
import { useState } from 'react'
import { SelectPeopleWrapper } from '../WorkPage/PopupVote/PopupVoteStyled'
import SelectUserButton from '../../components/Common/SelectUserButton'
import { ASSIGNEE_TYPE } from '../../constants'
import SelectListComponent from '../../components/Common/SelectListComponent'

const RiskWorkCreatePopup = ({
  handleCancel,
  isVisible,
  actionStore,
  riskStore,
  getRiskDetail,
  selectUserList,
  selectPeopleStore,
  handleVisibleSelectOneUserPopup,
}) => {
  const {
    indexDetailAction,
    dataActions,
    setDataActions,
    createAction,
    updateAction,
  } = actionStore
  const {
    handleOpenSelectUserDepartmentPopup,
    setSelectUserData,
    selectUserData,
  } = selectPeopleStore
  const { riskDetail } = riskStore
  const [form] = Form.useForm();
  const [isRenderRichText, setIsRenderRichText]=useState(false)

  useEffect(() => {
    if (!dataActions?.tier3[0] || indexDetailAction?.tier !== '3') {
      return
    }
    setSelectUserData({
      ...selectUserData,
      [ASSIGNEE_TYPE.ban_kt_ktnb]: dataActions.tier3[0].doi_tuong_thuc_hien.ban_kt_ktnb,
    })
    form.setFieldsValue({
      tong_giam_doc_cac_pho_tong_giam_doc:
        dataActions.tier3[0].doi_tuong_thuc_hien
          .tong_giam_doc_cac_pho_tong_giam_doc,
      // ban_kt_ktnb: dataActions.tier3[0].doi_tuong_thuc_hien.ban_kt_ktnb,
      dong_y_voi_danh_gia_rui_ro_xu_ly_rui_ro_o_tang_phong_ve_thu_nhat_va_thu_hai:
        dataActions.tier3[0].IV_ket_qua_ra_soat_rui_ro
          .dong_y_voi_danh_gia_rui_ro_xu_ly_rui_ro_o_tang_phong_ve_thu_nhat_va_thu_hai,
      kien_nghi_giai_phap_khac_phuc:
        dataActions.tier3[0].IV_ket_qua_ra_soat_rui_ro
          .kien_nghi_giai_phap_khac_phuc,
      ban_kt_ktnb_vimc:
        dataActions.tier3[0].IV_ket_qua_ra_soat_rui_ro.ban_kt_ktnb_vimc,
      hdqt_vimc: dataActions.tier3[0].IV_ket_qua_ra_soat_rui_ro.hdqt_vimc,
    })
  }, [indexDetailAction])
  useEffect(() => {
    if (isVisible) {
      setTimeout(() => {
        setIsRenderRichText(true)
      }, 100)
    } else {
      setIsRenderRichText(false)
    }
  }, [isVisible])

  const handleCancelModal = () => {
    handleCancel()
    // setTimeout(() => {
    //   setIndexDetailAction({ tiers: null, index: null })
    // }, 500)
  }

  
  const handleSubmit = async value => {
    const submitData = {
      name: 'Tuyến phòng vệ thứ ba',
      doi_tuong_thuc_hien: {
        tong_giam_doc_cac_pho_tong_giam_doc:
          value.tong_giam_doc_cac_pho_tong_giam_doc,
        ban_kt_ktnb: selectUserList.ban_kt_ktnb,
      },
      IV_ket_qua_ra_soat_rui_ro: {
        dong_y_voi_danh_gia_rui_ro_xu_ly_rui_ro_o_tang_phong_ve_thu_nhat_va_thu_hai:
          value.dong_y_voi_danh_gia_rui_ro_xu_ly_rui_ro_o_tang_phong_ve_thu_nhat_va_thu_hai,
        kien_nghi_giai_phap_khac_phuc: value.kien_nghi_giai_phap_khac_phuc,
        ban_kt_ktnb_vimc: value.ban_kt_ktnb_vimc,
        hdqt_vimc: value.hdqt_vimc,
      },
    }
    if (riskDetail?.tiers) {
      if (riskDetail?.tiers[2]?.actions[0]) {
        await updateAction(
          riskDetail?.tiers[2]?.actions[0]?.code,
          {
            name: 'Rủi ro nhận diện 3 update',
            data: JSON.stringify(submitData),
            risk_tier_code: riskDetail?.tiers[2]?.code,
          }
        )
      } else {
        await createAction({
          ...submitData,
          data: JSON.stringify(submitData),
          risk_tier_code: riskDetail?.tiers[2]?.code,
        })
      }
      message.success('Lưu thành công')
      handleCancelModal()
      getRiskDetail()
    } else {
      message.success('Lưu thành công')
      setDataActions({ ...dataActions, tier3: [submitData] })
      handleCancelModal()
    }
  }

  return (
    <Modal
      width={800}
      style={{ top: 40 }}
      title={'Tạo mới công việc thực hiện'}
      visible={isVisible}
      maskClosable={false}
      onCancel={handleCancelModal}
      destroyOnClose={true}
      afterClose={() => {
        setIsRenderRichText(false)
      }}
      zIndex={500}
      footer={null}>
      <Form
        form={form}
        layout={'vertical'}
        onFinish={handleSubmit}
        preserve={false}>
        <h3>C. Tuyến phòng vệ thứ ba</h3>
        <h3>Đối tượng thực hiện: <span style={{fontWeight:400}}>Đồng thời cả 2</span></h3>
        <Form.Item
          name={'tong_giam_doc_cac_pho_tong_giam_doc'}
          label={'(1)	Tổng giám đốc, các Phó Tổng giám đốc:'}>
          <Input placeholder={'Nhập nội dung'} />
        </Form.Item>
        {/* <Form.Item
          name={'ban_kt_ktnb'}
          label={'(2)	Ban KT-KTNB (tham mưu đánh giá độc lập cho HĐQT) :'}>
          <Input placeholder={'Nhập nội dung'} />
        </Form.Item> */}
        <SelectPeopleWrapper>
            <SelectUserButton
              style={{ marginTop: 16 }}
              title="(2)	Ban KT-KTNB (tham mưu đánh giá độc lập cho HĐQT):"
              tooltipTitle='Chọn người'
              handleOnClick={() => {
                handleVisibleSelectOneUserPopup()
                handleOpenSelectUserDepartmentPopup(ASSIGNEE_TYPE.ban_kt_ktnb)
              }}
            />
            <SelectListComponent userList={selectUserList.ban_kt_ktnb} />
          </SelectPeopleWrapper>
        <h3>IV. KẾT QUẢ RÀ SOÁT RỦI RO</h3>
        <Form.Item
          name={
            'dong_y_voi_danh_gia_rui_ro_xu_ly_rui_ro_o_tang_phong_ve_thu_nhat_va_thu_hai'
          }
          label={
            '1. Đồng ý với đánh giá rủi ro/xử lý rủi ro ở tầng phòng vệ thứ nhất và thứ hai:'
          }>
          <Radio.Group>
            {' '}
            <span style={{ marginRight: 16 }}>
              <Radio value={true}>Đồng ý</Radio>
            </span>
            <Radio value={false}>Không đồng ý</Radio>
          </Radio.Group>
        </Form.Item>
        <Form.Item
          name={'kien_nghi_giai_phap_khac_phuc'}
          label={'2.	Kiến nghị giải pháp khắc phục:'}>
          <Input placeholder={'Nhập nội dung'} />
        </Form.Item>
        <Divider />
        <Form.Item name={'ban_kt_ktnb_vimc'} label={'BAN KT-KTNB VIMC:'}>
          <Input placeholder={'Nhập nội dung'} />
        </Form.Item>
        <Form.Item name={'hdqt_vimc'} label={'HĐQT VIMC (CT/TV HĐQT):'}>
          <Input placeholder={'Nhập nội dung'} />
        </Form.Item>

        <FormActionFooter style={{ marginBottom: 0 }}>
          <Button icon={<CloseOutlined />} danger onClick={handleCancelModal}>
            Đóng
          </Button>
          <Button
            style={{ marginLeft: 10 }}
            type={'primary'}
            htmlType={'submit'}>
            Chỉnh sửa
          </Button>
        </FormActionFooter>
      </Form>
    </Modal>
  )
}

export default inject('riskStore', 'actionStore','selectPeopleStore')(observer(RiskWorkCreatePopup))
