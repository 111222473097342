import React, {
  useEffect,
  // , useState
} from 'react'
// Component
import { EmptyText } from '../../../../../components/Common/CellText'
// Styled Component
// Ant design
import {
  // Menu,
  message,
} from 'antd'
// Other
import { inject, observer } from 'mobx-react'
import { withRouter } from 'react-router-dom'
import styled from 'styled-components'

const ProposalDetailOther = props => {
  const {
    proposalStore,
    loadingAnimationStore,
    proposalId,
    // fileStore,
    history,
    selectPeopleStore,
  } = props

  const { otherDetail } = proposalStore

  const {} = selectPeopleStore

  // const [handlerList, setHandlerList] = useState([])
  // const [followerList, setFollowerList] = useState([])

  // const userList = otherDetail.peopel_involves ? otherDetail.peopel_involves.filter(el => el.assignee_type === ASSIGNEE_TYPE.USER && el.permission !== ASSIGNEE_TYPE.CREATE) : []
  // const departmentList = otherDetail.peopel_involves ? otherDetail.peopel_involves.filter(el => el.assignee_type === ASSIGNEE_TYPE.DEPARTMENT) : []
  // const groupList = otherDetail.peopel_involves ? otherDetail.peopel_involves.filter(el => el.assignee_type === ASSIGNEE_TYPE.GROUP) : []

  // useEffect(() => {
  //   setHandlerList([...selectUserData[ASSIGNEE_TYPE.HANDLER], ...selectDepartmentData[ASSIGNEE_TYPE.HANDLER], ...selectGroupData[ASSIGNEE_TYPE.HANDLER]])
  //   setFollowerList([...selectUserData[ASSIGNEE_TYPE.FOLLOWER], ...selectDepartmentData[ASSIGNEE_TYPE.FOLLOWER], ...selectGroupData[ASSIGNEE_TYPE.FOLLOWER]])
  // }, [selectUserData, selectDepartmentData, selectGroupData])

  // useEffect(() => {
  //   selectPeopleStore.setSelectUserData(selectPeopleStore.convertSelectDataObj(userList))
  //   selectPeopleStore.setSelectDepartmentData(selectPeopleStore.convertSelectDataObj(departmentList))
  //   selectPeopleStore.setSelectGroupData(selectPeopleStore.convertSelectDataObj(groupList))
  // }, [otherDetail, originDepartmentWithUserList])

  // const [isEdit, setIsEdit] = useState(false)

  // const renderDropdownMenu = attachment => {
  //   return (
  //     <Menu>
  //       <Menu.Item
  //         onClick={() =>
  //           fileStore.handleDownloadFile(
  //             attachment.file_id,
  //             attachment.file_name
  //           )
  //         }>
  //         Tải về
  //       </Menu.Item>
  //     </Menu>
  //   )
  // }

  // const submitUpdate = () => {
  //   const new_items_assign = []
  //   const remove_items_assign = []
  //   const listAssignOrigin = [...userList, ...departmentList, ...groupList].map(
  //     el => ({
  //       assignee_code: el.assignee_code,
  //       assignee_type: el.assignee_type,
  //       permission: el.permission,
  //     })
  //   )
  //   const listAssignAfter = [...followerList, ...handlerList].map(el => ({
  //     assignee_code: el.id,
  //     assignee_type: el.assignee_type,
  //     permission: el.permission,
  //   }))

  //   listAssignAfter.forEach(assignAfter => {
  //     if (
  //       !listAssignOrigin.some(
  //         assignOrigin =>
  //           assignOrigin.assignee_code + assignOrigin.permission ===
  //           assignAfter.assignee_code + assignAfter.permission
  //       )
  //     ) {
  //       new_items_assign.push({
  //         assignee_code: assignAfter.assignee_code,
  //         assignee_type: assignAfter.assignee_type,
  //         permission: assignAfter.permission,
  //       })
  //     }
  //   })
  //   listAssignOrigin.forEach(assignOrigin => {
  //     if (
  //       !listAssignAfter.some(
  //         assignAfter =>
  //           assignAfter.assignee_code + assignAfter.permission ===
  //           assignOrigin.assignee_code + assignOrigin.permission
  //       )
  //     ) {
  //       remove_items_assign.push({
  //         assignee_code: assignOrigin.assignee_code,
  //         assignee_type: assignOrigin.assignee_type,
  //         permission: assignOrigin.permission,
  //       })
  //     }
  //   })
  //   if (new_items_assign.length === 0 && remove_items_assign.length === 0)
  //     return
  //   const submitValue = {
  //     new_items: new_items_assign,
  //     remove_items: remove_items_assign,
  //   }
  //   loadingAnimationStore.showSpinner(true)
  //   proposalStore
  //     .updateOtherAssignPerson(proposalId, submitValue)
  //     .then(() => {
  //       loadingAnimationStore.showSpinner(false)
  //       setIsEdit(false)
  //       proposalStore.getOtherDetail(proposalId)
  //       return message.success('Cập nhật thành công!')
  //     })
  //     .catch(() => {
  //       loadingAnimationStore.showSpinner(false)
  //       setIsEdit(true)
  //       return message.error('Cập nhật thất bại!')
  //     })
  // }

  useEffect(() => {
    ;(async () => {
      loadingAnimationStore.showSpinner(true)
      try {
        await proposalStore.getOtherDetail(proposalId)
      } catch (err) {
        history.push('/proposal')
        console.log(err)
        message.error(err?.vi || 'Đã có lỗi xảy ra!')
      } finally {
        loadingAnimationStore.showSpinner(false)
      }
    })()

    return () => {
      selectPeopleStore.clearSelected()
      proposalStore.clearOtherDetail()
    }
  }, [proposalId])

  return (
    <>
      {otherDetail?.reason ? (
        <WordBreakStyled
          dangerouslySetInnerHTML={{ __html: otherDetail?.reason }}
        />
      ) : (
        <EmptyText>Không có nội dung</EmptyText>
      )}

      {/* <SelectPeoplePopup
        handleCancelSelectUser={handleCancelSelectUserDepartment}
        handleSubmitSelectUser={() => setIsVisibleSelectUserDepartmentPopup(false)}
        isVisibleSelectPeoplePopup={isVisibleSelectUserDepartmentPopup}
      />
      <SelectGroupPopup
        isVisibleSelectGroupPopup={isVisibleSelectGroupPopup}
        handleCancelSelectGroup={handleCancelSelectGroup}
        handleSubmitSelectGroup={() => setIsVisibleSelectGroupPopup(false)}
      /> */}
    </>
  )
}

const WordBreakStyled = styled.div`
  word-break: break-word;
  white-space: pre-line;
  div,
  p,
  span,
  figure,
  table {
    font-weight: 500;
  }
  img {
    width: 100%;
  }
`
ProposalDetailOther.propTypes = {}

export default withRouter(
  inject(
    'proposalStore',
    'commonStore',
    'loadingAnimationStore',
    'fileStore',
    'selectPeopleStore'
  )(observer(ProposalDetailOther))
)
