import React, { memo, useEffect, useMemo, useState } from 'react'
import { Helmet } from 'react-helmet/es/Helmet'
// Component
import UnreadText from '../../../components/UnreadText/UnreadText'
import { EmptyText } from '../../../components/Common/CellText'
import PageTitle from '../../../components/PageTitle'
import TableComponent from '../../../components/Common/TableComponent'
// Layout
import InternalMessageLayout from '../../../layouts/InternalMessageLayout'
// styled Component
import {
  SearchBar,
  TableActionWrapper,
  TableFooterActionWrapper,
} from './InternalMessageSendPageStyled'
// Ant design
import {
  Button,
  Col,
  Empty,
  Input,
  message,
  Pagination,
  Row,
} from 'antd'
import {
  PlusOutlined,
} from '@ant-design/icons'
//other
import { inject, observer } from 'mobx-react'
import { useLocation, withRouter } from 'react-router-dom'
import moment from 'moment'
import {
  ACL_ACTION_TYPE,
  YYYY_MM_DD_HH_mm,
} from '../../../constants'
import queryString from 'query-string'
import AccessControlAction from '../../../components/AccessControlAction'
import InternalMessageCreateModal from '../InternalMessageCreateModal'
import PopoverWatchTime from '../../../components/PopoverWatchTime'

const { Search } = Input

const InternalMessageRecievesPage = props => {
  const {
    history,
    internalMessageStore,
    loadingAnimationStore,
  } = props

  const {
    internalMessageList,
    internalMessageListTotalCount,
    queryParams,
  } = internalMessageStore

  const location = useLocation()
  const queryStringParse = queryString.parse(location.search)

  const [isModalVisible, setIsModalVisible] = useState(false)

  const GUI_TOI_WIDTH = 200
  const NGAY_DEN_WIDTH = 100
  const TIEU_DE_WIDTH = 700
  const SCROLL_WIDTH = 1000

  const tableColumns = useMemo(
    () => [
      {
        title: 'Gửi tới',
        width: GUI_TOI_WIDTH,
        render: record => (
          <>
            {record.receiverList && record.receiverList.length > 0 ? (
              <PopoverWatchTime
                userList={record.receiverList}
                title={'Danh sách người nhận'}
              />
            ) : (
              <EmptyText>Đang tải...</EmptyText>
            )}
          </>
        ),
      },
      {
        title: 'Tiêu đề',
        width: TIEU_DE_WIDTH,
        render: record => (
          <UnreadText
            text={record.title}
            isUnread={checkUnread(record)}
            width={TIEU_DE_WIDTH}
          />
        ),
      },
      {
        title: 'Ngày đến',
        width: NGAY_DEN_WIDTH,
        render: record => (
          <UnreadText
            text={moment(record.created_time).format(YYYY_MM_DD_HH_mm)}
            isUnread={checkUnread(record)}
            width={NGAY_DEN_WIDTH}
          />
        ),
      },
    ],
    []
  )

  const checkUnread = record => !record.is_read

  const handleQuickSearch = keyword => {
    const queryStringStringify = queryString.stringify(
      {
        ...queryStringParse,
        page: 0,
        size: 10,
        keyword: keyword ? keyword.trim() : null,
      }
    )
    history.replace(
      `/utility/internal-message/send?${queryStringStringify}`
    )
  }

  const onChangePagination = pageIndex => {
    internalMessageStore.setQueryParams({
      ...queryParams,
      page: pageIndex - 1,
      size: 10,
    })
    
    const queryStringStringify = queryString.stringify(
      {
        ...queryStringParse,
        page: pageIndex - 1,
        size: 10,
      }
    )
    history.replace(
      `/utility/internal-message/send?${queryStringStringify}`
    )
  }

  const getInternalMessageSendList = async () => {
    try {
      let localQueryStringParse = {
        page: 0,
        size: 10, ...queryStringParse
      }
      loadingAnimationStore.setTableLoading(true)
      await internalMessageStore.getInternalMessageSendList(localQueryStringParse)
    } catch (error) {
      console.log(error)
      message.error(error?.vi || 'Đã có lỗi xảy ra!')
    } finally {
      loadingAnimationStore.setTableLoading(false)
    }
  }

  useEffect(() => {
    (async () => {
      await getInternalMessageSendList()
    })()
  }, [location])

  return (
    <InternalMessageLayout
      title={
        <PageTitle location={props.location} title="Tin nhắn đi" hiddenGoBack>
          <AccessControlAction aclActionType={ACL_ACTION_TYPE.send__TIN_NHAN_NOI_BO}>
            <Button
              icon={<PlusOutlined />}
              onClick={() => setIsModalVisible(true)}
              type={'primary'}>
              Gửi tin nhắn
            </Button>
          </AccessControlAction>
        </PageTitle>
      }
      selectedKey="send"
      linkToInternalMessageRecieve="/utility/internal-message/receive"
      linkToInternalMessageSend="/utility/internal-message/send">
      <Helmet>
        <title>Tạo mới tin nhắn | Can Tho Port Quản lý Tin nhắn</title>
      </Helmet>
      <TableActionWrapper>
        <Row>
          <Col span={12}>
            <SearchBar>
              <Search
                allowClear
                onSearch={value => handleQuickSearch(value)}
                defaultValue={queryStringParse.keyword}
                placeholder={
                  'Tìm kiếm tin nhắn theo tiêu đề'
                }
              />
            </SearchBar>
          </Col>
        </Row>
        <TableComponent
          onRow={record => {
            return {
              onClick: () =>
                history.push(
                  `/utility/internal-message/send/view/${record.code}`
                ),
            }
          }}
          rowKey={record => record.code? (record.code  + (Math.random() * 1000)) : (Math.random() * 1000)}
          dataSource={internalMessageList}
          columns={tableColumns}
          pagination={false}
          scroll={{ x: SCROLL_WIDTH }}
          locale={{
            emptyText: (
              <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description={'Không có dữ liệu'}
              />
            ),
          }}
          loading={{
            tip: 'Đang tải tin nhắn...',
            spinning: loadingAnimationStore.tableLoading,
          }}
        />
        <TableFooterActionWrapper>
          <Pagination
            onChange={e => onChangePagination(e)}
            hideOnSinglePage={true}
            total={internalMessageListTotalCount}
            pageSize={queryParams.size}
            current={queryParams.page + 1}
            showSizeChanger={false}
            showLessItems
          />
        </TableFooterActionWrapper>
      </TableActionWrapper>
      <InternalMessageCreateModal
          isModalVisible={isModalVisible}
          handleCloseCreateModal={(isRefressList) => {
            setIsModalVisible(false)
            if (isRefressList) {
              getInternalMessageSendList()
            }
          }}
        />
    </InternalMessageLayout>
  )
}

InternalMessageRecievesPage.propTypes = {}

export default memo(
  withRouter(
    inject(
      'internalMessageStore',
      'loadingAnimationStore',
      'authorityStore',
      'userStore',
    )(observer(InternalMessageRecievesPage)),
  ),
)
