import { Menu, message } from 'antd'
import { inject, observer } from 'mobx-react'
import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { useHistory } from 'react-router-dom'
import { MODULE_CODE } from '../../../../constants'
import UserAppManagementPage from '../../../UserAppManagementPage/UserAppManagementPage'
import ConnectedDocumentLayout from '../ConnectedDocumentLayout'

const ConnectedTrucAccount = props => {
  const history = useHistory()

  useEffect(() => {
    if(props.moduleStore.moduleList.length === 0) return 

    if (!props.moduleStore.checkAccessModule(MODULE_CODE.lien_thong_van_ban)) {
      message.error('Không được quyền truy cập vào trang này')
      history.push('/dashboard')
    }
  }, [props.moduleStore.moduleList])

  return (
    <>
      <ConnectedDocumentLayout selectedKey="connected-truc-account">
        <Helmet>
          <title>
            Liên kết tài khoản trục | Liên thông văn bản| Quản trị | Can Tho Port Quản lý
            VB Can Tho Port
          </title>
        </Helmet>
        <UserAppManagementPage isDocumentManagementTab />
      </ConnectedDocumentLayout>
    </>
  )
}

export default inject(
  'moduleStore',
)(observer(ConnectedTrucAccount))
