import { requests } from './DefaultRequest'

export default {
  getRiskTemplateList: filter =>
    requests.get(`/api/v1/rui-ro-nhan-dien-template`, true, {
      ...filter,
      sort_by_created: 'DESC',
    }),

  getRiskTemplateTypes: () => requests.get(`/api/v1/rui-ro-nhan-dien-type`),

  getRiskTemplateDetail: code =>
    requests.get(`/api/v1/rui-ro-nhan-dien-template/${code}`),

  createRiskTemplate: payload =>
    requests.post(`/api/v1/rui-ro-nhan-dien-template`, payload),

  updateRiskTemplate: (code, payload) =>
    requests.put(`/api/v1/rui-ro-nhan-dien-template/${code}`, payload),

  delete: code => requests.delete(`/api/v1/rui-ro-nhan-dien-template/${code}`),
}
